function PlusCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-6 h-6"
    >
      <path
        fillRule="evenodd"
        d="M12 17.8333C12.3306 17.8333 12.6079 17.7213 12.8319 17.4973C13.0551 17.274 13.1667 16.9971 13.1667 16.6666V13.1666H16.6959C17.0264 13.1666 17.2987 13.0546 17.5125 12.8306C17.7264 12.6074 17.8334 12.3305 17.8334 11.9999C17.8334 11.6694 17.7214 11.3921 17.4974 11.1681C17.2742 10.9449 16.9973 10.8333 16.6667 10.8333H13.1667V7.30409C13.1667 6.97353 13.0551 6.70131 12.8319 6.48742C12.6079 6.27353 12.3306 6.16659 12 6.16659C11.6695 6.16659 11.3926 6.2782 11.1694 6.50142C10.9454 6.72542 10.8334 7.0027 10.8334 7.33325V10.8333H7.30421C6.97365 10.8333 6.70143 10.9449 6.48754 11.1681C6.27365 11.3921 6.16671 11.6694 6.16671 11.9999C6.16671 12.3305 6.27832 12.6074 6.50154 12.8306C6.72554 13.0546 7.00282 13.1666 7.33337 13.1666H10.8334V16.6958C10.8334 17.0263 10.9454 17.2985 11.1694 17.5124C11.3926 17.7263 11.6695 17.8333 12 17.8333ZM12 23.6666C10.3862 23.6666 8.86949 23.3601 7.45004 22.7473C6.0306 22.1351 4.79587 21.3041 3.74587 20.2541C2.69587 19.2041 1.86482 17.9694 1.25271 16.5499C0.639819 15.1305 0.333374 13.6138 0.333374 11.9999C0.333374 10.386 0.639819 8.86936 1.25271 7.44992C1.86482 6.03047 2.69587 4.79575 3.74587 3.74575C4.79587 2.69575 6.0306 1.86431 7.45004 1.25142C8.86949 0.639307 10.3862 0.333252 12 0.333252C13.6139 0.333252 15.1306 0.639307 16.55 1.25142C17.9695 1.86431 19.2042 2.69575 20.2542 3.74575C21.3042 4.79575 22.1353 6.03047 22.7474 7.44992C23.3603 8.86936 23.6667 10.386 23.6667 11.9999C23.6667 13.6138 23.3603 15.1305 22.7474 16.5499C22.1353 17.9694 21.3042 19.2041 20.2542 20.2541C19.2042 21.3041 17.9695 22.1351 16.55 22.7473C15.1306 23.3601 13.6139 23.6666 12 23.6666Z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default PlusCircle;
