import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSignedIn: false,
};

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    setIsSignedIn: (state, action) => {
      state.isSignedIn = action.payload;
    },
  },
});

export const {
  setIsSignedIn,
} = calendarSlice.actions;

export default calendarSlice.reducer;
