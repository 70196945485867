import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const DateRangeSelect = ({ handleDateRangeSelectChange}) =>
{
    const [selectedOption, setSelectedOption] = useState(null);

    const options = [
    { value: 'ultimoMes', label: 'Último mes' },
    { value: 'ultimoSemestre', label: 'Último semestre' },
    { value: 'mesActual', label: 'Mes actual' },
    ];

    const handleOptionChange = (selectedOption) =>
    {
        setSelectedOption(selectedOption);


        const option = selectedOption.value;
        const today = new Date();
        let newStartDate = '';
        let newEndDate = '';


        switch (option)
        {
            case 'ultimoMes':
            newStartDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
            newEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
            break;

            case 'ultimoSemestre':
            newStartDate = new Date(today.getFullYear(), today.getMonth() - 6, 1);
            newEndDate = new Date(today.getFullYear(), today.getMonth(), 0);
            break;

            case 'mesActual':
            newStartDate = new Date(today.getFullYear(), today.getMonth(), 1);
            newEndDate = today;
            break;

            default:
            break;
        }

        const formattedStartDate = newStartDate.toDateString();
        const formattedEndDate = newEndDate.toDateString();

        handleDateRangeSelectChange(formattedStartDate, formattedEndDate);

    };

    return (
    <div className='flex flex-row gam-2 m-auto text-center'>
        <Select
        options={options}
        value={selectedOption}
        onChange={handleOptionChange}
        placeholder="Seleccionar"
        />
    </div>
    );
};

export default DateRangeSelect;
