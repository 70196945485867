import React from "react";
import Calendar from "@ericz1803/react-google-calendar";

const API_KEY = process.env.REACT_APP_API_KEY_GOOGLE;
const calendars = [
  { calendarId: process.env.REACT_APP_CALENDAR_ID_GOOGLE },
];

const MyCalendar = () => {
  return (
    <div style={{
      width: "80%",
      maxHeight: "60vh",
      overflow: "auto",
      margin: "auto",
      maxWidth: "1200px",
      marginTop: "2rem",
      marginBottom: "2rem",
    }} >
      <Calendar apiKey={API_KEY} calendars={calendars} showFooter={false} />
    </div>
  );
};

export default MyCalendar;
