import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const useIsLoadingSection = (sectionName) => {
  const [isLoading, setIsLoading] = useState(true);
  const sectionList = useSelector((state) => state[sectionName].list);
  const sectionActions = useSelector((state) => state.layout.sectionActions);

  useEffect(() => {
    const newIsLoading = !sectionList || !sectionActions;
    if (newIsLoading !== isLoading) {
      setIsLoading(newIsLoading);
    }
  }, [sectionList, sectionActions, isLoading]);

  return isLoading;
};

export default useIsLoadingSection;
