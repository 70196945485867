import React, { useState, useEffect } from "react";
import DateRangePicker from "./DateRangePicker"
import Select from "react-select";
import DateRangerSelect from "./DateRangerSelect"
import ReportsApiExportToPDF from "./ReportsApiExportToPDF"

import { useAxiosWithAuth } from '../../hooks/useAxiosWithAuth';
import { useDispatch, useSelector } from "react-redux";
import TableHeader from "../../shared/components/CRUD/TableHeader";
import Calendar from "../../shared/assets/icons/Calendar";
import { resetClient, selectClient } from "./reportsReducer";



const Reports = () => {
  return (
    <>
      <div className="flex-col hidden md:flex">
        <UserTypeHandler />
      </div>
      <div className="flex-col flex md:hidden">
        <div className="ml-4 font-bold text-left text-sky-600/70 text-3xl mb-4">Reportes</div>
        <p
          className="m-4"
        >
          Para visualizar la pantalla de reportes, por favor, inicie sesión desde una computadora o un dispositivo que le permita ver la información en una pantalla más grande.
        </p>
      </div>
    </>
  )
}

const UserTypeHandler = () => {
  const [client, setClient] = useState(0);
  const [variable, setVariable] = useState([]);
  const [hourValue, setHourValue] = useState(0);
  const [totalHours, setTotalHours] = useState(0);
  const [activeTab, setActiveTab] = useState('client');
  const role = useSelector((state) => state.layout.role);

  const headers = [
    {}, // Blank because we change it depending on the selected tab
    { columnId: "level", columnName: "Nivel", filterable: false },
    { columnId: "modality", columnName: "Modalidad", filterable: false },
    { columnId: "schedule", columnName: "Horario", filterable: false },
    { columnId: "totalDictated", columnName: "Horas dictadas", filterable: false },
    { columnId: "students", columnName: "Alumnos", filterable: false },
    { columnId: "takenPerStudent", columnName: "Horas tomadas", filterable: false },
    { columnId: "attendancePerStudent", columnName: "Asistencias", filterable: false }
  ];

  const userType = (tab, teacher) => {
    switch (tab) {
      case 'client':
        headers[0] = { columnId: "teachers", columnName: "Profesores", filterable: false };
        return <TableConstructor headers={headers} client={client} setClient={setClient} tab={'client'} variable={variable} setVariable={setVariable} hourValue={hourValue} totalHours={totalHours} setHourValue={setHourValue} setTotalHours={setTotalHours} />
      case 'teacher':
        headers[0] = { columnId: "client", columnName: "Cliente", filterable: false };
        return <TableConstructor headers={headers} client={client} setClient={setClient} tab={'teacher'} variable={variable} setVariable={setVariable} hourValue={hourValue} totalHours={totalHours} setHourValue={setHourValue} setTotalHours={setTotalHours} teacher={teacher} />
      default:
        break;
    }
  }

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setClient(0)
    setVariable([])
    setHourValue(0)
    setTotalHours(0)
  };

  return (
    <>
      <div className="ml-4 font-bold text-left text-sky-600/70 text-3xl mb-4">Reportes</div>
      {role === 1 ?
        <>
          <div className="flex flex-col items-start mt-4 ml-4">
            <div className="flex">
              <TabButton text="Cliente" active={activeTab === 'client'} onClick={() => handleTabClick('client')} />
              <div className="border-b border-gray-300 w-1"></div>
              <TabButton text="Profesor" active={activeTab === 'teacher'} onClick={() => handleTabClick('teacher')} />
            </div>
          </div>
          <div>{userType(activeTab)}</div>
        </>
        :
        <div>{userType('teacher', true)}</div>
      }
    </>
  )
}

const TableConstructor = ({ headers, client, setClient, tab, variable, setVariable, hourValue, totalHours, setHourValue, setTotalHours, teacher = false }) => {

  const [updateTable, setUpdateTable] = useState(false);
  const role = useSelector((state) => state.layout.role);
  const userLogged = useSelector((state) => state.layout.userLogged);
  const [dates, setDates] = useState([]);
  const [dateSelect, setDateSelect] = useState([]);
  const axios = useAxiosWithAuth();
  const [typeDateRanger, setTypeDateRanger] = useState(false);

  useEffect(() => {
    if (updateTable) {
      if ( Object.keys(dates).length > 0 )
      {
        getReports(client, dates)
      }
      else if ( Object.keys(dateSelect).length > 0 )
      {
        getReports(client, dateSelect)
      }
    }
    setUpdateTable(false);
  }, [updateTable]);

  const getReports = (filterId, dates) => {
    let url = "/reports/";
    if (role !== 1 || tab === "teacher") {
      url += "teacher";
      if (role !== 1) {
        filterId = userLogged.id;
      }
    } else {
      url += "client";
    }
    url += "/" + filterId;

    let params = new URLSearchParams();
    if (dates.startDate) {
      const startDate = new Date(dates.startDate);
      params.set(
        "startDate",
        startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + startDate.getDate()
      );
    }
    if (dates.endDate) {
      const endDate = new Date(dates.endDate);
      params.set(
        "endDate",
        endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate()
      );
    }
    url += "?" + params.toString();

    axios
      .get(url)
      .then((res) => {
        if (res.status === 200) {
          // TODO: this should support objects
          setVariable(Object.keys(res.data.table).map(key => res.data.table[key]));
          teacher
            ? setHourValue(userLogged.currentValuePerHour)
            : (tab === 'client'
              ? setHourValue(res.data.table[0].client.currentValuePerHour)
              : setHourValue(res.data.table[0].teachers[Object.keys(res.data.table[0].teachers)[0]].currentValuePerHour)
            );
          setTotalHours(res.data.totalDictated);
        }
      })
      .catch(function (error) {
        console.error(error.message)
      });
  }

  const getStartTimeAndEndTime = (time, minutes) => {
    const splitTime = time.split(":");
    const startDate = new Date(
      0,
      0,
      0,
      parseInt(splitTime[0]),
      parseInt(splitTime[1])
    ).getTime();
    const endDate = new Date(startDate).getTime() + minutes * 60000;

    const startTime = (new Date(startDate).toLocaleTimeString([], {
      hour: '2-digit', minute: '2-digit'
    }));
    const endTime = (new Date(endDate).toLocaleTimeString([], {
      hour: '2-digit', minute: '2-digit'
    }));

    return [startTime, endTime];
  }

  const rowHelper = (row, header, rowIndex, colIndex) => {

    switch (header.columnId) {
      case "teachers":
      case "students":
        return (
          <td className=" px-3 py-2 text-sm text-center text-gray-800" key={rowIndex + colIndex}>
            {
              Object.keys(row[header.columnId]).map((personId, idx) => {
                const person = row[header.columnId][personId];
                return (
                  <p className="px-3 py-2 text-sm text-center text-gray-800" key={header.columnId + '-' + rowIndex + colIndex + idx}>{person.firstName + ' ' + person.lastName}</p>
                )
              })
            }
          </td>
        )
      case "level":
      case "modality":
        return <td className="px-3 py-2 text-sm text-center text-gray-800 whitespace-nowrap" key={rowIndex + colIndex}>{row[header.columnId].name}</td>
      case "schedule":
        return (
          <td className="px-3 py-2 text-sm text-center text-gray-800 whitespace-nowrap" key={rowIndex + colIndex}>
            {
              row[header.columnId].map((day, idx) => {
                const [startTime, endTime] = getStartTimeAndEndTime(day.startTime, day.lessonDuration);
                return (
                  <p className="px-3 py-2 text-sm text-center text-gray-800 whitespace-nowrap" key={header.columnId + '-' + rowIndex + colIndex + idx}>{`${day.weekDay} de ${startTime} a ${endTime}`}</p>
                );
              })
            }
          </td>
        )
      case "client":
        return <td className="px-3 py-2 text-sm text-center text-gray-800 whitespace-nowrap" key={rowIndex + colIndex}>{row[header.columnId].businessName}</td>
      case "totalDictated":
        return <td className="px-3 py-2 text-sm text-center text-gray-800 whitespace-nowrap" key={rowIndex + colIndex}>{`${row[header.columnId]} hs`}</td>
      case "takenPerStudent":
        return (
          <td className="px-3 py-2 text-sm text-center text-gray-800" key={rowIndex + colIndex}>
            {
              Object.keys(row['takenPerStudent']).map((studentId, idx) => {
                const hoursTaken = row['takenPerStudent'][studentId];
                return (
                  <p className="px-3 py-2 text-sm text-center text-gray-800" key={header.columnId + '-' + rowIndex + colIndex + idx}>
                    {`${hoursTaken} hs`}
                  </p>
                )
              })
            }
          </td>
        )
      case "attendancePerStudent":
        return (
          <td className="px-3 py-2 text-sm text-center text-gray-800" key={rowIndex + colIndex}>
            {
              Object.keys(row['attendancePerStudent']).map((studentId, idx) => {
                const attendancePercentage = row['attendancePerStudent'][studentId];
                return (
                  <p className="px-3 py-2 text-sm text-center text-gray-800" key={header.columnId + '-' + rowIndex + colIndex + idx}>
                    {`${Math.round(Number(attendancePercentage))}%`}
                  </p>
                )
              })
            }
          </td>
        )
    }
  }
  const dispatch = useDispatch()

  const updateVariable = () => {
    setClient(0)
    setVariable([])
    setHourValue(0)
    setTotalHours(0)
    setDates([])
    setUpdateTable(true)
    dispatch(resetClient())
  }

  const handleDateRangeChange = (startDate, endDate) => {
    setDates({ startDate: startDate, endDate: endDate });
    setDateSelect([]);
    setUpdateTable(true);
  };

  const handleDateRangeSelectChange = (startDate, endDate) => {
    setDateSelect({ startDate: startDate, endDate: endDate });
    setDates([]);
    setUpdateTable(true);
  };

  const valueFormatted = (value) => {
    return value.toLocaleString('es-AR', {
      style: 'currency',
      currency: 'ARS',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return (
    <>
      <div className="flex flex-row justify-between items-center mx-4 my-2">
        <div className="flex flex-row items-center gap-2">
          {!teacher &&
            <>
              <ClientSelect client={client} setClient={(value) => setClient(value)} setUpdateTable={setUpdateTable} axios={axios} tab={tab} />
              <button
                className="px-2 py-2 text-white bg-sky-500/80 rounded hover:bg-sky-800 focus:outline-none focus:ring focus:ring-blue-200"
                onClick={updateVariable}
              >
                Resetear
              </button>
            </>}
        </div>
        { (client !== 0 || teacher) &&
        <>
          <div className="m-2 flex justify-evenly">
            <div className="m-2 flex justify-evenly">
              <button
              className="text-zinc-500 hover:text-zinc-600"
              title="Cambiar Lapso de tiempo"
              onClick={() => setTypeDateRanger(!typeDateRanger)}
              >
              <Calendar />
              </button>
            </div>
            <div className="m-2 flex justify-evenly">
              { typeDateRanger ?
                <DateRangePicker handleDateRangeChange={handleDateRangeChange} />
                :
                <DateRangerSelect handleDateRangeSelectChange={handleDateRangeSelectChange} />
              }
            </div>
          </div>
        </>
        }
      </div>
      {client === 0 && !teacher ?
        <>
        <div className="m-2 flex justify-evenly">
        <p className="flex flex-row gam-2 m-auto text-center"><span className="text-[#FD8757] font-bold me-1">Valor hora:</span><span className="font-bold">{valueFormatted(hourValue)}</span></p>
        <p className="flex flex-row gam-2 m-auto text-center"><span className="text-[#FD8757] font-bold me-1">Total horas dictadas:</span><span className="font-bold">{totalHours} hs</span></p>
        <p className="flex flex-row gam-2 m-auto text-center"><span className="text-[#FD8757] font-bold me-1">Valor a cobrar:</span><span className="font-bold">{valueFormatted(hourValue * totalHours)}</span></p>
    </div>
      <table className="m-3 min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="px-6 py-3 text-sm font-bold text-center text-sky-500/80">
            {headers.map((header) => (
              <TableHeader>
                {header.columnName}
              </TableHeader>
            ))}
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {
            variable && variable.length > 0 ?
              variable.map((row, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    {headers.map((header, colIndex) => {
                      return rowHelper(row, header, rowIndex, colIndex)
                    })}
                  </tr>
                )
              })
              :
              <tr>
                <td colSpan={headers.length}>
                  <p className="px-3 py-2 text-sm text-center text-gray-800 whitespace-nowrap">No se encontraron resultados</p>
                </td>
              </tr>
          }
        </tbody>
      </table>
        </>
        :
        <>
        <div className="m-2 flex justify-evenly">
            {variable && variable.length > 0 ?
                (<>
                  <p className="flex flex-row gam-2 m-auto text-center"><span className="text-[#FD8757] font-bold me-1">Valor hora:</span><span className="font-bold">{valueFormatted(hourValue)}</span></p>
                  <p className="flex flex-row gam-2 m-auto text-center"><span className="text-[#FD8757] font-bold me-1">Total horas dictadas:</span><span className="font-bold">{totalHours} hs</span></p>
                  <p className="flex flex-row gam-2 m-auto text-center"><span className="text-[#FD8757] font-bold me-1">Valor a cobrar:</span><span className="font-bold">{valueFormatted(hourValue * totalHours)}</span></p>
                </>)
                  : (
                    <>
                      <p className="flex flex-row gam-2 m-auto text-center"><span className="text-[#FD8757] font-bold me-1">Valor hora:</span><span className="font-bold">{valueFormatted(0)}</span></p>
                      <p className="flex flex-row gam-2 m-auto text-center"><span className="text-[#FD8757] font-bold me-1">Total horas dictadas:</span><span className="font-bold">0 hs</span></p>
                      <p className="flex flex-row gam-2 m-auto text-center"><span className="text-[#FD8757] font-bold me-1">Valor a cobrar:</span><span className="font-bold">{valueFormatted(0)}</span></p>
                    </>)

            }
            <div className="pr-8">
              <ReportsApiExportToPDF
                variables={variable}
                collectedValue={valueFormatted(hourValue * totalHours)}
                valuePerHour={valueFormatted(hourValue)}
                totalHours={`${totalHours} hs`}
                tab={tab}
                client={client}
                teacher={teacher}
                lapseTime = { (Object.keys(dateSelect).length > 0 && dateSelect ) || dates }
              />
            </div>
        </div>
          <table className="m-3 min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr className="px-6 py-3 text-sm font-bold text-center text-sky-500/80">
                {headers.map((header) => (
                  <TableHeader>
                    {header.columnName}
                  </TableHeader>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {
                variable && variable.length > 0 ?
                  variable.map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        {headers.map((header, colIndex) => {
                          return rowHelper(row, header, rowIndex, colIndex)
                        })}
                      </tr>
                    )
                  })
                  :
                  <tr>
                    <td colSpan={headers.length}>
                      <p className="px-3 py-2 text-sm text-center text-gray-800 whitespace-nowrap">No se encontraron resultados</p>
                    </td>
                  </tr>
              }
            </tbody>
          </table>
        </>
      }
    </>
  )
}

const ClientSelect = ({ client, setClient, setUpdateTable, axios, tab }) => {
  const dispatch = useDispatch()
  const [allClient, setAllClient] = useState([]);

  useEffect(() => {
    tab === 'client' ? getAllClients() : getAllTeachers();
    handleChange(null);
  }, [tab]);

  const getAllClients = () => {
    axios
      .get("/client/getAll?destroyed=1")
      .then((res) => {
        if (res.status === 200) {
          let response = [];
          res.data.clients.forEach(client => response.push({ value: client.id, label: client.businessName }))
          setAllClient(response)
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {

        }
        console.error(error.message)
      });
  }

  const getAllTeachers = () => {
    axios
      .get("/user/getAllTeachers?destroyed=1")
      .then((res) => {
        if (res.status === 200) {
          let response = [];
          res.data.teachers.forEach(teacher => response.push({ value: teacher.id, label: teacher.firstName + ' ' + teacher.lastName }))
          setAllClient(response)
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {

        }
        console.error(error.message)
      });
  }

  const value = useSelector((state) => state.reports);
  const handleChange = (selected) => {
    if(selected !== null) {
      const selectedValue = { value: selected.value, label: selected.label };
      dispatch(selectClient(selectedValue));
      setClient(parseInt(selected.value));
    } else {
      setClient(0);
      dispatch(resetClient())
    }
    setUpdateTable(true);
  };

  return (
    <>
      <div>
        <Select
          value={value.value === null && value.label === null ? null : { value: value.value, label: value.label }}
          onChange={handleChange}
          options={allClient}
          placeholder={tab === 'client' ? 'Seleccione Cliente' : 'Seleccione Profesor'}
        />
      </div>
    </>
  )
}

const TabButton = ({ text, active, onClick }) => {
  return (
    <button
      className={`py-2 px-4 border border-b-0 border-gray-300 ${active ? 'bg-white text-[#1AB0E7]' : 'bg-white text-[#646464]'} font-semibold rounded-t-lg`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Reports;
