import React, { useEffect } from "react";
import './ResponseMessage.css'

const ResponseMessage = ({
  type = 'default',
  message,
  open,
  onClose,
  closeTime = 2000
}) => {
  useEffect(() => {
    if (open && onClose) {
      setTimeout(onClose, closeTime);
    }
  }, [open, onClose, closeTime]);

  return (
    <>
      {open && (
        <div className={type === 'success' ? 'success-response' : type === 'error' ? 'error-response' : 'default-response'}>
          <p className="message">{message}</p>
        </div>
      )}
    </>
  )
}

export default ResponseMessage;
