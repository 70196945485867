import { Outlet } from "react-router-dom";

function Container() {
  return (
    <>
      <div id="container" className="w-full mt-8">
        <Outlet />
      </div>
    </>
  );
}

export default Container;
