import Icon from "../Icon/Icon";

const TableHeader = ({
  children,
}) => {
  return (
    <th scope="col" className={"px-6 py-3 text-sm font-bold text-center text-sky-500/80"}>
      {children}
    </th>
  );
};

export default TableHeader;
