import Icon from "../Icon/Icon";

// TODO: use TableHeader component to prevent duplication
const SortableTableHeader = ({
  id,
  headerText,
  onSortColumn,
  sortBy,
  sortOrder
}) => {
  return (
      <th scope="col" className={"px-6 py-3 text-sm font-bold text-center text-sky-500/80"} onClick={onSortColumn}>
        {headerText+" "}
        {sortBy !== id && (
          <Icon icon="sort" />
        )}
        {sortBy === id && (
          <>
            {sortOrder === "ASC" && (
              <Icon icon="sort-down" />
            )}
            {sortOrder === "DESC" && (
              <Icon icon="sort-up" />
            )}
          </>
        )}
      </th>
  );
};

export default SortableTableHeader;
