import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: null,
  currentPage: 1,
  totalPages: 1,
  all: [],
  clientStudents: {},
};

const filterList = (list, key, value) => {
  return list.filter((course) => {
    return !course.hasOwnProperty(key) || course[key] !== value;
  });
};

export const courseSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setAll: (state, action) => {
      state.all = action.payload;
    },
    appendToList: (state, action) => {
      state.list.push(action.payload);
    },
    removeFromListByIndex: (state, action) => {
      state.list.splice(action.payload, 1);
    },
    removeFromListById: (state, action) => {
      state.list = filterList(state.list, "id", action.payload);
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setClientStudents: (state, action) => {
      state.clientStudents[action.payload.clientId] = action.payload.students;
    },
  },
});

export const {
  setList,
  setAll,
  appendToList,
  removeFromListByIndex,
  removeFromListById,
  setCurrentPage,
  setTotalPages,
  setClientStudents,
} = courseSlice.actions;

export default courseSlice.reducer;
