import React, { useState } from "react";
import MagnifyingrGlass from "../../assets/icons/MagnifyingrGlass";
import Select from "react-select";
import Icon from "../Icon/Icon";
import Modal from "../Modal/Modal";

const TableFilter = ({
  placeholderText,
  searchTermFilter,
  onChangeSearchText,
  onFilter,
  onChangeFilterDropdownValues,
  selectedFilterDropdownOptions,
  tableColumns,
  onResetFiltersAndOrder,
  hideSearchText,
}) => {
  const [showFilterModal, setShowFilterModal] = useState(false);

  const filterDropdownOptions = tableColumns
    .filter((column) => {
      return column.filterable && column.dataType === 'string';
    })
    .map((column) => {
      return {
        value: column.columnId,
        label: column.columnName,
      };
    });

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setShowFilterModal(false);
    onFilter(evt);
  };

  const hideFilterModal = () => {
    setShowFilterModal(false);
  };

  const onClickResetFiltersAndOrder = (evt) => {
    setShowFilterModal(false);
    onResetFiltersAndOrder(evt);
  };

  const handleClickShowFilterModal = () => {
    setShowFilterModal(true);
  };

  return (
    <>
      <span className="relative inline-flex items-center space-x-2">
        <button type="button" onClick={handleClickShowFilterModal}>
          <Icon
            icon="filter"
            className="text-zinc-500 hover:text-zinc-600 w-6 h-6"
          />
        </button>
      </span>
      <span className="relative inline-flex items-center space-x-2">
        <button type="button" onClick={onClickResetFiltersAndOrder}>
          <Icon
            icon="filter-circle-xmark"
            className="text-zinc-500 hover:text-zinc-600 w-6 h-6"
          />
        </button>
      </span>
      <Modal
        open={showFilterModal}
        onClose={hideFilterModal}
        title="Filtrar"
        backdrop={true}
        cross={true}
      >
        <form onSubmit={handleSubmit}>
          <div className="align-items-center">
            {!hideSearchText && (
              <>
                <div className="relative">
                  <input
                    type="text"
                    name="hs-table-search"
                    id="hs-table-search"
                    className="shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    placeholder={placeholderText}
                    onChange={onChangeSearchText}
                    defaultValue={searchTermFilter}
                  />
                  <span className="absolute inset-y-0 right-3 flex items-center pl-2 text-zinc-500 hover:text-zinc-600">
                    <button type="submit">
                      <MagnifyingrGlass />
                    </button>
                  </span>
                </div>
                <div className="filterDropdownContainer">
                  <Select
                    as="select"
                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600"
                    id="filterSelector"
                    name="filterSelector"
                    isMulti={true}
                    onChange={onChangeFilterDropdownValues}
                    placeholder="Seleccionar"
                    options={filterDropdownOptions}
                    defaultValue={selectedFilterDropdownOptions}
                  >
                  </Select>
                </div>
              </>
            )}
            {tableColumns.filter(column => { return column.filterable })
              .map((column, idx) => {
                if(column.dataType === "dropdown") {
                  let translatedOptions = [];
                  if(column.translations !== undefined) {
                    column.options = column.options.map((option) => {
                      let value = option.value;
                      let label = option.label;
                      translatedOptions.push({
                        value: value,
                        label: column.translations[label]
                      });
                      return option;
                    })
                  } else {
                    translatedOptions = column.options;
                  }

                  let defaultValue;
                  if(column.isMulti) {
                    defaultValue = Array.isArray(column.options) && column.options.filter((translatedOption) => {
                      return (Array.isArray(column.selectedOptions) &&
                        column.selectedOptions.some((selectedOption) => {
                          return (selectedOption === translatedOption.value)
                        })
                      )
                    })
                  } else {
                    defaultValue = column.selectedOptions;
                  }

                  return (
                      <div className="my-2" key={idx}>
                        <label
                            htmlFor={column.columnId}
                            className="block text-sm font-semibold text-gray-800"
                          >
                          {column.columnName}
                        </label>
                        <Select
                          as="select"
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600"
                          id={column.columnId}
                          name={column.columnId}
                          isMulti={column.isMulti}
                          onChange={column.onChange}
                          placeholder={column.placeholder}
                          options={translatedOptions}
                          defaultValue={defaultValue}
                        >
                        </Select>
                      </div>
                  );
                } else if (column.dataType === "checkbox") {
                  return (
                    <div className="px-3 my-2">
                      <div className="filterCheckboxContainer my-2">
                        <span
                          htmlFor={column.columnId}
                          className="inline-block text-sm text-gray-800"
                        >
                          {column.placeholder}
                        </span>
                        <input
                          type="checkbox"
                          id={column.columnId}
                          name={column.columnId}
                          className="ml-2"
                          onChange={column.onChange}
                        />
                      </div>
                    </div>
                  );
                } else {
                  return false;
                }
              })
            }
            <span className="m-2 relative inline-flex items-center space-x-2 text-zinc-500 hover:text-zinc-600">
              <button className="bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded" type="submit">
                Filtrar
              </button>
            </span>
            <span className="m-2 relative inline-flex items-center space-x-2">
              <button className="bg-zinc-500/80 hover:bg-zinc-500 text-white font-bold py-2 px-4 rounded" type="button" onClick={onClickResetFiltersAndOrder}>
                Limpiar filtros
              </button>
            </span>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default TableFilter;
