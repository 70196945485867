import React from 'react';
import ClipLoader from "react-spinners/ClipLoader";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import Login from './pages/Login/Login';
import Layout from './shared/components/Layout/Layout';
import Home from './pages/Home/Home';
import Users from './pages/Users/Users';
import Clients from './pages/Clients/Clients';
import Courses from './pages/Courses/Courses';
import StudentClasses from './pages/StudentClasses/StudentClasses';
import Error from './pages/Error/Error';
import Reports from './pages/Reports/Reports';
import './shared/styles.css';
import './App.css';

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/",
      element: <Layout />,
      errorElement: <Error />,
      children: [
        {
          path: "/",
          element: <Navigate to="/home" replace />,
        },
        {
          path: "home",
          element: <Home />,
        },
        {
          path: "users",
          element: <Users />,
        },
        {
          path: "courses",
          element: <Courses />,
        },
        {
          path: "clients",
          element: <Clients />,
        },
        {
          path: "classes",
          element: <StudentClasses />,
        },
        {
          path: "reports",
          element: <Reports />
        }
      ],
    },
  ]);

  return (
    <div className="App">
      <RouterProvider router={router} fallbackElement={<ClipLoader />} />
    </div>
  );
}

export default App;
