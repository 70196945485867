import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "../../shared/components/Modal/Modal";
import Pen from "../../shared/assets/icons/Pen";
import TrashCan from "../../shared/assets/icons/TrashCan";
import InformationCircle from "../../shared/assets/icons/InformationCircle";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../shared/components/CRUD/Pagination";
import { useAxiosWithAuth } from '../../hooks/useAxiosWithAuth';
import SortableTableHeaders from "../../shared/components/CRUD/SortableTableHeader";
import TableFilter from "../../shared/components/CRUD/TableFilter";
import { setAll as setAllCountries } from "../../reducers/countriesReducer";
import {
  setList as setListStudents,
  setCurrentPage,
  setTotalPages,
  setClientCourses,
} from "./studentsReducer";
import Spinner from "../../shared/components/Spinner/Spinner";
import ResponseMessage from "../../shared/components/ResponseMessage/ResponseMessage";
import useIsLoadingSection from "../../hooks/useIsLoadingSection";
import Select from "react-select";
import BulkUpload from "./BulkUpload";

const Students = ({
  client
}) => {
  const token = useSelector((state) => state.layout.token);
  const axios = useAxiosWithAuth();
  const role = useSelector((state) => state.layout.role);
  const sectionActions = useSelector((state) => state.layout.sectionActions);
  const isLoadingSection = useIsLoadingSection("students");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listStudents = useSelector((state) => state.students.list);
  const currentPage = useSelector((state) => state.students.currentPage);
  const totalPages = useSelector((state) => state.students.totalPages);
  const allCountries = useSelector((state) => state.countries.all);
  const clientCourses = useSelector((state) => state.students.clientCourses);
  const [updateTable, setUpdateTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [model, setModel] = useState({
    id: null,
    firstName: "",
    lastName: "",
    countryId: "",
    documentTypeId: "",
    document: "",
    email: "",
    phone: "",
    courses: [],
    registrationDate: null,
    clientId: "",
  });
  const [isCreate, setIsCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermFilter, setSearchTermFilter] = useState("");
  const [selectedFilterDropdownOptions, setSelectedFilterDropdownOptions] = useState("");
  const [searchColumnFilter, setSearchColumnFilter] = useState("");
  const [courseFilter, setCourseFilter] = useState(null);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [message, setMessage] = useState("");
  const [lastAction, setLastAction] = useState("");
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [allCourses, setAllCourses] = useState([]);
  const [allCoursesFilter, setAllCoursesFilter] = useState([]);
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);

  const boxInput = "shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
  const select =  "block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 my-2 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600"

  const handleChangeCourses = (courseValues) => {
    let courseId = null;
    if (courseValues !== null) {
      courseId = courseValues.map((courseValue) => {
        return courseValue.value;
      });
    }
    setCourseFilter(courseId);
  };

  const columns = [
    { columnId: "lastName",  columnName: "Apellido", showInTable: true, filterable: true, dataType: "string"},
    { columnId: "firstName", columnName: "Nombre",   showInTable: true, filterable: true, dataType: "string"},
    { columnId: "email",     columnName: "Email",    showInTable: true, filterable: true, dataType: "string"},
    { columnId: "courseId",  columnName: "Cursos",    showInTable: true, filterable: true, dataType: "dropdown", isMulti: true, onChange: handleChangeCourses,  options: allCoursesFilter, selectedOptions: courseFilter,  placeholder: "Seleccionar Curso"},
  ];

  useEffect(() => {
    if (token) {
      getStudentsList(
        currentPage,
        searchTermFilter,
        searchColumnFilter,
        courseFilter,
        orderBy,
        orderDirection
      );
      getCountries();
      loadClientCourses((client) ? client.id : 0);
    }
  }, [token]);

  useEffect(() => {
    if (responseMessage !== "") {
      setTimeout(() => {
        setResponseMessage("");
      }, 5000);
    }
  }, [responseMessage]);

  useEffect(() => {
    if (updateTable && token && role === 1) {
      getStudentsList(
        currentPage,
        searchTermFilter,
        searchColumnFilter,
        courseFilter,
        orderBy,
        orderDirection
      );
      setUpdateTable(false);
    }
  }, [updateTable]);

  const getStudentsList = async (
    currentPage,
    searchTermFilter = '',
    searchColumnFilter = null,
    courseFilter = null,
    orderBy = 'id',
    orderDirection = 'DESC'
  ) => {
    dispatch(setListStudents(null));

    const clientId = (client) ? client.id : 0;

    let searchColumnFilterURLParam = [];
    if(searchColumnFilter) {
      searchColumnFilterURLParam = searchColumnFilter.map((columnId, index) => {
        return "&searchColumns[" + index + "]=" + encodeURIComponent(columnId);
      });
    }

    let courseFilterURLParam = [];
    if (courseFilter) {
      courseFilterURLParam = courseFilter.map((courseId, index) => {
        return "&courseFilter[" + index + "]=" + courseId;
      });
    }

    const requestParams = new URLSearchParams({
      page: currentPage,
      orderBy: orderBy,
      orderDirection: orderDirection,
      clientId: clientId,
      searchTerm: searchTermFilter,
    }).toString() +
      searchColumnFilterURLParam.join("") +
      courseFilterURLParam.join("");

    axios
      .get("/student/list?"+requestParams)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentPage(res.data.currentPage));
          dispatch(setTotalPages(res.data.totalPages));
          dispatch(setListStudents(res.data.students));
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error( message.error )
      });
  };


  const getAllCoursesFilter = async () => {
    return await axios
      .get("/course/getAll")
      .then((res) => {
        if (res.status === 200) {
          const courses = res.data.courses.map((course) => {
            return { value: course.id, label: course.name };
          });
          setAllCoursesFilter(courses);
          return courses;
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error( message.error )
      });
  };

  const handleFilterDropdownValues = (onChangeFilterDropdownValues) => {
    let selectedFilterDropdownOptions = [];
    let filteredColumnIds = onChangeFilterDropdownValues.map((columnFiltered) => {
      selectedFilterDropdownOptions.push(columnFiltered);
      return columnFiltered.value;
    });

    setSelectedFilterDropdownOptions(selectedFilterDropdownOptions);
    setSearchColumnFilter(filteredColumnIds);
  };

  const handleChangePage = (page) => {
    dispatch(setCurrentPage(page));
    if (token) {
      getStudentsList(
        page,
        searchTermFilter,
        searchColumnFilter,
        courseFilter,
        orderBy,
        orderDirection
      );
    }
  };

  const handleEdit = (event, student) => {
    event.preventDefault();
    setLastAction("edit");
    setModel(student);
    setSelectedCountry(allCountries.find((c) => c.id === student.countryId));
    setIsEdit(true);
    setShowModal(true);
  };

  const handleDelete = (event, student) => {
    event.preventDefault();
    setLastAction("delete");
    setModel(student);
    setIsDelete(true);
    setShowModal(true);
  };

  const handleCreate = (event) => {
    event.preventDefault();
    setLastAction("create");
    setSelectedCountry(null);
    setIsCreate(true);
    setShowModal(true);
  };

  const handleBulkUpload = (event) => {
    event.preventDefault();
    setShowBulkUploadModal(true);
  };

  const handleView = (event, student) => {
    event.preventDefault();
    setLastAction("view");
    setModel(student);
    setSelectedCountry(allCountries.find((c) => c.id === student.countryId));
    setIsView(true);
    setShowModal(true);
  };

  const handleSortColumn = (columnId) => {
    return ((event) => {
      event.preventDefault();
      setOrderBy(columnId);
      if(orderDirection === "ASC") {
        setOrderDirection("DESC");
      } else {
        setOrderDirection("ASC");
      }
      setUpdateTable(true);
    });
  };

  const handleFilter = () => {
    setSearchTermFilter(searchTerm);
    setOrderBy("id");
    setOrderDirection("DESC");
    dispatch(setCurrentPage(1));
    setUpdateTable(true);
  };

  const resetFilter = () => {
    setSearchTerm("");
    setSearchTermFilter("");
    setSearchColumnFilter("");
    setOrderBy("id");
    setOrderDirection("DESC");
    dispatch(setCurrentPage(1));
    setCourseFilter("");
    setSelectedFilterDropdownOptions([]);
    setUpdateTable(true);
  };

  const handleSearchTextChange = (event) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  const hideModal = () => {
    resetModel();
    setShowModal(false);
    if (isDelete) {
      setIsDelete(false);
    } else if (isCreate) {
      setIsCreate(false);
    } else if (isEdit) {
      setIsEdit(false);
    } else if (isView) {
      setIsView(false);
    }
    setResponseMessage("");
  };

  const handleBulkUploadSuccess = () => {
    setUpdateTable(true);
    hideBulkUploadModal();
  };

  const hideBulkUploadModal = () => {
    setShowBulkUploadModal(false);
    setResponseMessage("");
  };

  const resetModel = () => {
    setModel({
      id: null,
      firstName: "",
      lastName: "",
      countryId: "",
      documentTypeId: "",
      document: "",
      email: "",
      phone: "",
      courses: [],
      registrationDate: null,
      clientId: "",
    });
  };

  const getCountries = async () => {
    if (allCountries?.length > 0) {
      return allCountries;
    } else {
      return await axios
        .get("/country/getAll")
        .then((res) => {
          if (res.status === 200) {
            const countries = res.data.countries;
            dispatch(setAllCountries(countries));
            return countries;
          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            const cookies = new Cookies();
            cookies.remove("jwt_token");
            navigate("/login");
          }
          console.error( message.error )
        });
    }
  };

  const loadClientCourses = async (clientId) => {
    let courses = [];
    if (clientId) {
        await axios
          .get("/course/getAllCoursesByClient/" + clientId)
          .then((res) => {
            if (res.status === 200) {
              const newCourses = res.data.courses;
              dispatch(setClientCourses({
                clientId: clientId,
                courses: newCourses,
              }));
              courses = newCourses;
            }
          })
          .catch(function (error) {
            if (error.response.status === 401) {
              const cookies = new Cookies();
              cookies.remove("jwt_token");
              navigate("/login");
            }
            console.error( message.error )
          });
    }

    setAllCourses(courses.map((course) => {
      return { value: course, label: course.name };
    }));
    setAllCoursesFilter(courses.map((course) => {
      return { value: course.id, label: course.name };
    }));
  };

  const hasPermission = (action) => {
    return sectionActions && sectionActions.includes(action);
  };

  const actionStudent = async (action, data = null) => {
    if (!data) {
      data = model;
    }

    axios
      .post("/student/" + action, data)
      .then((res) => {
        if (res.status === 200) {
          setResponseMessage(res.data.status);
          setUpdateTable(true);
        } else {
          setResponseMessage(res.data.message);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error( message.error )
        setResponseMessage("error");
      });

    hideModal();
  };

  const renderDocumentTypesOptions = () => {
    const showSelect = (selectedCountry === null) ||
      (selectedCountry !== null && selectedCountry.document_types.length > 1);
    return (
      <>
        {showSelect && (
          <option value="">Seleccionar</option>
        )}
        {selectedCountry !== null && selectedCountry.document_types.map((documentType, idx) => (
          <option key={"documentType_" + idx} value={documentType.id}>
            {documentType.value}
          </option>
        ))}
      </>
    );
  }

  const isExistingEmail = async (email) =>
  {
      return await axios
      .post("/user/checkEmail" , {email: email})
      .then((res) =>
      {
        return (res.status === 200 && res.data.status === "existing_email");
      })
      .catch(function (error)
      {
        if (error.response.status === 401)
        {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    if (responseMessage === "success") {
      setMessage(lastAction === "create"
        ? "¡Alumno creado exitosamente!"
        : lastAction === "edit"
        ? "¡Alumno modificado exitosamente!"
        : lastAction === "delete"
        ? "¡Alumno borrado existosamente!"
        : "");
    } else if (responseMessage === "existing_email") {
      setMessage("El email ya está en uso.");
    } else if (responseMessage === "invalid_data") {
      setMessage("Datos inválidos.");
    } else if (responseMessage === "error") {
      setMessage("Ocurrió un error. ¡Vuelva a intentar mas tarde!");
    }
    if (responseMessage) {
      setShowResponseMessage(true);
    }
  }, [responseMessage]);

  const hideResponseMessage = () => {
    setShowResponseMessage(false);
    setResponseMessage("");
    setMessage("");
  };

  return (
    <>
    {isLoadingSection && (
      <Spinner
        color="#000000"
        size={80}
      />
    )}
    {!isLoadingSection && (
    <>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="flex items-center gap-4 py-3 px-3 md:px-10 mb-4">
            <TableFilter
              placeholderText={"Buscar alumno..."}
              searchTermFilter={searchTermFilter}
              onChangeSearchText={handleSearchTextChange}
              onFilter={handleFilter}
              onChangeFilterDropdownValues={handleFilterDropdownValues}
              selectedFilterDropdownOptions={selectedFilterDropdownOptions}
              tableColumns={columns}
              onResetFiltersAndOrder={resetFilter}
              hideSearchText={false}
            />
            {hasPermission("create") && (
              <div style={{marginLeft: "auto"}} className="flex gap-2">
                {/* <div className="relative">
                  <button
                    className="bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={(ev) => handleBulkUpload(ev)}
                  >
                    Carga masiva
                  </button>
                </div> */}
                <div className="relative">
                  <button
                    className="bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={(ev) => handleCreate(ev)}
                  >
                    Nuevo alumno
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="p-3 w-full inline-block align-middle">
            <div className="overflow-auto border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map((column, index) => {
                      if(column.showInTable) {
                        if(column.columnId !== 'courseId') {
                          return (
                            <SortableTableHeaders
                              id={column.columnId}
                              key={"header_" + index}
                              headerText={column.columnName}
                              onSortColumn={handleSortColumn(column.columnId)}
                              sortBy={orderBy}
                              sortOrder={orderDirection}
                            />
                          );
                        } else {
                          return (
                            <th scope="col" key={"header_" + column.columnId} className={"px-6 py-3 text-sm font-bold text-center text-sky-500/80"}>
                              {column.columnName}
                            </th>
                          )
                        }
                      } else {
                        return false;
                      }
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {listStudents && listStudents.map((student, idx) => {
                    return (
                      <tr key={"row_" + idx}>
                        <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                          {student.lastName}
                        </td>
                        <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                          {student.firstName}
                        </td>
                        <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                          {student.email}
                        </td>
                        <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                          {student.courses.map(item => { return (<span className="block">{item.name}</span>) })}
                        </td>
                        <td className="px-6 py-4 flex justify-center gap-x-4 text-sm whitespace-nowrap">
                          {hasPermission("update") && (
                            <button
                              className="text-zinc-500 hover:text-zinc-600"
                              title="Editar"
                              onClick={(ev) => handleEdit(ev, student)}
                            >
                              <Pen />
                            </button>
                          )}
                          {hasPermission("delete") && (
                            <button
                              className="text-zinc-500 hover:text-zinc-600"
                              title="Borrar"
                              onClick={(ev) => handleDelete(ev, student)}
                            >
                              <TrashCan />
                            </button>
                          )}
                          <button
                            className="text-zinc-500 hover:text-zinc-600"
                            href=""
                            title="Ver"
                            onClick={(ev) => handleView(ev, student)}
                          >
                            <InformationCircle />
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Pagination currentPage={currentPage} totalPages={totalPages} onChangePage={handleChangePage} />

      <ResponseMessage
        open={showResponseMessage}
        onClose={hideResponseMessage}
        type={responseMessage === 'success' ? 'success' : 'error'}
        message={message}
      />

      <Modal
        open={showBulkUploadModal}
        onClose={hideBulkUploadModal}
        title="Carga masiva"
        backdrop={false}
        cross={true}
      >
        <BulkUpload
          clientId={client.id}
          onHide={hideBulkUploadModal}
          onSuccess={handleBulkUploadSuccess}
        />
      </Modal>

      <Modal
        open={showModal}
        onClose={hideModal}
        title={
          isCreate
            ? "Nuevo alumno"
            : isEdit
            ? "Editar alumno"
            : isDelete
            ? "Borrar alumno " + model.firstName + " " + model.lastName
            : "Ver alumno"
        }
        backdrop={false}
        cross={true}
      >
        {isCreate || isEdit || isView ? (
          <Formik
            initialValues={model}
            validate={(values) => {
              const errors = {};

              if (!values.firstName) {
                errors.firstName = "Campo requerido";
              }
              if (!values.lastName) {
                errors.lastName = "Campo requerido";
              }
              if (!values.email) {
                errors.email = "Campo requerido";
              }
              else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Correo inválido";
              }
              if (!values.registrationDate) {
                errors.registrationDate = "Campo requerido";
              }
              if (!values.countryId) {
                errors.countryId = "Campo requerido";
              }
              if (!values.documentTypeId) {
                errors.documentTypeId = "Campo requerido";
              }
              if (!values.document) {
                errors.document = "Campo requerido";
              }
              return errors;
            }}
            onSubmit={ async (values, { setSubmitting }) =>
            {
              try
              {
                if (values.email !== model.email && await isExistingEmail(values.email))
                {
                  setResponseMessage("existing_email");
                  return;
                }
                else if (client)
                {
                  const finalValues = {...values, clientId: client.id};
                  if (isCreate)
                  {
                    actionStudent("create", finalValues);
                  }
                  else if(isEdit)
                  {
                    actionStudent("edit", finalValues);
                  }
                }
                else
                {
                  setResponseMessage("error");
                }
              }
              catch (error)
              {
                setResponseMessage("error");
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting, isValid }) => {
              if (isSubmitting && !isValid) {
                setResponseMessage("invalid_data");
              }
              return (
              <Form>
                <div className="md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Nombre*
                    </label>
                    <Field
                      className= { boxInput }
                      type="text"
                      name="firstName"
                      disabled={isView}
                    />
                    <ErrorMessage
                      name="firstName"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Apellido*
                    </label>
                    <Field
                      className= { boxInput }
                      id="lastName"
                      type="text"
                      name="lastName"
                      disabled={isView}
                    />
                    <ErrorMessage
                      name="lastName"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="countryId"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      País*
                    </label>
                    <Field
                      as="select"
                      className= { select }
                      id="countryId"
                      name="countryId"
                      disabled={isView}
                      onChange={(event) => {
                        const selectedId = event.target.value;
                        if (!selectedId) {
                          setFieldValue("countryId", "");
                          setFieldValue("documentTypeId", "");
                          setSelectedCountry(null);
                        } else {
                          setFieldValue("countryId", selectedId);

                          const country = allCountries.find((c) => c.id == selectedId);
                          setSelectedCountry(country);

                          if (country.document_types.length === 1) {
                            setFieldValue("documentTypeId", country.document_types[0].id);
                          }
                        }
                      }}
                    >
                      <option value="">Seleccionar</option>
                      {allCountries.map((country, idx) => {
                        return (
                          <option key={"country_" + idx} value={country.id}>
                            {country.name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="countryId"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="document"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Tipo y Nro de Documento*
                    </label>
                    <div className="grid grid-cols-2 gap-2">
                      <div className="col-span-1">
                        <Field
                          as="select"
                          className= { select }
                          id="documentTypeId"
                          name="documentTypeId"
                          disabled={isView}
                        >
                          {renderDocumentTypesOptions()}
                        </Field>
                        <ErrorMessage
                          name="documentTypeId"
                          className="text-red-600"
                          component="div"
                        />
                      </div>
                      <div className="col-span-1">
                        <Field
                          className= { boxInput }
                          id="document"
                          type="text"
                          name="document"
                          disabled={isView}
                        />
                        <ErrorMessage
                          name="document"
                          className="text-red-600"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Email*
                    </label>
                    <Field
                      className= { boxInput }
                      id="email"
                      type="email"
                      name="email"
                      disabled={isView}
                    />
                    <ErrorMessage
                      name="email"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Teléfono celular
                    </label>
                    <Field
                      className= { boxInput }
                      id="phone"
                      type="text"
                      name="phone"
                      disabled={isView}
                    />
                    <ErrorMessage
                      name="phone"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="courses"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Cursos
                    </label>
                    <Field name="courses">
                      {({ field }) => (
                        <Select
                          as="select"
                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600 my-2"
                          id="courses"
                          name="courses"
                          closeMenuOnSelect={false}
                          isDisabled={isView}
                          isMulti
                          placeholder="Seleccionar"
                          options={allCourses}
                          value={
                            field.value &&
                            allCourses.filter((course) => {
                              return field.value.find((item) => {
                                return (
                                  (item.id ? item.id : item) ===
                                  course.value.id
                                );
                              });
                            })
                          }
                          onChange={(selectedCourses) => {
                            setFieldValue(
                              "courses",
                              selectedCourses.map(
                                (course) => course.value
                              )
                            );
                          }}
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="courseId"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="registrationDate"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Fecha de alta*
                    </label>
                    <Field
                      className= { boxInput }
                      id="registrationDate"
                      type="date"
                      name="registrationDate"
                      disabled={isView}
                    />
                    <ErrorMessage
                      name="registrationDate"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                </div>
                <div className="items-center gap-2 mt-3 sm:flex">
                  {!isView && (
                    <button
                      type="submit"
                      className="w-full mt-2 p-2.5 flex-1 text-white font-bold bg-orange-500/80 hover:bg-orange-500 rounded-md outline-none ring-offset-2 ring-orange-500 focus:ring-2"
                    >
                      {isCreate ? "Crear" : "Editar"}
                    </button>
                  )}
                  <button
                    className="w-full mt-2 p-2.5 flex-1 text-white bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none border ring-offset-2 ring-zinc-500 focus:ring-2"
                    onClick={() => hideModal()}
                  >
                    Cerrar
                  </button>
                </div>
              </Form>
              );
            }}
          </Formik>
        ) : (
          <>
            <p className="mt-2 text-[18px] leading-relaxed text-gray-500">
              ¿Está seguro de borrar al alumno?
            </p>
            <div className="items-center gap-2 mt-3 sm:flex">
              <button
                type="submit"
                className="w-full mt-2 p-2.5 flex-1 text-white bg-red-500/80 hover:bg-red-500 rounded-md outline-none ring-offset-2 ring-red-500 focus:ring-2"
                onClick={() => actionStudent("delete")}
              >
                Borrar
              </button>
              <button
                className="w-full mt-2 p-2.5 flex-1 text-white bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none border ring-offset-2 ring-zinc-500 focus:ring-2"
                onClick={() => hideModal()}
              >
                Cancelar
              </button>
            </div>
          </>
        )}
      </Modal>
    </>
    )}
    </>
  );
}

export default Students;
