import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  all: [],
};

export const levelSlice = createSlice({
  name: 'levels',
  initialState,
  reducers: {
    setAll: (state, action) => {
      state.all = action.payload;
    },
  },
});

export const {
  setAll,
} = levelSlice.actions;

export default levelSlice.reducer;
