import { Path, Svg } from '@react-pdf/renderer'

const ExporterIcon = ({ svg, styles }) => {
  const icon = svg.icon
  const duotone = Array.isArray(icon[4])
  const paths = Array.isArray(icon[4]) ? icon[4] : [icon[4]]

  return (
    <Svg width="50px" heigth="50px" viewBox={`0 0 ${icon[0]} ${icon[1]}`} style={styles}>
      {paths &&
        paths.map((d, index) => (
          <Path
            d={d}
            key={index}
            fill={styles.color}
            fillOpacity={duotone && index === 0 ? 0.4 : 1.0}
          />
        ))}
    </Svg>
  )
}

export default ExporterIcon;
