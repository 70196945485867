import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import Select from "react-select";
import Modal from "../../shared/components/Modal/Modal";
import Pen from "../../shared/assets/icons/Pen";
import TrashCan from "../../shared/assets/icons/TrashCan";
import InformationCircle from "../../shared/assets/icons/InformationCircle";
import PlusCircle from "../../shared/assets/icons/PlusCircle";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../shared/components/CRUD/Pagination";
import { useAxiosWithAuth } from "../../hooks/useAxiosWithAuth";
import SortableTableHeaders from "../../shared/components/CRUD/SortableTableHeader";
import TableFilter from "../../shared/components/CRUD/TableFilter";
import { setAll as setAllLevels } from "../../reducers/levelsReducer";
import { setAll as setAllModalities } from "../../reducers/modalitiesReducer";
import {
  setCurrentPage,
  setTotalPages,
  setList as setListCourses,
  setClientStudents,
} from "./coursesReducer";
import Spinner from "../../shared/components/Spinner/Spinner";
import ResponseMessage from "../../shared/components/ResponseMessage/ResponseMessage";
import useIsLoadingSection from "../../hooks/useIsLoadingSection";
import Exporter from "./CoursesExporter";
import {
  createEventWithVideo,
  deleteEvent,
  editEventWithVideo,
} from "../../shared/components/calendar/calendarConfig";

const Courses = () => {
  const token = useSelector((state) => state.layout.token);
  const axios = useAxiosWithAuth();
  const role = useSelector((state) => state.layout.role);
  const userLogged = useSelector((state) => state.layout.userLogged);
  const sectionActions = useSelector((state) => state.layout.sectionActions);
  const isLoadingSection = useIsLoadingSection("courses");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listCourses = useSelector((state) => state.courses.list);
  const currentPage = useSelector((state) => state.courses.currentPage);
  const totalPages = useSelector((state) => state.courses.totalPages);
  const clientStudents = useSelector((state) => state.courses.clientStudents);
  const [updateTable, setUpdateTable] = useState(false);
  const allLevels = useSelector((state) => state.levels.all);
  const allModalities = useSelector((state) => state.modalities.all);
  const [allClients, setAllClients] = useState(null);
  const [allTeachers, setAllTeachers] = useState(null);
  const [allStudents, setAllStudents] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [model, setModel] = useState({
    id: null,
    name: "",
    startDate: "",
    endDate: "",
    objectives: "",
    teachers: [],
    client: {},
    level: {},
    modality: {},
    days: [],
    students: [],
  });
  const [isCreate, setIsCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [orderBy, setOrderBy] = useState("clientId");
  const [orderDirection, setOrderDirection] = useState("ASC");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermFilter, setSearchTermFilter] = useState("");
  const [searchColumnFilter, setSearchColumnFilter] = useState("");
  const [clientFilter, setClientFilter] = useState(null);
  const [teacherFilter, setTeacherFilter] = useState(null);
  const [levelFilter, setLevelFilter] = useState(null);
  const [modalityFilter, setModalityFilter] = useState(null);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [message, setMessage] = useState("");
  const [lastAction, setLastAction] = useState("");
  const isSignedInCalendar = useSelector((state) => state.calendar.isSignedIn);
  const [calendarAlertModal, setCalendarAlertModal] = useState(false);

  const handleChangeClients = (clientValues) => {
    let clientId = null;
    if (clientValues !== null) {
      clientId = clientValues.map((clientValue) => {
        return clientValue.value;
      });
    }
    setClientFilter(clientId);
  };

  const handleChangeTeachers = (teacherValues) => {
    let teacherId = null;
    if (teacherValues !== null) {
      teacherId = teacherValues.map((teacherValue) => {
        return teacherValue.value;
      });
    }
    setTeacherFilter(teacherId);
  };

  const handleChangeLevels = (levelValues) => {
    let levelId = null;
    if (levelValues !== null) {
      levelId = levelValues.map((levelValue) => {
        return levelValue.value;
      });
    }
    setLevelFilter(levelId);
  };

  const handleChangeModalities = (modalityId) => {
    setModalityFilter(modalityId);
  };


  const columns = [
    {columnId: "clientId",    columnName: "Cliente",         showInTable: true, filterable: true,  dataType: "dropdown", isMulti: true,  className: "Client",   onChange: handleChangeClients,    options: allClients,    selectedOptions: clientFilter,   placeholder: "Seleccionar Cliente"},
    {columnId: "teacherId",   columnName: "Profesor/es",        showInTable: true, filterable: role === 1,  dataType: "dropdown", isMulti: true,  className: "Teacher",  onChange: handleChangeTeachers,   options: allTeachers,   selectedOptions: teacherFilter,  placeholder: "Seleccionar Profesor"},
    {columnId: "levelId",     columnName: "Nivel",           showInTable: true, filterable: true,  dataType: "dropdown", isMulti: true,  className: "Level",    onChange: handleChangeLevels,     options: allLevels,     selectedOptions: levelFilter,    placeholder: "Seleccionar Nivel"},
    {columnId: "modalityId",  columnName: "Modalidad",       showInTable: true, filterable: true,  dataType: "dropdown", isMulti: false, className: "Modality", onChange: handleChangeModalities, options: allModalities, selectedOptions: modalityFilter, placeholder: "Modalidad"},
    {columnId: "dateAndTime", columnName: "Fecha y Horario", showInTable: true, filterable: false, dataType: "string"}
  ];

  useEffect(() => {
    if (token) {
      getCoursesList(
        currentPage,
        searchTermFilter,
        searchColumnFilter,
        clientFilter,
        teacherFilter,
        levelFilter,
        modalityFilter,
        orderBy,
        orderDirection
      );
      getAllLevels();
      getAllModalities();
      getAllClients();
      getAllTeachers();
    }
  }, [token]);

  useEffect(() => {
    if (responseMessage !== "") {
      setTimeout(() => {
        setResponseMessage("");
      }, 5000);
    }
  }, [responseMessage]);

  useEffect(() => {
    if (updateTable && token) {
      getCoursesList(
        currentPage,
        searchTermFilter,
        searchColumnFilter,
        clientFilter,
        teacherFilter,
        levelFilter,
        modalityFilter,
        orderBy,
        orderDirection
      );
      setUpdateTable(false);
    }
  }, [updateTable]);

  const getCoursesList = async (
    currentPage,
    searchTermFilter = "",
    searchColumnFilter = null,
    clientFilter = null,
    teacherFilter = null,
    levelFilter = null,
    modalityFilter = null,
    orderBy = "clientId",
    orderDirection = "DESC"
  ) => {
    dispatch(setListCourses(null));
    let searchColumnFilterURLParam = [];
    if (searchColumnFilter) {
      searchColumnFilterURLParam = searchColumnFilter.map((columnId, index) => {
        return "&searchColumns[" + index + "]=" + encodeURIComponent(columnId);
      });
    }

    let teacherFilterURLParam = [];
    // TODO: don't hardcode this
    if (role === 2) {
      teacherFilterURLParam = [
        `&teacherFilter[0]=${userLogged.id}`,
      ];
    } else {
      if (teacherFilter) {
        teacherFilterURLParam = teacherFilter.map((teacher, index) => {
          return "&teacherFilter[" + index + "]=" + teacher.id;
        });
      }
    }

    let clientFilterURLParam = [];
    if (clientFilter) {
      clientFilterURLParam = clientFilter.map((clientId, index) => {
        return "&clientFilter[" + index + "]=" + clientId;
      });
    }

    let levelFilterURLParam = [];
    if (levelFilter) {
      levelFilterURLParam = levelFilter.map((levelId, index) => {
        return "&levelFilter[" + index + "]=" + levelId;
      });
    }

    let modalityFilterURLParam = "";
    if (modalityFilter) {
      modalityFilterURLParam = "&modalityFilter=" + modalityFilter.value;
    }

    const requestParams =
      new URLSearchParams({
        page: currentPage,
        orderBy: orderBy,
        orderDirection: orderDirection,
        searchTerm: searchTermFilter,
      }).toString() +
      clientFilterURLParam.join("") +
      teacherFilterURLParam.join("") +
      levelFilterURLParam.join("") +
      modalityFilterURLParam +
      searchColumnFilterURLParam.join("");

    axios
      .get("/course/list?" + requestParams)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentPage(res.data.currentPage));
          dispatch(setTotalPages(res.data.totalPages));
          dispatch(setListCourses(res.data.courses));
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error(error.message)
      });
  };

  const getAllLevels = async () => {
    if (allLevels.length > 0) {
      return allLevels;
    } else {
      return await axios
        .get("/level/getAll")
        .then((res) => {
          if (res.status === 200) {
            const levels = res.data.levels.map((level) => {
              return { value: level.id, label: level.name };
            });
            dispatch(setAllLevels(levels));
            return levels;
          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            const cookies = new Cookies();
            cookies.remove("jwt_token");
            navigate("/login");
          }
          console.error(error.message)
        });
    }
  };

  const getAllModalities = async () => {
    if (allModalities.length > 0) {
      return allModalities;
    } else {
      return await axios
        .get("/modality/getAll")
        .then((res) => {
          if (res.status === 200) {
            const modalities = res.data.modalities.map((modality) => {
              return { value: modality.id, label: modality.name };
            });
            dispatch(setAllModalities(modalities));
            return modalities;
          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            const cookies = new Cookies();
            cookies.remove("jwt_token");
            navigate("/login");
          }
          console.error(error.message)
        });
    }
  };

  const getAllClients = async () => {
    return await axios
      .get("/client/getAll")
      .then((res) => {
        if (res.status === 200) {
          const clients = res.data.clients.map((client) => {
            return { value: client.id, label: client.businessName };
          });
          setAllClients(clients);
          return clients;
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error(error.message)
      });
  };

  const getAllTeachers = async () => {
    return await axios
      .get("/user/getAllTeachers")
      .then((res) => {
        if (res.status === 200) {
          const teachers = res.data.teachers.map((teacher) => {
            return {
              value: teacher,
              label: teacher.firstName + " " + teacher.lastName,
            };
          });
          setAllTeachers(teachers);
          return teachers;
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error(error.message)
      });
  };

  const allLessonWeekdays = [
    { value: "Lunes", label: "Lunes" },
    { value: "Martes", label: "Martes" },
    { value: "Miércoles", label: "Miércoles" },
    { value: "Jueves", label: "Jueves" },
    { value: "Viernes", label: "Viernes" },
    { value: "Sábado", label: "Sábado" },
    { value: "Domingo", label: "Domingo" },
  ];

  const allLessonDuration = [
    { value: 30, label: 30 },
    { value: 45, label: 45 },
    { value: 60, label: 60 },
    { value: 90, label: 90 },
    { value: 120, label: 120 },
  ];

  const handleFilterDropdownValues = (onChangeFilterDropdownValues) => {
    let filteredColumnIds = onChangeFilterDropdownValues.map(
      (columnFiltered) => {
        return columnFiltered.value;
      }
    );
    setSearchColumnFilter(filteredColumnIds);
  };

  const handleChangePage = (page) => {
    dispatch(setCurrentPage(page));
    if (token) {
      getCoursesList(
        page,
        searchTermFilter,
        searchColumnFilter,
        clientFilter,
        teacherFilter,
        levelFilter,
        modalityFilter,
        orderBy,
        orderDirection
      );
    }
  };

  const handleEdit = async (event, course) =>
  {
    event.preventDefault();

    if (!isSignedInCalendar) {
      setCalendarAlertModal(true);
      return;
    }

    return await loadClientStudents(course.client.id)
    .then(()=>{
      setLastAction("edit");
      setModel(course);
      setIsEdit(true);
      setShowModal(true);
    })
  };

  const handleDelete = (event, course) => {
    event.preventDefault();

    if (!isSignedInCalendar) {
      setCalendarAlertModal(true);
      return;
    }

    setLastAction("delete");
    setModel(course);
    setIsDelete(true);
    setShowModal(true);
  };

  const handleCreate = (event) => {
    event.preventDefault();

    if (!isSignedInCalendar) {
      setCalendarAlertModal(true);
      return;
    }

    setLastAction("create");
    setIsCreate(true);
    setShowModal(true);
  };

  const handleView =  async (event, course) => {

    event.preventDefault();
    await loadClientStudents(course.client.id);
    setLastAction("view");
    setModel(course);
    setIsView(true);
    setShowModal(true);
  };

  const handleSortColumn = (columnId) => {
    return (event) => {
      event.preventDefault();
      setOrderBy(columnId);
      if (orderDirection === "ASC") {
        setOrderDirection("DESC");
      } else {
        setOrderDirection("ASC");
      }
      setUpdateTable(true);
    };
  };

  const handleFilter = () => {
    setSearchTermFilter(searchTerm);
    setOrderBy("clientId");
    setOrderDirection("ASC");
    dispatch(setCurrentPage(1));
    setUpdateTable(true);
  };

  const resetFilter = () => {
    setSearchTerm("");
    setSearchTermFilter("");
    setSearchColumnFilter("");
    setOrderBy("clientId");
    setOrderDirection("ASC");
    dispatch(setCurrentPage(1));
    setModalityFilter("");
    setLevelFilter("");
    setTeacherFilter("");
    setClientFilter("");
    setUpdateTable(true);
  };

  const handleSearchTextChange = (event) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  const hideModal = () => {
    resetModel();
    setShowModal(false);
    if (isDelete) {
      setIsDelete(false);
    } else if (isCreate) {
      setIsCreate(false);
    } else if (isEdit) {
      setIsEdit(false);
    } else if (isView) {
      setIsView(false);
    }
    setResponseMessage("");
  };

  const resetModel = () => {
    setModel({
      id: null,
      name: "",
      startDate: "",
      endDate: "",
      objectives: "",
      teachers: [],
      client: {},
      level: {},
      modality: {},
      days: [],
      students: [],
    });
  };

  const hasPermission = (action) => {
    return sectionActions && sectionActions.includes(action);
  };

  const actionCourse = async (action, data = null) => {
    if (!data) {
      data = {...model};
    }
    let newData = action === 'create'? await handleCreateEventWithVideo(data) : await handleEditEvent(data);
    handleDeleteEvent(data)
    axios
      .post("/course/" + action, action !== "delete" ? newData : data)
      .then((res) => {
        if (res.status === 200) {
          setResponseMessage(res.data.status);
          setUpdateTable(true);
        } else {
          setResponseMessage(res.data.message);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }

        setResponseMessage("error");
        console.error(error.message)
      });

    hideModal();
  };

  useEffect(() => {
    if (responseMessage === "success") {
      setMessage(
        lastAction === "create"
          ? "¡Curso creado exitosamente!"
          : lastAction === "edit"
          ? "¡Curso modificado exitosamente!"
          : lastAction === "delete"
          ? "¡Curso borrado existosamente!"
          : ""
      );
    } else if (responseMessage === "invalid_data") {
      setMessage("Datos inválidos.");
    } else if (responseMessage === "error") {
      setMessage("Ocurrió un error. ¡Vuelva a intentar mas tarde!");
    }
    if (responseMessage) {
      setShowResponseMessage(true);
    }
  }, [responseMessage]);

  const hideResponseMessage = () => {
    setShowResponseMessage(false);
    setResponseMessage("");
    setMessage("");
  };

  const loadClientStudents = async (clientId) =>
  {
    if (clientId) {
      if (clientStudents[clientId] !== undefined && clientStudents[clientId].length > 0) {
        setAllStudents(mapStudentsToOptions(clientStudents[clientId]));
      } else {
        const response = await axios.get(`/student/getAllStudentsByClient/${clientId}`);
        if (response.status === 200) {
          const newStudents = response.data.students;
          dispatch(setClientStudents({ clientId: clientId, students: newStudents }));
          setAllStudents(mapStudentsToOptions(newStudents));
        }
      }
    }
  };

  const mapStudentsToOptions = (students) =>
  {
    return students.map((student) =>
    ({
      value: student,
      label: `${student.firstName} ${student.lastName}`,
    }));
  };

  const handleCreateEventWithVideo = async (values ) => {
    if (isCreate) {
      // Get the current date and time
      const currentDate = new Date(values.startDate + " 12:00:00");
      const finalDate = new Date(values.endDate + "T12:00:00Z");
      const formatDateToUTC = (date) => {
        return date.toISOString().replace(/[-:]/g, "").replace(/\..*/, "Z");
      };

      function getNextWeekday(date, day) {
        const daysOfWeek = [
          "Domingo",
          "Lunes",
          "Martes",
          "Miércoles",
          "Jueves",
          "Viernes",
          "Sábado",
        ];
        const dayIndex = daysOfWeek.indexOf(day);
        const resultDate = new Date(date.getTime());
        resultDate.setDate(
          date.getDate() + ((dayIndex + 7 - date.getDay()) % 7)
        );
        return resultDate;
      }

      // Function to add minutes to a given date
      function addMinutes(date, minutes) {
        const resultDate = new Date(date.getTime());
        resultDate.setMinutes(date.getMinutes() + minutes);
        return resultDate;
      }

      const attendees = [];

      values.students.forEach((student) => {
        attendees.push({ email: student.email });
      });
      values.teachers.forEach((teacher) => {
        attendees.push({ email: teacher.email });
      });

         for (let i=0; values.days.length > i; i++) {
          const day = values.days[i];
        const startDay = day.weekDay;
        const startTime = day.startTime;
        let recurrence = "RRULE:FREQ=WEEKLY;BYDAY=";
        switch (startDay) {
          case "Domingo":
            recurrence += `SU;UNTIL=${formatDateToUTC(finalDate)}`;
            break;
          case "Lunes":
            recurrence += `MO;UNTIL=${formatDateToUTC(finalDate)}`;
            break;
          case "Martes":
            recurrence += `TU;UNTIL=${formatDateToUTC(finalDate)}`;
            break;
          case "Miércoles":
            recurrence += `WE;UNTIL=${formatDateToUTC(finalDate)}`;
            break;
          case "Jueves":
            recurrence += `TH;UNTIL=${formatDateToUTC(finalDate)}`;
            break;
          case "Viernes":
            recurrence += `FR;UNTIL=${formatDateToUTC(finalDate)}`;
            break;
          case "Sábado":
            recurrence += `SA;UNTIL=${formatDateToUTC(finalDate)}`;
            break;
          default:
        }

        // Find the start date by finding the next occurrence of the start day
        const startDateTime = getNextWeekday(currentDate, startDay);
        startDateTime.setHours(Number(startTime.split(":")[0]));
        startDateTime.setMinutes(Number(startTime.split(":")[1]));

        // Add 60 minutes to the start date
        const endDateTime = addMinutes(startDateTime, day.lessonDuration);

        const objectEvent = {
          eventTitle: values.name,
          dateTimeStart: startDateTime.toISOString(),
          recurrence: [recurrence],
          timeZone: "America/Argentina/Buenos_Aires",
          dateTimeEnd: endDateTime.toISOString(),
          attendees: attendees,
        };
        const resultEvent = await createEventWithVideo(objectEvent);
        values.days[i].googleId = resultEvent.result.id;
      };
      return values
    }
  };

  const handleEditEvent = async (values) => {
    if(isEdit){
          const currentDate = new Date(values.startDate + " 12:00:00");
          const finalDate = new Date(values.endDate + "T12:00:00Z");
          const formatDateToUTC = (date) => {
            return date.toISOString().replace(/[-:]/g, "").replace(/\..*/, "Z");
          };

          function getNextWeekday(date, day) {
            const daysOfWeek = [
              "Domingo",
              "Lunes",
              "Martes",
              "Miércoles",
              "Jueves",
              "Viernes",
              "Sábado",
            ];
            const dayIndex = daysOfWeek.indexOf(day);
            const resultDate = new Date(date.getTime());
            resultDate.setDate(
              date.getDate() + ((dayIndex + 7 - date.getDay()) % 7)
            );
            return resultDate;
          }

          // Function to add minutes to a given date
          function addMinutes(date, minutes) {
            const resultDate = new Date(date.getTime());
            resultDate.setMinutes(date.getMinutes() + minutes);
            return resultDate;
          }

          const attendees = [];

          values.students.forEach((student) => {
            attendees.push({ email: student.email });
          });
          values.teachers.forEach((teacher) => {
            attendees.push({ email: teacher.email });
          });

          for (let i=0; values.days.length > i; i++) {
            const day = values.days[i];
            const startDay = day.weekDay;
            const startTime = day.startTime;
            let recurrence = "RRULE:FREQ=WEEKLY;BYDAY=";
            switch (startDay) {
              case "Domingo":
                recurrence += `SU;UNTIL=${formatDateToUTC(finalDate)}`;
                break;
              case "Lunes":
                recurrence += `MO;UNTIL=${formatDateToUTC(finalDate)}`;
                break;
              case "Martes":
                recurrence += `TU;UNTIL=${formatDateToUTC(finalDate)}`;
                break;
              case "Miércoles":
                recurrence += `WE;UNTIL=${formatDateToUTC(finalDate)}`;
                break;
              case "Jueves":
                recurrence += `TH;UNTIL=${formatDateToUTC(finalDate)}`;
                break;
              case "Viernes":
                recurrence += `FR;UNTIL=${formatDateToUTC(finalDate)}`;
                break;
              case "Sábado":
                recurrence += `SA;UNTIL=${formatDateToUTC(finalDate)}`;
                break;
              default:
            }

            // Find the start date by finding the next occurrence of the start day
            const startDateTime = getNextWeekday(currentDate, startDay);
            startDateTime.setHours(Number(startTime.split(":")[0]));
            startDateTime.setMinutes(Number(startTime.split(":")[1]));

            // Add 60 minutes to the start date
            const endDateTime = addMinutes(startDateTime, day.lessonDuration);
            const objectEvent = {
              eventTitle: values.name,
              dateTimeStart: startDateTime.toISOString(),
              recurrence: [recurrence],
              timeZone: "America/Argentina/Buenos_Aires",
              dateTimeEnd: endDateTime.toISOString(),
              attendees: attendees,
              googleId: day.googleId
            };
            if ( !values.days[i].hasOwnProperty('googleId') ) {
              let resultEvent = await createEventWithVideo(objectEvent);
              values.days[i].googleId = resultEvent.result.id;
            }
            else {
              await editEventWithVideo(objectEvent);
            }

          };
          return values
    }
  }

  const getStartTimeAndEndTime = (time, minutes) => {
    const splitTime = time.split(":");
    const startDate = new Date(
      0,
      0,
      0,
      parseInt(splitTime[0]),
      parseInt(splitTime[1])
    ).getTime();
    const endDate = new Date(startDate).getTime() + minutes * 60000;

    const startTime = (new Date(startDate).toLocaleTimeString([], {
      hour: '2-digit', minute: '2-digit'
    }));
    const endTime = (new Date(endDate).toLocaleTimeString([], {
      hour: '2-digit', minute: '2-digit'
    }));

    return [startTime, endTime];
  };

  const handleDeleteEvent = (data) => {
    if (isDelete) {
        for (let i = 0; i < data.days.length; i++){
          deleteEvent(data.days[i].googleId)
        }
    }
  };

  const handelDeleteDay = ( googleId ) => {
    if (typeof googleId !== 'undefined') {
      deleteEvent(googleId)
    }
  }

  return (
    <>
      {isLoadingSection && (
        <Spinner
          color="#000000"
          size={80}
        />
      )}
      {!isLoadingSection && (
        <>
          <div className="mx-4 font-bold text-left text-sky-600/70 text-3xl mb-8">Cursos</div>
            <div className="flex flex-col">
              <div className="overflow-x-auto">
                <div className="flex items-center gap-4 py-3 px-3 md:px-10 mb-4">
                  <TableFilter
                    placeholderText={"Buscar curso..."}
                    onChangeSearchText={handleSearchTextChange}
                    onFilter={handleFilter}
                    onChangeFilterDropdownValues={handleFilterDropdownValues}
                    tableColumns={columns}
                    onResetFiltersAndOrder={resetFilter}
                    hideSearchText={true}
                  />
                  {hasPermission("create") && (
                    <div style={{marginLeft: "auto"}}>
                      <div className="relative">
                        <button
                          className="bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded"
                          type="button"
                          onClick={(ev) => handleCreate(ev)}
                        >
                          Nuevo curso
                        </button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="p-3 w-full inline-block align-middle">
                  <div className="overflow-auto border rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                        <tr>
                          {columns.map((column, index) => {
                            if(column.showInTable) {
                              if(column.columnId !== 'dateAndTime' && column.columnId !== 'teacherId') {
                                return (
                                  <SortableTableHeaders
                                    id={column.columnId}
                                    key={"header_" + index}
                                    headerText={column.columnName}
                                    onSortColumn={handleSortColumn(column.columnId)}
                                    sortBy={orderBy}
                                    sortOrder={orderDirection}
                                  />
                                )
                              } else {
                                return (
                                  <th scope="col" key={"header_" + column.columnId} className={"px-6 py-3 text-sm font-bold text-center text-sky-500/80"}>
                                    {column.columnName}
                                  </th>
                                )
                              }
                            } else {
                              return false;
                            }
                          })}
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {
                          listCourses && listCourses.length > 0 ?
                            listCourses.map((course, idx) => {
                              return (
                                <tr key={"row_" + idx}>
                                  <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                                    {course.client.businessName}
                                  </td>
                                  <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                                    {course.teachers.map((teacher,i)=> {
                                      return (
                                        <div key={i}>
                                          {teacher.firstName +" "+ teacher.lastName}
                                        </div>
                                      )
                                    })}
                                  </td>
                                  <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                                    {course.level.name}
                                  </td>
                                  <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                                    {course.modality.name}
                                  </td>
                                  <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                                    {course.days.map((item,i)=> {
                                      const [startTime, endTime] = getStartTimeAndEndTime(item.startTime, item.lessonDuration);
                                      return (
                                        <div key={i}>
                                          {`${item.weekDay} de ${startTime} a ${endTime}`}
                                        </div>
                                      )
                                    })}
                                  </td>
                                  <td className="px-6 py-4 flex justify-center gap-x-4 text-sm whitespace-nowrap">
                                    <div className="px-6 py-4 flex justify-center gap-x-4 text-sm whitespace-nowrap">
                                      {hasPermission("update") && (
                                        <button
                                          className="text-zinc-500 hover:text-zinc-600"
                                          title="Editar"
                                          onClick={(ev) => handleEdit(ev, course)}
                                        >
                                          <Pen />
                                        </button>
                                      )}
                                      {hasPermission("delete") && (
                                        <button
                                          className="text-zinc-500 hover:text-zinc-600"
                                          title="Borrar"
                                          onClick={(ev) => handleDelete(ev, course)}
                                        >
                                          <TrashCan />
                                        </button>
                                      )}
                                      <button
                                        className="text-zinc-500 hover:text-zinc-600"
                                        href=""
                                        title="Ver"
                                        onClick={(ev) => handleView(ev, course)}
                                      >
                                        <InformationCircle />
                                      </button>
                                      <Exporter data={course}/>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          :
                            <>
                              <tr className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                                <td></td>
                                <td></td>
                                <td className="px-6 py-4 text-sm text-right text-gray-800 whitespace-nowrap">
                                  "No se encontraron resultados"
                                </td>
                              </tr>
                            </>
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onChangePage={handleChangePage}
            />

            <ResponseMessage
              open={showResponseMessage}
              onClose={hideResponseMessage}
              type={responseMessage === "success" ? "success" : "error"}
              message={message}
            />

      <Modal
        open={showModal}
        onClose={hideModal}
        title={
          isCreate
            ? "Nuevo curso"
            : isEdit
            ? "Editar curso"
            : isDelete
            ? "Borrar curso"
            : "Ver curso"
        }
        backdrop={true}
        cross={true}
      >
        {isCreate || isEdit || isView ? (
          <Formik
            initialValues={model}
            validate={values => {
              const errors = {};
              if (!values.name) {
                errors.name = "Campo requerido";
              }
              if (!values.client || Object.keys(values.client).length === 0) {
                errors.client = "Campo requerido";
              }
              if (!values.level || Object.keys(values.level).length === 0) {
                errors.level = "Campo requerido";
              }
              if (!values.modality || Object.keys(values.modality).length === 0) {
                errors.modality = "Campo requerido";
              }
              if (!values.teachers || values.teachers.length === 0) {
                errors.teachers = "Campo requerido";
              }
              if (!values.students || values.students.length === 0) {
                errors.students = "Campo requerido";
              }
              if (!values.startDate) {
                errors.startDate = "Campo requerido";
              }
              if (!values.endDate) {
                errors.endDate = "Campo requerido";
              }
              if (values.startDate && values.endDate) {
                const auxStartDate = new Date(values.startDate);
                const auxEndDate = new Date(values.endDate);
                if (auxStartDate > auxEndDate) {
                  errors.endDate = "La fecha de inicio no puede ser mayor a la fecha de fin";
                }
              }
              if (!values.objectives) {
                errors.objectives = "Campo requerido";
              }
              if (!values.days || values.days.length === 0) {
                errors.days = "Campo requerido";
              } else {
                values.days.forEach((day) => {
                  if (day === "") {
                    errors.days = "Campo requerido";
                  } else {
                    if (Object.keys(day).length === 0) {
                      errors.days = "Campo requerido";
                    } else {
                      if (!day.weekDay) {
                        errors.days = "Día de clase es un campo requerido";
                      }
                      if (!day.startTime) {
                        errors.days = "Horario de inicio es un campo requerido";
                      }
                      if (!day.lessonDuration) {
                        errors.days = "Duración es un campo requerido";
                      }
                    }
                  }
                });
              }
              return errors;
            }}
                onSubmit={(values, { setSubmitting }) => {
                  if (isCreate) {
                    actionCourse("create", values);
                  } else if (isEdit) {
                    actionCourse("edit", values);
                  }
                }}
              >
                {({ values, setFieldValue, isSubmitting, isValid }) => {
                  if (isSubmitting && !isValid) {
                    setResponseMessage("invalid_data");
                  }
                  return (
                    <Form>
                      <div className="md:flex">
                        <div className="md:w-1/2 px-3">
                          <label
                            htmlFor="name"
                            className="block text-sm font-semibold text-gray-800"
                          >
                            Nombre del curso*
                          </label>
                          <Field
                            className="shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="name"
                            type="text"
                            name="name"
                            disabled={isView}
                          />
                          <ErrorMessage
                            name="name"
                            className="text-red-600"
                            component="div"
                          />
                        </div>

                        <div className="md:w-1/2 px-3">
                          <label
                            htmlFor="client.id"
                            className="block text-sm font-semibold text-gray-800"
                          >
                            Cliente*
                          </label>
                          <Field name="client.id">
                            {({field}) =>
                              <Select
                                as="select"
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600 my-2"
                                isDisabled={isView}
                                placeholder="Seleccionar"
                                options={allClients}
                                value={field.value && allClients.find(client => client.value === field.value)}
                                onChange={(selectedClient) => {
                                  setFieldValue("client.id", selectedClient.value);
                                  loadClientStudents(selectedClient.value);
                                }}
                              />
                            }
                          </Field>
                          <ErrorMessage
                            name="client"
                            className="text-red-600"
                            component="div"
                          />
                        </div>
                      </div>

                      <div className="md:flex">
                        <div className="md:w-1/2 px-3">
                          <label
                            htmlFor="startDate"
                            className="block text-sm font-semibold text-gray-800"
                          >
                            Fecha de inicio*
                          </label>
                          <Field
                            type="date"
                            className="shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="startDate"
                            name="startDate"
                            disabled={isView}
                          ></Field>
                          <ErrorMessage
                            name="startDate"
                            className="text-red-600"
                            component="div"
                          />
                        </div>

                        <div className="md:w-1/2 px-3">
                          <label
                            htmlFor="endDate"
                            className="block text-sm font-semibold text-gray-800"
                          >
                            Fecha de fin*
                          </label>
                          <Field
                            type="date"
                            className="shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="endDate"
                            name="endDate"
                            disabled={isView}
                          ></Field>
                          <ErrorMessage
                            name="endDate"
                            className="text-red-600"
                            component="div"
                          />
                        </div>
                      </div>

                      <div className="md:flex">
                        <div className="md:w-1/2 px-3">
                          <label
                            htmlFor="teachers"
                            className="block text-sm font-semibold text-gray-800"
                          >
                            Profesor/es*
                          </label>
                          <Field name="teachers">
                            {({ field }) => (
                              <Select
                                as="select"
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600 my-2"
                                id="teachers"
                                name="teachers"
                                closeMenuOnSelect={false}
                                isDisabled={isView}
                                isMulti
                                placeholder="Seleccionar"
                                options={allTeachers}
                                value={
                                  field.value &&
                                  allTeachers.filter((teacher) => {
                                    return field.value.find((item) => {
                                      return (
                                        (item.id ? item.id : item) ===
                                        teacher.value.id
                                      );
                                    });
                                  })
                                }
                                onChange={(selectedTeachers) => {
                                  setFieldValue(
                                    "teachers",
                                    selectedTeachers.map(
                                      (teacher) => teacher.value
                                    )
                                  );
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="teachers"
                            className="text-red-600"
                            component="div"
                          />
                        </div>

                        <div className="md:w-1/2 px-3">
                          <label
                            htmlFor="level.id"
                            className="block text-sm font-semibold text-gray-800"
                          >
                            Nivel*
                          </label>
                          <Field name="level.id">
                            {({field}) =>
                              <Select
                                as="select"
                                id="level.id"
                                name="level.id"
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600 my-2"
                                isDisabled={isView}
                                placeholder="Seleccionar"
                                options={allLevels}
                                value={field.value && allLevels.find(level => level.value === field.value)}
                                onChange={(selectedLevel) => {
                                  setFieldValue("level.id", selectedLevel.value);
                                }}
                              />
                            }
                          </Field>
                          <ErrorMessage
                            name="level"
                            className="text-red-600"
                            component="div"
                          />
                        </div>
                      </div>

                      <div className="md:flex">
                        <div className="md:w-1/2 px-3">
                          <label
                            htmlFor="modality.id"
                            className="block text-sm font-semibold text-gray-800"
                          >
                            Modalidad*
                          </label>
                          <Field name="modality.id">
                            {({field}) =>
                              <Select
                                as="select"
                                id="modality.id"
                                name="modality.id"
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600 my-2"
                                isDisabled={isView}
                                placeholder="Seleccionar"
                                options={allModalities}
                                value={field.value && allModalities.find(modality => modality.value === field.value)}
                                onChange={(selectedModality) => {

                                  setFieldValue( "modality.id",  selectedModality.value )

                                  setFieldValue( "students", [] )
                                }}
                              />
                            }
                          </Field>
                          <ErrorMessage
                            name="modality"
                            className="text-red-600"
                            component="div"
                          />
                        </div>

                        <div className="md:w-1/2 px-3">
                          <label
                            htmlFor="students"
                            className="block text-sm font-semibold text-gray-800"
                          >
                            Alumnos*
                          </label>
                          <Field name="students">
                            {({ field }) => (
                              <Select
                                as="select"
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600 my-2"
                                id="students"
                                name="students"
                                closeMenuOnSelect={values.modality?.id === 1}
                                isDisabled={isView}
                                isMulti = {values.modality?.id !== 1}
                                placeholder="Seleccionar"
                                options={allStudents}
                                value={
                                  allStudents.filter((student) => {
                                    return field.value.find((item) => {
                                      return (
                                        (item.id ? item.id : item) ===
                                        student.value.id
                                      );
                                    });
                                  })
                                 }
                                onChange={(selectedStudents) => {
                                  if(values.modality && parseInt(values.modality.id) !== 1)
                                  {
                                    setFieldValue("students", selectedStudents.map( (student) => student.value ) )
                                  }
                                  else
                                  {
                                    setFieldValue( "students", [selectedStudents.value]  );
                                  }
                                }}
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="students"
                            className="text-red-600"
                            component="div"
                          />
                        </div>
                      </div>

                      <div className="md:w-1/1 px-3 mb-2">
                        <FieldArray
                          name="days"
                          render={arrayHelpers => (
                            <div className="w-full">
                              {values.days && values.days.length > 0 ? (
                                values.days.map((friend, index) => (
                                  <div key={index} className="w-full md:flex">
                                    <div className="md:w-1/4 px-3">
                                      <label
                                        htmlFor={`days.${index}.weekDay`}
                                        className="block text-sm font-semibold text-gray-800"
                                      >
                                        Días de clase*
                                      </label>
                                      <Field name={`days.${index}.weekDay`}>
                                        {({field}) =>
                                          <Select
                                            as="select"
                                            className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600 my-2"
                                            id={`days.${index}.id`}
                                            name={`days.${index}.weekDay`}
                                            closeMenuOnSelect={false}
                                            isDisabled={isView}
                                            placeholder="Seleccionar"
                                            options={allLessonWeekdays}
                                            value={values.days[index] && allLessonWeekdays.find(weekdays =>{
                                              return weekdays.label === values.days[index].weekDay
                                            })}
                                            onChange={(selectedWeekday) => {
                                              setFieldValue(`days.${index}.weekDay`, selectedWeekday.label);
                                            }}
                                          />
                                        }
                                      </Field>
                                    </div>
                                    <div className="md:w-1/4 px-3">
                                      <div>
                                        <label
                                          htmlFor={`days.${index}.startTime`}
                                          className="block text-sm font-semibold text-gray-800"
                                        >
                                          Horario de inicio*
                                        </label>
                                        <Field
                                          className="shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                          id={`days.${index}.startTime`}
                                          type="time"
                                          step={60 * 30}
                                          name={`days.${index}.startTime`}
                                          disabled={isView}
                                          onChange={(event) => {
                                            setFieldValue(`days.${index}.startTime`, event.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="md:w-1/10 px-1">
                                      <div>
                                        <label
                                          htmlFor={`days.${index}.lessonDuration`}
                                          className="block text-sm font-semibold text-gray-800"
                                        >
                                          Duración*
                                        </label>
                                        <Select
                                          as="select"
                                          className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600 my-2"
                                          id={`days.${index}.lessonDuration`}
                                          name={`days.${index}.lessonDuration`}
                                          isDisabled={isView}
                                          placeholder="Seleccionar"
                                          options={allLessonDuration}
                                          value={values.days[index]?.lessonDuration !== undefined && allLessonDuration.find(lessonDuration => {
                                            return lessonDuration.value === values.days[index].lessonDuration
                                          })}
                                          onChange={(lessonDuration) => {
                                            setFieldValue(`days.${index}.lessonDuration`, lessonDuration.value);
                                          }}
                                        >
                                        </Select>
                                      </div>
                                    </div>
                                    <div className="md:w-1/10 py-8 px-1">
                                      <div style={{color:"#757575"}}>
                                        <button
                                          className="text-zinc-500 hover:text-zinc-600"
                                          type="button"
                                          title="Borrar"
                                          onClick={() => { handelDeleteDay(values.days[index]?.googleId); arrayHelpers.remove(index); }}
                                          disabled={isView}
                                        >
                                          <TrashCan />
                                        </button>
                                      </div>
                                    </div>
                                    <div className="md:w-1/10 py-8 px-1">
                                      <div style={{color:"#757575"}}>
                                        <button
                                            className="text-zinc-500 hover:text-zinc-600"
                                            type="button"
                                            title="Borrar"
                                            onClick={() => arrayHelpers.insert(index + 1, '')}
                                            disabled={isView}
                                          >
                                          <PlusCircle />
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <button type="button" onClick={() => arrayHelpers.push('')}>
                                  <p className="block text-sm font-semibold text-gray-800">Añadir Horario</p>
                                  <PlusCircle />
                                </button>
                              )}
                              <ErrorMessage
                                name="days"
                                className="text-red-600"
                                component="div"
                              />
                            </div>
                          )}
                        />
                      </div>

                      <div className="md:w-1/1 px-3">
                        <label
                          htmlFor="objectives"
                          className="block text-sm font-semibold text-gray-800"
                        >
                          Objetivos del curso*
                        </label>
                        <Field
                          className="shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          as="textarea"
                          id="objectives"
                          name="objectives"
                          disabled={isView}
                        />
                        <ErrorMessage
                          name="objectives"
                          className="text-red-600"
                          component="div"
                        />
                      </div>
                      <div className="items-center gap-2 mt-3 sm:flex">
                        {!isView && (
                          <button
                            type="submit"
                            className="w-full mt-2 p-2.5 flex-1 text-white font-bold bg-orange-500/80 hover:bg-orange-500 rounded-md outline-none ring-offset-2 ring-orange-500 focus:ring-2"
                          >
                            {isCreate ? "Crear" : "Editar"}
                          </button>
                        )}
                        <button
                          className="w-full mt-2 p-2.5 flex-1 text-white bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none border ring-offset-2 ring-zinc-500 focus:ring-2"
                          onClick={() => hideModal()}
                        >
                          Cerrar
                        </button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <>
                <p className="mt-2 text-[18px] leading-relaxed text-gray-500">
                  ¿Está seguro de borrar al curso?
                </p>
                <div className="items-center gap-2 mt-3 sm:flex">
                  <button
                    type="submit"
                    className="w-full mt-2 p-2.5 flex-1 text-white bg-red-500/80 hover:bg-red-500 rounded-md outline-none ring-offset-2 ring-red-500 focus:ring-2"
                    onClick={() => actionCourse("delete")}
                  >
                    Borrar
                  </button>
                  <button
                    className="w-full mt-2 p-2.5 flex-1 text-white bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none border ring-offset-2 ring-zinc-500 focus:ring-2"
                    onClick={() => hideModal()}
                  >
                    Cancelar
                  </button>
                </div>
              </>
            )}
          </Modal>
          <Modal
            open={calendarAlertModal}
            onClose={() => setCalendarAlertModal(false)}
            title="Inicie sesión con Google"
            backdrop={true}
            cross={true}
          >
            <div className="max-w-xl">
              <p
                className="my-4"
              >
                Para poder crear, editar o eliminar cursos tiene que iniciar sesión con su cuenta de Google. Para ello, por favor, diríjase a Inicio y haga click en el botón de "Vincular Google Calendar".
              </p>
              <div className="flex w-full justify-end">
                <Link
                  to="/home"
                  className="bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded"
                >
                  Ir a Inicio
                </Link>
              </div>
            </div>
          </Modal>
        </>
      )}
    </>
  );
};

export default Courses;
