import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedSection } from "./layoutReducer";
import useNavSections from "../../../hooks/useNavSections";
import Icon from '../Icon/Icon';

function Sidebar() {
  const dispatch = useDispatch();
  const sections = useNavSections();

  const generateRoute = (section) => {
    switch (section.toLowerCase()) {
      case "home":
        return "/home";
      case "user":
        return "/users";
      case "client":
        return "/clients";
      case "course":
        return "/courses";
      case "class":
        return "/classes";
      case "report":
        return "/reports";
      default:
        return "";
    }
  };

  const getIconSection = (section) => {
    switch (section.toLowerCase()) {
      case "client":
        return (
          <Icon icon="users" color="white" size="xl" />
        );

      case "course":
        return (
          <Icon icon="book-open" color="white" size="xl" />
        );

      case "class":
        return (
          <Icon icon="chalkboard-user" color="white" size="xl" />
        );

      case "report":
        return (
          <Icon icon="clock" color="white" size="xl" />
        );

      case "user":
        return (
          <Icon icon="user" color="white" size="xl" />
        );

      default:
        return (
          <Icon icon="house" color="white" size="xl" />
        );
    }
  };

  const getSectionName = (section) => {
    switch (section.toLowerCase()) {
      case "home":
        return "Inicio";
      case "client":
        return "Clientes";
      case "course":
        return "Cursos";
      case "class":
        return "Clases";
      case "report":
        return "Reportes";
      case "user":
        return "Usuarios";

      default:
        return section;
    };
  }

  return (
    sections && (
      <>
        <div
          id="sidebar"
          className="bg-sky-600/70 w-20 flex-shrink-0 flex-col p-3 min-h-full shadow duration-300 hidden md:block"
        >
          <div className="space-y-3 flex-1">
            <ul className="flex flex-col gap-y-4 pt-2 pb-4 text-sm">
              {sections.length > 0 &&
                sections.map((section, index) => {
                  return (
                    <li
                      className="flex justify-center rounded-sm"
                      key={index}
                    >
                      <Link
                        to={generateRoute(section)}
                        title={getSectionName(section)}
                        onClick={() => {
                          dispatch(setSelectedSection(section));
                        }}
                      >
                        {getIconSection(section)}
                      </Link>
                    </li>
                  );
                })}
              {sections.length === 0 && (
                <li
                  className="flex justify-center rounded-sm"
                >
                  <Link
                    to={generateRoute("home")}
                    title={getSectionName("home")}
                    onClick={() => {
                      dispatch(setSelectedSection("home"));
                    }}
                  >
                    {getIconSection("home")}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </>
    )
  );
}

export default Sidebar;
