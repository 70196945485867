import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

const Spinner = (props) => {

  return (
    <div className="flex h-full w-full justify-center align-middle">
      <ClipLoader
        {...props}
        className="my-auto"
        loading={true}
        aria-label="Cargando..."
      />
    </div>
  );

};

export default Spinner;
