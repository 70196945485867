import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Cookies from "universal-cookie";
import { useLocation, useNavigate } from "react-router-dom";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Container from "./Container";
import Modal from "../Modal/Modal";
import {
  setUserLogged,
  setSections,
  setToken,
  setRole,
  setSectionActions,
  setSelectedSection,
  setIsLoading,
} from "./layoutReducer";
import Pen from "../../assets/icons/Pen";
import ResponseMessage from "../ResponseMessage/ResponseMessage";
import { useAxiosWithAuth } from '../../../hooks/useAxiosWithAuth';
import Spinner from "../Spinner/Spinner";

// TODO: move this
const routeSectionToSectionNameMap = {
  "clients": "client",
  "students": "student",
  "courses": "course",
  "classes": "class",
  "reports": "report",
  "users": "user",
}

function Layout() {
  const axios = useAxiosWithAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [editProfile, setEditProfile] = useState(false);
  const [editPassword, setEditPassword] = useState(false);
  const isLoading = useSelector((state) => state.layout.isLoading);
  const userLogged = useSelector((state) => state.layout.userLogged);
  const token = useSelector((state) => state.layout.token);
  const selectedSection = useSelector((state) => state.layout.selectedSection);
  const sections = useSelector((state) => state.layout.sections);
  const location = useLocation();
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [message, setMessage] = useState("");
  const openModals = useSelector((state) => state.layout.openModals);
  const [oldBodyOverflow, setOldBodyOverflow] = useState("");
  const [shouldSaveOldBodyOverflow, setShouldSaveOldBodyOverflow] = useState(true);

  useEffect(() => {
    dispatch(setIsLoading(!sections || sections.length === 0));
  }, [sections]);

  useEffect(() => {
    if (token) {
      getUserLogged();
    } else {
      let cookies = new Cookies();
      let cookie_token = cookies.get("jwt_token");
      if (cookie_token) {
        dispatch(setToken(cookie_token));
      } else {
        navigate("/login");
      }
    }
  }, [token]);

  useEffect(() => {
    if (selectedSection) {
      if (sections.length > 0 && !sections.includes(selectedSection)) {
        navigate("/home");
        return;
      }

      let cookies = new Cookies();
      let user = cookies.get("user");
      if (!user || user.id === undefined) {
        return;
      }

      axios
        .get(
          "/role/" +
            user.roleId +
            "/section/" +
            selectedSection +
            "/actions"
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setSectionActions(res.data.actions));
          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            let cookies = new Cookies();
            cookies.remove("jwt_token", { path: "/" });
            cookies.remove("user", { path: "/" });
            navigate("/login");
          }
        });
    }
  }, [selectedSection]);

  const getUserLogged = () => {
    axios
      .get("/profile")
      .then((res) => {
        if (res.status === 200) {
          let cookies = new Cookies();
          cookies.set("user", res.data.user);
          dispatch(setUserLogged(res.data.user));
          dispatch(setRole(res.data.user.roleId));
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          let cookies = new Cookies();
          cookies.remove("jwt_token", { path: "/" });
          cookies.remove("user", { path: "/" });
          navigate("/login");
        }
      });
  };

  useEffect(() => {
    if (userLogged) {
      getUserLoggedSections();
      let currentSection = "invalid_section";
      const match = location.pathname.match(/^\/?([\w-]+)/);
      if (match !== null && match.length === 2) {
        currentSection = match[1];
      }
      if (routeSectionToSectionNameMap.hasOwnProperty(currentSection)) {
        currentSection = routeSectionToSectionNameMap[currentSection];
      }
      dispatch(setSelectedSection(currentSection));
    }
  }, [userLogged]);

  const getUserLoggedSections = () => {
    let cookies = new Cookies();
    let user = cookies.get("user");
    if (!user || user.id === undefined) {
      return;
    }

    axios
      .get("/role/" + user.roleId + "/sections")
      .then((res) => {
        if (res.status === 200) {
          dispatch(setSections(res.data.sections));
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          let cookies = new Cookies();
          cookies.remove("jwt_token", { path: "/" });
          cookies.remove("user", { path: "/" });
          navigate("/login");
        }
      });
  };

  const editUser = async (data = null) => {
    axios
      .post("/profile", data)
      .then((res) => {
        setResponseMessage(res.data.status);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          let cookies = new Cookies();
          cookies.remove("jwt_token", { path: "/" });
          cookies.remove("user", { path: "/" });
          navigate("/login");
        }
        console.error( message.error )
        setResponseMessage("error");
      });
  };

  useEffect(() => {
    if (openModals.length > 0) {
      if (shouldSaveOldBodyOverflow) {
        setOldBodyOverflow(document.body.style.overflow);
        setShouldSaveOldBodyOverflow(false);
      }
      document.body.style.overflow = "hidden";
    } else {
      setShouldSaveOldBodyOverflow(true);
      document.body.style.overflow = oldBodyOverflow;
    }
  }, [openModals]);

  const handleCloseEditProfile = () => {
    setEditProfile(false);
    setEditPassword(false);
  };

  useEffect(() => {
    if (responseMessage === "success") {
      setMessage("¡Usuario modificado exitosamente!");
    } else if (responseMessage === "error") {
      setMessage("Ocurrió un error. ¡Vuelva a intentar mas tarde!");
    }
    if (responseMessage) {
      setShowResponseMessage(true);
    }
  }, [responseMessage]);

  const hideResponseMessage = () => {
    setShowResponseMessage(false);
    setResponseMessage("");
    setMessage("");
  };

  return (
    <>
      {!isLoading && (
        <>
          <div className="w-full">
            <Navbar setEditProfile={setEditProfile} />
          </div>
          <div className="flex relative min-h-full">
            <Sidebar />
            <Container />
          </div>

          <ResponseMessage
            open={showResponseMessage}
            onClose={hideResponseMessage}
            type={responseMessage === 'success' ? 'success' : 'error'}
            message={message}
          />

          <Modal
            open={editProfile}
            onClose={handleCloseEditProfile}
            title="Perfil"
            backdrop={true}
            cross={true}
          >
            <Formik
              initialValues={userLogged}
              validate={(values) => {
                const errors = {};
                if (!values.firstName) {
                  errors.firstName = "Campo requerido";
                }
                if (!values.lastName) {
                  errors.lastName = "Campo requerido";
                }
                if (!values.email) {
                  errors.email = "Campo requerido";
                } else if (
                  !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})+$/i.test(values.email)
                ) {
                  errors.email = "Correo inválido";
                }
                if (
                  values.referenceEmail !== "" && values.referenceEmail !== null &&
                  !/^[a-zA-Z0-9._%+-]+@gmail(\.[a-zA-Z]{2,})+$/i.test(values.referenceEmail)
                ) {
                  errors.referenceEmail = "Correo inválido. Debe ser una casilla de Gmail";
                }
                if (editPassword) {
                  if (!values.password) {
                    errors.password = "Campo requerido";
                  } else if (
                    !/^(?=.*\d)(?=.*\W|.*_)(?=.*[A-Z])\S{8,12}$/g.test(  values.password )
                  ) {
                    errors.password = "Mínimo de 8 a 12 caracteres, con al menos 1 mayúscula, 1 número y 1 caracter especial";
                  } else if (!values.repeat_password) {
                    errors.repeat_password = "Campo requerido";
                  } else if (values.password !== values.repeat_password) {
                    errors.password = "Las contraseñas no coinciden";
                  }
                }
                return errors;
              }}
              onSubmit={async (values) => {
                editUser(values);
                handleCloseEditProfile();
              }}
            >
              {({ isSubmitting, values, errors }) => (
                <Form>
                  <div className="md:flex">
                    <div className="md:w-1/2 px-3">
                      <label
                        htmlFor="firstName"
                        className="block text-sm font-semibold text-gray-800"
                      >
                        Nombre
                      </label>
                      <Field
                        className={`shadow appearance-none bg-gray-200 border-gray-200 border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                        id="firstName"
                        type="text"
                        name="firstName"
                        disabled={true}
                      />
                      <ErrorMessage
                        name="firstName"
                        className="text-red-600"
                        component="div"
                      />
                    </div>
                    <div className="md:w-1/2 px-3">
                      <label
                        htmlFor="lastName"
                        className="block text-sm font-semibold text-gray-800"
                      >
                        Apellido
                      </label>
                      <Field
                        className={`shadow appearance-none bg-gray-200 border-gray-200 border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                        id="lastName"
                        type="text"
                        name="lastName"
                        disabled={true}
                      />
                      <ErrorMessage
                        name="lastName"
                        className="text-red-600"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="md:flex">
                    <div className="md:w-1/2 px-3">
                      <label
                        htmlFor="email"
                        className="block text-sm font-semibold text-gray-800"
                      >
                        Email
                      </label>
                      <Field
                        className={`shadow appearance-none bg-gray-200 border-gray-200 border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                        id="email"
                        type="email"
                        name="email"
                        disabled={true}
                      />
                      <ErrorMessage
                        name="email"
                        className="text-red-600"
                        component="div"
                      />
                    </div>
                    <div className="md:w-1/2 px-3">
                      <label
                        htmlFor="role"
                        className="block text-sm font-semibold text-gray-800"
                      >
                        Rol
                      </label>
                      <div className="flex">
                        <span className="mt-3">{values.roleId === 1 ? "Administrador" : "Profesor"}</span>
                      </div>
                    </div>
                  </div>
                  <div className="md:flex">
                    <div className="md:w-full px-3">
                      <label
                        htmlFor="referenceEmail"
                        className="block text-sm font-semibold text-gray-800"
                      >
                        Email de referencia
                      </label>
                      <Field
                        className={`shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                        id="referenceEmail"
                        type="email"
                        name="referenceEmail"
                      />
                      <ErrorMessage
                        name="referenceEmail"
                        className="text-red-600"
                        component="div"
                      />
                    </div>
                  </div>
                  <div className="md:flex">
                    <div className={`${editPassword ? "md:w-1/2" : "md:w-full"} px-3`}>
                      <div className="w-full inline-flex justify-between">
                        <label
                          htmlFor="password"
                          className="block text-sm font-semibold text-gray-800"
                        >
                          Contraseña
                        </label>
                        <button
                          type="button"
                          className="text-zinc-500 hover:text-zinc-600"
                          title="Ingresar nueva contraseña"
                          onClick={(ev) => setEditPassword(!editPassword)}
                        >
                          <Pen />
                        </button>
                      </div>
                      <Field
                        className={`shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                        id="password"
                        type="password"
                        name="password"
                        disabled={!editPassword}
                      />
                      <ErrorMessage
                        name="password"
                        className="text-red-600"
                        component="div"
                      />
                    </div>
                    {editPassword && (
                      <div className="md:w-1/2 px-3">
                        <label
                          htmlFor="repeat_password"
                          className="block text-sm font-semibold text-gray-800"
                        >
                          Repetir contraseña
                        </label>
                        <Field
                          className={`shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline`}
                          id="repeat_password"
                          type="password"
                          name="repeat_password"
                          disabled={!editPassword}
                        />
                        <ErrorMessage
                          name="repeat_password"
                          className="text-red-600"
                          component="div"
                        />
                      </div>
                    )}
                  </div>
                  <div className="items-center gap-2 mt-3 sm:flex">
                    <button
                      type="submit"
                      className="w-full mt-2 p-2.5 flex-1 text-white font-bold bg-orange-500/80 hover:bg-orange-500 rounded-md outline-none ring-offset-2 ring-orange-500 focus:ring-2"
                    >
                      Guardar
                    </button>
                    <button
                      className="w-full mt-2 p-2.5 flex-1 text-white font-bold bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none ring-offset-2 ring-zinc-500 focus:ring-2"
                      onClick={() => handleCloseEditProfile()}
                    >
                      Cancelar
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Modal>
        </>
      )}
      {isLoading && (
        <div className="fixed flex h-full w-full">
          <Spinner
            color="#000000"
            size={80}
          />
        </div>
      )}
    </>
  );
}

export default Layout;
