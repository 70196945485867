import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: null,
  states: {},
  currentPage: 1,
  totalPages: 1,
  teachers: {},
  listByCourse : null,
};

const filterList = (list, key, value) => {
  return list.filter((studentClass) => {
    return !studentClass.hasOwnProperty(key) || studentClass[key] !== value;
  });
};

export const studentClassSlice = createSlice({
  name: 'studentClasses',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setStates: (state, action) => {
      state.states = action.payload;
    },
    appendToList: (state, action) => {
      state.list.push(action.payload);
    },
    removeFromListByIndex: (state, action) => {
      state.list.splice(action.payload, 1);
    },
    removeFromListById: (state, action) => {
      state.list = filterList(state.list, "id", action.payload);
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setTeachers: (state, action) => {
      state.teachers[action.payload.courseId] = action.payload.teachers;
    },
    setListByCourse: (state, action) => {
      state.listByCourse = action.payload;
    },
  },
});

export const {
  setList,
  setStates,
  setListByCourse,
  appendToList,
  removeFromListByIndex,
  removeFromListById,
  setCurrentPage,
  setTotalPages,
  setTeachers,
  listByCourse,
} = studentClassSlice.actions;

export default studentClassSlice.reducer;
