import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import config from "../../shared/config";
import logo from "../../shared/assets/b1u-300x146.png";
import { useDispatch } from "react-redux";
import { reset as resetStore, setToken } from "../../shared/components/Layout/layoutReducer";
import { useAxiosWithAuth } from '../../hooks/useAxiosWithAuth';
import useLogin from "../../hooks/useLogin";

function Login() {
  const axios = useAxiosWithAuth();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const {login, error} = useLogin();

  return (
    <div className="relative flex flex-col justify-center min-h-screen overflow-hidden bg-sky-600/70">
      <div className="w-full p-6 m-auto rounded-md lg:max-w-xl">
        <div className="flex justify-center mb-32">
          <img className="drop-shadow-lg" src={logo} alt="beoneup"></img>
        </div>
        {error && (
          <div
            className="bg-red-200 rounded-lg py-5 px-6 text-base text-red-700 mb-3"
            role="alert"
          >
            Usuario o contraseña incorrectos - ¡Intente nuevamente!
          </div>
        )}
        <form className="mt-6" onSubmit={handleSubmit(login)}>
          <div className="mb-6">
            <input
              type="email"
              placeholder="Ingrese su usuario"
              className="block w-full h-12 px-4 py-2 mt-2 bg-white border rounded-full focus:border-bg-orange-500/80 focus:ring-orange-400/80 focus:outline-none focus:ring focus:ring-opacity-40"
              {...register("email", { required: true })}
            />
            {errors.email?.type === "required" && (
              <div className="flex justify-center">
                <p className="mt-2 text-red-700">El campo Usuario es requerido</p>
              </div>
            )}
          </div>
          <div className="mb-14">
            <input
              type="password"
              placeholder="Ingrese su contraseña"
              className="block w-full h-12 px-4 py-2 mt-2 bg-white border rounded-full focus:border-bg-orange-500/80 focus:ring-orange-400/80 focus:outline-none focus:ring focus:ring-opacity-40"
              {...register("password", { required: true })}
            />
            {errors.password?.type === "required" && (
              <div className="flex justify-center">
                <p className="mt-2 text-red-700">El campo Contraseña es requerido</p>
              </div>
            )}
          </div>
          <div className="flex justify-center mt-6">
            <input
              type="submit"
              value="Iniciar sesión"
              className="w-64 h-12 px-4 py-2 tracking-wide text-white text-lg transition-colors duration-200 transform bg-orange-500/80 rounded-full hover:bg-orange-500 focus:outline-none focus:bg-orange-500"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default Login;
