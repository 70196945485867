import React, { useEffect, useState } from "react";
import "./Pagination.css";

const defaultPageCount = 3;

const Pagination = ({currentPage, totalPages, onChangePage}) => {

  const [pageCount, setPageCount] = useState(1);
  const [hasPrev, setHasPrev] = useState(false);
  const [hasNext, setHasNext] = useState(false);
  const [leftPage, setLeftPage] = useState(1);

  useEffect(() => {
    // If we should show the prev/next buttons
    setHasPrev(currentPage > 1);
    setHasNext(currentPage < totalPages);

    // Calculate number of pages that the user can click on
    const pageCount = (totalPages >= defaultPageCount) ? defaultPageCount : totalPages;
    // Determine if the page count is even
    const evenPageCount = (pageCount % 2 === 0);
    setPageCount(pageCount);

    // Calculate number of pages that are on each side of the current selected
    // page. Notice that if pageCount is even we don't have a "center" page, so
    // we must use the next left page instead.
    const sidePages = Math.floor(pageCount/2);

    if (currentPage < Math.ceil(pageCount/2)) {
      // If the user is in the first portion of the buttons
      setLeftPage(1);
    } else if (currentPage > totalPages - Math.floor(pageCount/2)) {
      // If the user is in the last portion of the buttons
      setLeftPage(totalPages - pageCount + 1);
    } else {
      // The user must be somewhere in the middle
      setLeftPage(currentPage - sidePages + (evenPageCount ? 1 : 0));
    }
  }, [currentPage, totalPages]);

  return (
    <>
      <div className="flex py-4">
        <div className="inline-flex flex-col mx-auto">
          <div
            className="flex flex-row gap-2 justify-items-center"
          >
            {hasPrev && (
              <button
                className="opacity-80 hover:opacity-100 mr-2"
                type="button"
                onClick={() => onChangePage(currentPage-1)}
                title="Página anterior"
              >
                <div className="pagination-arrow-prev">
                  <div className="pagination-arrow bg-orange-500"></div>
                </div>
              </button>
            )}
            {[...Array(pageCount)].map((item, index) => {
              const page = leftPage + index;
              return (
                <button
                  type="button"
                  onClick={() => {
                    if (currentPage !== page) {
                      onChangePage(page);
                    }
                  }}
                  title={`Ir a la página ${page}`}
                  key={index}
                >
                  <div
                    className={`w-full py-2.5 px-4 flex-1 text-white font-bold rounded-md outline-none ring-offset-2 ring-orange-500 focus:ring-2" ${(currentPage === page) ? "bg-orange-500" : "bg-orange-500/80 hover:bg-orange-500"}`}
                  >
                    {page}
                  </div>
                </button>
              );
            })}
            {hasNext && (
              <button
                className="opacity-80 hover:opacity-100 ml-2"
                type="button"
                onClick={() => onChangePage(currentPage+1)}
                title="Página siguiente"
              >
                <div className="pagination-arrow-next">
                  <div className="pagination-arrow bg-orange-500"></div>
                </div>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );

};

export default Pagination;
