import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import Modal from "../../shared/components/Modal/Modal";
import Pen from "../../shared/assets/icons/Pen";
import CheckRegister from "../../shared/assets/icons/CheckRegister";
import TrashCan from "../../shared/assets/icons/TrashCan";
import InformationCircle from "../../shared/assets/icons/InformationCircle";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../shared/components/CRUD/Pagination";
import { useAxiosWithAuth } from '../../hooks/useAxiosWithAuth';
import SortableTableHeaders from "../../shared/components/CRUD/SortableTableHeader";
import TableFilter from "../../shared/components/CRUD/TableFilter";
import {
  setCurrentPage,
  setTotalPages,
  setList as setListStudentClasses,
  setTeachers,
  setListByCourse,
} from "./studentClassesReducer";
import Spinner from "../../shared/components/Spinner/Spinner";
import ResponseMessage from "../../shared/components/ResponseMessage/ResponseMessage";
import useIsLoadingSection from "../../hooks/useIsLoadingSection";
import { setAll as setAllCourses } from "../Courses/coursesReducer";

// TODO: this should not be hardcoded
const weekDays = [
  "Domingo",
  "Lunes",
  "Martes",
  "Miércoles",
  "Jueves",
  "Viernes",
  "Sábado",
];

const optionsStatesClass = [
  {id: "D", value: "Dictada"},
  {id: "F", value: "Feriado"},
  {id: "CP", value: "Cancelada por el profesor"},
  {id: "CA", value: "Cancelada con anticipación"},
  {id: "CS", value: "Cancelada sin anticipación"},
  {id: "CR", value: "Clase recuperación"},
  {id: "CRF", value: "Clase recuperación facturable"},
];
const registerOptions = [
  { id: 'P', value: 'Presente' },
  { id: 'APL', value: 'Ausente por razones laborales' },
  { id: 'AEN', value: 'Ausente por enfermedad' },
  { id: 'APV', value: 'Ausente por vacaciones' },
  { id: 'ARP', value: 'Ausente por razones personales' },
  { id: 'ADE', value: 'Ausente por día de estudio' }
];

const StudentClasses = () => {
  const token = useSelector((state) => state.layout.token);
  const axios = useAxiosWithAuth();
  const role = useSelector((state) => state.layout.role);
  const userLogged = useSelector((state) => state.layout.userLogged);
  const sectionActions = useSelector((state) => state.layout.sectionActions);
  const isLoadingSection = useIsLoadingSection("studentClasses");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listClasses = useSelector((state) => state.studentClasses.list);
  const currentPage = useSelector((state) => state.studentClasses.currentPage);
  const totalPages = useSelector((state) => state.studentClasses.totalPages);
  const teachersByCourse = useSelector((state) => state.studentClasses.teachers);
  const listStudentRegisters = useSelector((state) => state.studentClasses.listByCourse);
  const allCourses = useSelector((state) => state.courses.all);
  const [updateTable, setUpdateTable] = useState(false);
  const [allClients, setAllClients] = useState(null);
  const [allTeachers, setAllTeachers] = useState(null);
  const [allCoursesFilter, setAllCoursesFilter] = useState(null);
  const [allStates, setAllStates] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [model, setModel] = useState({
    id: null,
    date: "",
    clientId: "",
    courseId: "",
    userId: "",
    state: "",
    material: "",
    content: "",
    observation: "",
    recoveryDate: "",
  });
  const [isCreate, setIsCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermFilter, setSearchTermFilter] = useState("");
  const [selectedFilterDropdownOptions, setSelectedFilterDropdownOptions] = useState("");
  const [searchColumnFilter, setSearchColumnFilter] = useState("");
  const [clientFilter, setClientFilter] = useState(null);
  const [teacherFilter, setTeacherFilter] = useState(null);
  const [courseFilter, setCourseFilter] = useState(null);
  const [stateFilter, setStateFilter] = useState(null);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [message, setMessage] = useState("");
  const [lastAction, setLastAction] = useState("");
  const [isAssistance , setIsAssistance] = useState(false);
  const [idClassForAssistance, setIdClassForAssistance] = useState("");
  const [isCreateRegister, setIsCreateRegister ] = useState(false);
  const [isUpdateRegister, setIsUpdateRegister ] = useState(false);
  const [statesClass, setStatesClass] = useState({});
  const [dateAlertModal, setDateAlertModal] = useState(false);



  const teacherRoles = [
    2, // Professor
  ];
  const boxInput = "shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
  const select =  "block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 my-2 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600"

  const handleChangeClients = (clientValues) => {
    let clientId = null;
    if (clientValues !== null) {
      clientId = clientValues.map((clientValue) => {
        return clientValue.value;
      });
    }
    setClientFilter(clientId);
  };

  const handleChangeTeachers = (teacherValues) => {
    let teacherId = null;
    if (teacherValues !== null) {
      teacherId = teacherValues.map((teacherValue) => {
        return teacherValue.value;
      });
    }
    setTeacherFilter(teacherId);
  };

  const handleChangeCourses = (courseValues) => {
    let courseId = null;
    if (courseValues !== null) {
      courseId = courseValues.map((courseValue) => {
        return courseValue.value;
      });
    }
    setCourseFilter(courseId);
  };

  const handleChangeStates = (stateValues) => {
    let stateId = null;
    if (stateValues !== null) {
      stateId = stateValues.map((stateValue) => {
        return stateValue.value;
      });
    }
    setStateFilter(stateId);
  };

  const classNameTypeAssistance = [{
    register : select
  }];


  const columns = [
    {columnId: "clientId",      columnName: "Cliente",              showInTable: true, filterable: true,  dataType: "dropdown", isMulti: true, onChange: handleChangeClients,  options: allClients,       selectedOptions: clientFilter,  placeholder: "Seleccionar Cliente"},
    {columnId: "userId",        columnName: "Profesor",             showInTable: true, filterable: true,  dataType: "dropdown", isMulti: true, onChange: handleChangeTeachers, options: allTeachers,      selectedOptions: teacherFilter, placeholder: "Seleccionar Profesor"},
    {columnId: "courseId",      columnName: "Curso",                showInTable: true, filterable: true,  dataType: "dropdown", isMulti: true, onChange: handleChangeCourses,  options: allCoursesFilter, selectedOptions: courseFilter,  placeholder: "Seleccionar Curso"},
    {columnId: "date",          columnName: "Fecha de la clase",    showInTable: true, filterable: false, dataType: "date"},
    {columnId: "state",         columnName: "Estado de asistencia", showInTable: true, filterable: true,  dataType: "dropdown", isMulti: true, onChange: handleChangeStates,   options: allStates,        selectedOptions: stateFilter,   placeholder: "Seleccionar Estado de Asistencia"},
  ];

  useEffect(() => {
    if (token) {
      getStudentClassesList(
        currentPage,
        searchTermFilter,
        searchColumnFilter,
        clientFilter,
        teacherFilter,
        courseFilter,
        stateFilter,
        orderBy,
        orderDirection
      );
      getAllClients();
      getAllTeachers();
      getAllCoursesFilter();
      getAllCourses();
      getAllStates();
    }
  }, [token]);

  useEffect(() => {
    if (responseMessage !== "")
    {
        setTimeout(() => {
          setResponseMessage("");
        }, 5000);
    }
  }, [responseMessage]);

  useEffect(() => {
    if (updateTable && token) {
      getStudentClassesList(
        currentPage,
        searchTermFilter,
        searchColumnFilter,
        clientFilter,
        teacherFilter,
        courseFilter,
        stateFilter,
        orderBy,
        orderDirection
      );
      setUpdateTable(false);
      getAllCourses();
    }
  }, [updateTable]);

  const getStudentClassesList = async (
    currentPage,
    searchTermFilter = '',
    searchColumnFilter = null,
    clientFilter = null,
    teacherFilter = null,
    courseFilter = null,
    stateFilter = null,
    orderBy = 'id',
    orderDirection = 'DESC'
  ) => {
    dispatch(setListStudentClasses(null));


    let clientFilterURLParam = [];
    if (clientFilter) {
      clientFilterURLParam = clientFilter.map((clientId, index) => {
        return "&clientFilter[" + index + "]=" + clientId;
      });
    }

    let teacherFilterURLParam = [];
    // TODO: don't hardcode this
    if (role === 2) {
      teacherFilterURLParam = [
        `&teacherFilter[0]=${userLogged.id}`,
      ];
    } else {
      if (teacherFilter) {
        teacherFilterURLParam = teacherFilter.map((teacherId, index) => {
          return "&teacherFilter[" + index + "]=" + teacherId;
        });
      }
    }

    let courseFilterURLParam = [];
    if (courseFilter) {
      courseFilterURLParam = courseFilter.map((courseId, index) => {
        return "&courseFilter[" + index + "]=" + courseId;
      });
    }

    let stateFilterURLParam = [];
    if (stateFilter) {
      stateFilterURLParam = stateFilter.map((stateId, index) => {
        return "&stateFilter[" + index + "]=" + stateId;
      });
    }

    let searchColumnFilterURLParam = [];
    if(searchColumnFilter) {
      searchColumnFilterURLParam = searchColumnFilter.map((columnId, index) => {
        return "&searchColumns[" + index + "]=" + encodeURIComponent(columnId);
      });
    }
    const requestParams = new URLSearchParams({
      page: currentPage,
      orderBy: orderBy,
      orderDirection: orderDirection,
      searchTerm: searchTermFilter,
    }).toString() +
        clientFilterURLParam.join("") +
        teacherFilterURLParam.join("") +
        courseFilterURLParam.join("") +
        stateFilterURLParam.join("") +
        searchColumnFilterURLParam.join("");

    axios
      .get("/class/list?"+requestParams)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentPage(res.data.currentPage));
          dispatch(setTotalPages(res.data.totalPages));
          dispatch(setListStudentClasses(res.data.studentClasses));
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error(error.message)
      });
  };

  const getAllClients = async () => {
    return await axios
      .get("/client/getAll")
      .then((res) => {
        if (res.status === 200) {
          const clients = res.data.clients.map((client) => {
            return { value: client.id, label: client.businessName };
          });
          setAllClients(clients);
          return clients;
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error(error.message)
      });
  };

  const getAllTeachers = async () => {
    return await axios
      .get("/user/getAllTeachers")
      .then((res) => {
        if (res.status === 200) {
          const teachers = res.data.teachers.map((teacher) => {
            return {
              value: teacher.id,
              label: teacher.firstName + " " + teacher.lastName,
            };
          });
          setAllTeachers(teachers);
          return teachers;
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error(error.message)
      });
  };

  const getAllCoursesFilter = async () => {
    return await axios
      .get("/course/getAll")
      .then((res) => {
        if (res.status === 200) {
          const courses = res.data.courses.map((course) => {
            return { value: course.id, label: course.name };
          });
          setAllCoursesFilter(courses);
          return courses;
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error(error.message)
      });
  };

  const getAllStates = async () => {
    let statesClass = [
      {value: "D", label: "Dictada"},
      {value: "F", label: "Feriado"},
      {value: "CP", label: "Cancelada por el profesor"},
      {value: "CA", label: "Cancelada con anticipación"},
      {value: "CS", label: "Cancelada sin anticipación"},
      {value: "CR", label: "Clase recuperación"},
      {value: "CRF", label: "Clase recuperación facturable"},
    ];
    setAllStates(statesClass);
    return statesClass;
  };

  const getAllTeachersByCourse = async (courseId) => {
    if (!courseId || teachersByCourse[courseId] !== undefined) {
      return;
    }

    await axios
      .get("/user/getAllTeachersByCourse/"+courseId)
      .then((res) => {
        if (res.status === 200) {
          const teachers = res.data.teachers;
          dispatch(setTeachers({
            courseId: courseId,
            teachers: teachers,
          }));
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error(error.message)
      });
  }

  const handleFilterDropdownValues = (onChangeFilterDropdownValues) => {
    let selectedFilterDropdownOptions = [];
    let filteredColumnIds = onChangeFilterDropdownValues.map((columnFiltered) => {
      selectedFilterDropdownOptions.push(columnFiltered);
      return columnFiltered.value;
    });

    setSelectedFilterDropdownOptions(selectedFilterDropdownOptions);
    setSearchColumnFilter(filteredColumnIds);
  };

  const handleChangePage = (page) => {
    dispatch(setCurrentPage(page));
    if (token) {
      getStudentClassesList(
        page,
        searchTermFilter,
        searchColumnFilter,
        clientFilter,
        teacherFilter,
        courseFilter,
        stateFilter,
        orderBy,
        orderDirection
      );
    }
  };

  const getAllCourses = async () =>
  {
    if ( role === 2 )
    {
      axios
        .get("course/getAllCoursesByTeacher/"+userLogged.id)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setAllCourses(res.data.courses));
          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            const cookies = new Cookies();
            navigate("/login");
          }
          console.error(error.message)
        });
    }
    else if (role === 1 ) {
      axios
        .get("/course/getAll")
        .then((res) => {
          if (res.status === 200) {
            dispatch(setAllCourses(res.data.courses));
          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            const cookies = new Cookies();
            navigate("/login");
          }
          console.error(error.message)
        });
    }
  }

  const handleEdit = (event, studentClass) => {
    event.preventDefault();
    studentClass = {...studentClass, clientId : studentClass.course.clientId}
    setLastAction("edit");
    setModel(studentClass);
    setIdClassForAssistance(studentClass.id);
    setIsEdit(true);
    setShowModal(true);
  };

  const handleDelete = (event, studentClass) => {
    event.preventDefault();
    studentClass = {...studentClass, clientId : studentClass.course.clientId}
    setLastAction("delete");
    setModel(studentClass);
    setIsDelete(true);
    setShowModal(true);
  };

  const handleCreate = (event) => {
    event.preventDefault();
    setLastAction("create");
    setIsCreate(true);
    setShowModal(true);
  };

  const handleView = (event, studentClass) => {
    event.preventDefault();
    studentClass = {...studentClass, clientId : studentClass.course.clientId}
    setLastAction("view");
    setModel(studentClass);
    setIdClassForAssistance(studentClass.id);
    setIsView(true);
    setShowModal(true);
  };

  const getAction = async (  classId ) =>
  {
    await axios
        .get("/classRegister/checkClass?id="+classId)
        .then( async (res) => {
          if (res.status === 200)
          {
            let isCreated = res.data.student.isCreated

            if (isCreated)
            {
              setIsUpdateRegister (true)
              await getRegistersByClass ( classId )
            }
            else
            {
              setIsCreateRegister (true)
              await getStudentsByClass ( classId )
            }
            return isCreated

          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            const cookies = new Cookies();
            cookies.remove("jwt_token");
            navigate("/login");
          }
          console.error(error.message)
        });
  }


  const getStateRegister = async (classId) =>
  {
    try {
      const res = await axios.get("/classRegister/checkClass?id=" + classId);
      if (res.status === 200)
      {
        return res.data.student.isCreated;
      }
    }
    catch (error)
    {
      if (error.response && error.response.status === 401)
      {
        const cookies = new Cookies();
        cookies.remove("jwt_token");
        navigate("/login");
      }
      console.error(error.message)
      return false;
    }
  }

  const getStatesForListClasses = async (listClasses) =>
  {
    const statesF = {};
    for (const studentClass of listClasses)
    {
      if ( ["D","CR", "CRF"].includes(studentClass.state) )
      {
        const state = await getStateRegister(studentClass.id);
        const matchS = state ? matchState(studentClass.state) : "Pendiente de Registro";
        statesF[studentClass.id] = matchS;
      }
      else
      {
        const matchS = matchState(studentClass.state)
        statesF[studentClass.id] = matchS;
      }
    }
    return statesF;
  }

  useEffect(() =>
  {
    const fetchData = async () =>
    {
      try {
        if (listClasses && listClasses.length > 0)
        {
          const statesData = await getStatesForListClasses(listClasses);
          setStatesClass(statesData);
        }
      } catch ( error ){
        console.error( message.error )
      }
    };
    fetchData();
  }, [listClasses]);


const getStudentsByClass = async (courseId ) =>
{
  await axios
    .get("/classRegister/getStudents?id="+courseId)
    .then((res) => {
      if (res.status === 200)
      {
        return res.data.listStudentAssistance
      }
    })
    .then((students)=>
    {
      dispatch(setListByCourse(students))
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        const cookies = new Cookies();
        cookies.remove("jwt_token");
        navigate("/login");
      }
      console.error(error.message)
    });
}

const getRegistersByClass = async (classId) =>
{
  await axios
    .get("/classRegister/getRegisters?id="+classId)
    .then((res) => {
      if (res.status === 200)
      {
        return res.data.listStudentAssistance
      }
    })
    .then((students)=>
    {
      dispatch(setListByCourse(students))
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        const cookies = new Cookies();
        cookies.remove("jwt_token");
        navigate("/login");
      }
      console.error(error.message)
    });
}

  const handleAssistance =  (event, classId) =>
  {
    event.preventDefault();
    setIdClassForAssistance (classId )
    getAction ( classId )
    .then( ()=>
    {
      setLastAction("assitence");
      setIsAssistance(true);
    } )

  };

  const handleSortColumn = (columnId) => {
    return ((event) => {
      event.preventDefault();
      setOrderBy(columnId);
      if(orderDirection === "ASC") {
        setOrderDirection("DESC");
      } else {
        setOrderDirection("ASC");
      }
      setUpdateTable(true);
    });
  };

  const matchState = (typeState) =>
  {
    return optionsStatesClass.find(state => state.id === typeState).value
  }

  const handleFilter = () => {
    setSearchTermFilter(searchTerm);
    setOrderBy("id");
    setOrderDirection("DESC");
    dispatch(setCurrentPage(1));
    setUpdateTable(true);
  };

  const resetFilter = () => {
    setSearchTermFilter('')
    setOrderBy("id");
    setOrderDirection("DESC");
    dispatch(setCurrentPage(1));
    setClientFilter("");
    setTeacherFilter("");
    setCourseFilter("");
    setStateFilter("");
    setSelectedFilterDropdownOptions([]);
    setUpdateTable(true);
  };

  const handleSearchTextChange = (event) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  const hideModal = () => {
    resetModel();
    setShowModal(false);
    if (isDelete) {
      setIsDelete(false);
    } else if (isCreate) {
      setIsCreate(false);
    } else if (isEdit) {
      setIsEdit(false);
      setIdClassForAssistance("")
    } else if (isView) {
      setIsView(false);
      setIdClassForAssistance("")
    }
    setResponseMessage("");
  };

  const hideModalOfAssistance = () =>
  {
    resetModelAsistance();
    setResponseMessage("");
  };

  const resetModelAsistance = () => {
    dispatch(setListByCourse([]))
    if(isEdit)
    {
      setLastAction("edit")
    }
    else if(isView)
    {
      setLastAction("view")
    }
    else
    {
      setIdClassForAssistance("")
    }
    setIsAssistance(false);
    setIsCreateRegister (false)
    setIsUpdateRegister (false)
  };

  const resetModel = () => {
    setModel({
      id: null,
      date: "",
      clientId:"",
      courseId: "",
      userId: "",
      state: "",
      material: "",
      content: "",
      observation: "",
      recoveryDate: "",
    });
  };

  const hasPermission = (action) => {
    return sectionActions && sectionActions.includes(action);
  };

  const actionStudentClass = async (action, data = null) => {
    if (!data) {
      data = model;
    }
    axios
      .post("/class/" + action, data)
      .then((res) => {
        if (res.status === 200) {
          setResponseMessage(res.data.status);
          setUpdateTable(true);
        } else {
          setResponseMessage(res.data.message);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error(error.message)
        setResponseMessage("error");
      });

    hideModal();
  };

  const actionRegister = async ( data = null) => {
    if (!data) {
      data = listStudentRegisters;
    }

    var action = {  method : 'post', type : 'add' };
    if ( isUpdateRegister )
    {
      action.method = 'put'
      action.type = 'modify'
    }
    await axios[`${action.method}`]("/classRegister/" + action.type, data)
      .then((res) =>
      {
        if (res.status === 200)
        {
          setResponseMessage(res.data.status);
        }
        else
        {
          setResponseMessage(res.data.message);
        }
      })
      .catch(function (error)
      {
        if (error.response.status === 401)
        {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }

        setResponseMessage("error");
        console.error( message.error )
      });
      setUpdateTable(true);
  };

  const registerOptionsMatch = ( idRegister ) =>
  {
    if(idRegister)
    {
      return registerOptions.find( register => register.id === idRegister).value
    }
    return "";
  }

  useEffect(() => {
    if (responseMessage === "success") {
      setMessage(lastAction === "create"
        ? "¡Clase creado exitosamente!"
        : lastAction === "edit"
        ? "¡Clase modificado exitosamente!"
        : lastAction === "delete"
        ? "¡Clase borrado existosamente!"
        : lastAction === "assitence"
        ? "¡Asistencia registrada existosamente!"
        : "");
    } else if (responseMessage === "invalid_data") {
      setMessage("Datos inválidos.");
    } else if (responseMessage === "error") {
      setMessage("Ocurrió un error. ¡Vuelva a intentar mas tarde!");
    }
    if (responseMessage) {
      setShowResponseMessage(true);
    }
  }, [responseMessage]);

  const hideResponseMessage = () => {
    setShowResponseMessage(false);
    setResponseMessage("");
    setMessage("");
  };

  const renderTeacherOptions = (courseId) =>
  {
    getAllTeachersByCourse(courseId);

    if (teacherRoles.includes(role)) {
      return (
        <option value={userLogged.id}>Asignarme la clase</option>
      );
    }

    let teachers = (courseId && teachersByCourse[courseId] !== undefined) ?
      teachersByCourse[courseId] :
      [];
    return teachers.map((teacher, idx) => (
      <option key={idx} value={teacher.id}>{`${teacher.firstName} ${teacher.lastName}`}</option>
    ));
  };

  const renderCoursesOptions = (clientId) =>
  {
    return allCourses.filter(course => {
      return course.clientId == clientId
    }).map((course, index) => {
      return (
        <option key={"course_" + index} value={course.id}>
          {course.name}
        </option>
      );
    })
  };

  const ErrorArrayMessage = ({ name , error }) =>
  {
      return (
        <div className="text-red-600">
        <Field
          name={name}
          render = {  () =>
          {
            const errors = error[name]
            return errors ? errors : null;
          }}
        />
        </div>
      )
  }

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString([], {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'UTC',
    });
  };

  const handleCourseDateChange = (date, courseId) => {
    if (!date || !courseId) {
      return;
    }

    const course = allCourses.find((course) => course.id == courseId);
    if (!course) {
      return;
    }

    const selectedDay = new Date(date).getUTCDay();
    const courseDays = course.days.map((day) => weekDays.indexOf(day.weekDay));

    if (!courseDays.includes(selectedDay)) {
      setDateAlertModal(true);
    }
  };

  return (
    <>
    {isLoadingSection && (
      <Spinner
      color="#000000"
      size={80}
      />
      )}
    { !isLoadingSection  && (
      <>
    <div className="mx-4 font-bold text-left text-sky-600/70 text-3xl mb-8">Clases</div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="flex items-center gap-4 py-3 px-3 md:px-10 mb-4">
            <TableFilter
              placeholderText={"Buscar clase..."}
              searchTermFilter={searchTermFilter}
              onChangeSearchText={handleSearchTextChange}
              onFilter={handleFilter}
              onChangeFilterDropdownValues={handleFilterDropdownValues}
              selectedFilterDropdownOptions={selectedFilterDropdownOptions}
              tableColumns={columns}
              onResetFiltersAndOrder={resetFilter}
              hideSearchText={true}
            />
            {hasPermission("create") && (
              <div style={{marginLeft: "auto"}}>
                <div className="relative">
                  <button
                    className="bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={(ev) => handleCreate(ev)}
                  >
                    Nueva clase
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="p-3 w-full inline-block align-middle">
            <div className="overflow-auto border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map((column, index) => {
                      if(column.showInTable) {
                          if(column.columnId !== 'clientId' && column.columnId !== 'state') {
                          return (
                            <SortableTableHeaders
                              id={column.columnId}
                              key={"header_" + index}
                              headerText={column.columnName}
                              onSortColumn={handleSortColumn(column.columnId)}
                              sortBy={orderBy}
                              sortOrder={orderDirection}
                            />
                          );
                        } else {
                          return (
                            <th scope="col" key={"header_" + column.columnId} className={"px-6 py-3 text-sm font-bold text-center text-sky-500/80"}>
                              {column.columnName}
                            </th>
                          )
                        }
                      } else {
                        return false;
                      }
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
      {listClasses && listClasses.length > 0 && statesClass ? (
        listClasses.map((studentClass, idx) => (
          <tr key={"row_" + idx}>
            <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
              {studentClass.course.client.businessName}
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
              {studentClass.teacher.firstName + " " + studentClass.teacher.lastName}
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
              {studentClass.course.name}
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
              {formatDate(studentClass.date)}
            </td>
            <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
              {statesClass[studentClass.id] || "Cargando ..."}
            </td>
            <td className="px-6 py-4 flex justify-center gap-x-4 text-sm whitespace-nowrap">
            {/* Hardcoding stuff is like an addiction, it is useful the first few
                times, but it becomes a bad habit soon enough. Please don't
                hardcode this below. */}
            {["D","CR","CRF"].includes(studentClass.state) && (
                          <button
                              className="text-zinc-500 hover:text-zinc-600"
                              href=""
                              title="Asistencia"
                              onClick={(ev) => handleAssistance( ev , studentClass.id)}
                            >
                              <CheckRegister />
                            </button>
            )}
                            {hasPermission("update") && (
                              <button
                                className="text-zinc-500 hover:text-zinc-600"
                                title="Editar"
                                onClick={(ev) => handleEdit(ev, studentClass)}
                              >
                                <Pen />
                              </button>
                            )}
                            {hasPermission("delete") && (
                              <button
                                className="text-zinc-500 hover:text-zinc-600"
                                title="Borrar"
                                onClick={(ev) => handleDelete(ev, studentClass)}
                              >
                                <TrashCan />
                              </button>
                            )}
                            <button
                              className="text-zinc-500 hover:text-zinc-600"
                              href=""
                              title="Ver"
                              onClick={(ev) => handleView(ev, studentClass)}
                            >
                              <InformationCircle />
                            </button>
            </td>
          </tr>
        ))
      ) : (
        <>
          <tr className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
            <td></td>
            <td></td>
            <td className="px-6 py-4 text-sm text-right text-gray-800 whitespace-nowrap">
              "No se encontraron resultados"
            </td>
          </tr>
        </>
      )}
    </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Pagination currentPage={currentPage} totalPages={totalPages} onChangePage={handleChangePage} />

      <ResponseMessage
        open={showResponseMessage}
        onClose={hideResponseMessage}
        type={responseMessage === 'success' ? 'success' : 'error'}
        message={message}
      />

      <Modal
        open={showModal}
        onClose={hideModal}
        title={
          isCreate
            ? "Nueva clase"
            : isEdit
            ? "Editar clase"
            : isDelete
            ? "Borrar clase"
            : "Ver clase"
        }
        backdrop={true}
        cross={true}
      >
        {isCreate || isEdit || isView ? (
          <Formik
            initialValues={model}
            validate={(values) => {
              const errors = {};
              if (!values.date) {
                errors.date = "Campo requerido";
              }
              if (!values.clientId) {
                errors.clientId = "Campo requerido";
              }
              if (!values.courseId) {
                errors.courseId = "Campo requerido";
              }
              if (!values.userId) {
                errors.userId = "Campo requerido";
              }
              if (!values.state) {
                errors.state = "Campo requerido";
              }
              if (["CR","CRF"].includes(values.state) && !values.recoveryDate) {
                errors.recoveryDate = "Campo requerido";
              }
              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {
              if (isCreate) {
                actionStudentClass("create", values);
              } else if (isEdit) {
                actionStudentClass("edit", values);
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting, isValid }) => {
              if (isSubmitting && !isValid) {
                setResponseMessage("invalid_data");
              }
              return (
              <Form>
                <div className="md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="date"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Fecha de la clase*
                    </label>
                    <Field
                      className={boxInput}
                      id="date"
                      type="date"
                      name="date"
                      disabled={isView}
                      onChange={(event) => {
                        const date = event.target.value;
                        setFieldValue("date", date);
                        handleCourseDateChange(date, values.courseId);
                      }}
                    />
                    <ErrorMessage
                      name="date"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="clientId"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Cliente*
                    </label>
                    <Field
                      as="select"
                      className={select}
                      id="clientId"
                      name="clientId"
                      disabled={isView}
                      onChange={(event) => {
                        const clientId = event.target.value;
                        setFieldValue("clientId", clientId);
                      }}
                    >
                      <option value="">Seleccionar</option>
                      {allClients && allClients.map((client, index) => {
                        if (role == 1) {
                          return (
                            <option key={"client_" + index} value={client.value}>
                              {client.label}
                            </option>
                          );
                        } else if (role == 2) {
                          let clientIds = allCourses.map(course => course.clientId);
                          if (clientIds.includes(client.value)) {
                            return (
                              <option key={"client_" + index} value={client.value}>
                                {client.label}
                              </option>
                            );
                          }
                        }
                      })}
                    </Field>
                    <ErrorMessage
                      name="clientId"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="courseId"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Curso*
                    </label>
                    <Field
                      as="select"
                      className={select}
                      id="courseId"
                      name="courseId"
                      disabled={isView}
                      onChange={(event) => {
                        const courseId = event.target.value;
                        setFieldValue("courseId", courseId);
                        handleCourseDateChange(values.date, courseId);
                      }}
                    >
                      <option value="">Seleccionar</option>
                      {renderCoursesOptions(values.clientId)}
                    </Field>
                    <ErrorMessage
                      name="courseId"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="userId"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Profesor*
                    </label>
                    <Field
                      as="select"
                      className={select}
                      id="userId"
                      name="userId"
                      disabled={isView}
                    >
                      <option value="">Seleccionar</option>
                      {renderTeacherOptions(values.courseId)}
                    </Field>
                    <ErrorMessage
                      name="userId"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="state"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Estado de la clase*
                    </label>
                    <Field
                      as="select"
                      className={select}
                      id="state"
                      name="state"
                      disabled={isView}
                    >
                      <option value="">Seleccionar</option>
                      {optionsStatesClass && optionsStatesClass.map((state, index) => {
                        return (
                          <option key={"state_" + index} value={state.id}>
                            {state.value}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="state"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                </div>
                <div className="md:flex">
                  <div className={`${["CR","CRF"].includes(values.state) ? "md:w-1/2" : "md:w-full"} px-3`}>
                    <label
                      htmlFor="material"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Material
                    </label>
                    <Field
                      as="textarea"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 my-2 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600"
                      id="material"
                      name="material"
                      disabled={isView}
                    >
                    </Field>
                  </div>
                  {["CR","CRF"].includes(values.state) && (
                    <div className="md:w-1/2 px-3">
                      <label
                        htmlFor="date"
                        className="block text-sm font-semibold text-gray-800"
                      >
                        Fecha de la clase que se recuperó*
                      </label>
                      <Field
                        className={boxInput}
                        id="recoveryDate"
                        type="date"
                        name="recoveryDate"
                        disabled={isView}
                      />
                      <ErrorMessage
                        name="date"
                        className="text-red-600"
                        component="div"
                      />
                    </div>
                  )}
                </div>
                <div className="md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="content"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Contenido
                    </label>
                    <Field
                      as="textarea"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 my-2 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600"
                      id="content"
                      name="content"
                      disabled={isView}
                    >
                    </Field>
                  </div>
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="observation"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Observaciones
                    </label>
                    <Field
                      as="textarea"
                      className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 my-2 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600"
                      id="observation"
                      name="observation"
                      disabled={isView}
                    >
                    </Field>
                  </div>
                </div>
                <div className="items-center gap-2 mt-3 sm:flex">
                  {!isView && (
                    <button
                      type="submit"
                      className="w-full mt-2 p-2.5 flex-1 text-white font-bold bg-orange-500/80 hover:bg-orange-500 rounded-md outline-none ring-offset-2 ring-orange-500 focus:ring-2"
                    >
                      {isCreate ? "Crear" : "Editar"}
                    </button>
                  )}
                  <button
                    className="w-full mt-2 p-2.5 flex-1 text-white bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none border ring-offset-2 ring-zinc-500 focus:ring-2"
                    onClick={() => hideModal()}
                  >
                    Cerrar
                  </button>
                </div>
              </Form>
              );
            }}
          </Formik>
        )
        :(
          <>
            <p className="mt-2 text-[18px] leading-relaxed text-gray-500">
              ¿Está seguro de borrar la clase?
            </p>
            <div className="items-center gap-2 mt-3 sm:flex">
              <button
                type="submit"
                className="w-full mt-2 p-2.5 flex-1 text-white bg-red-500/80 hover:bg-red-500 rounded-md outline-none ring-offset-2 ring-red-500 focus:ring-2"
                onClick={() => actionStudentClass("delete")}
              >
                Borrar
              </button>
              <button
                className="w-full mt-2 p-2.5 flex-1 text-white bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none border ring-offset-2 ring-zinc-500 focus:ring-2"
                onClick={() => hideModal()}
              >
                Cancelar
              </button>
            </div>
          </>
        )}
      </Modal>

      <Modal
        open={dateAlertModal}
        onClose={() => setDateAlertModal(false)}
        title="Alerta"
        backdrop={true}
        cross={true}
      >
        <div className="max-w-xl">
          <p
            className="my-4"
          >
            Está creando una clase en un día distinto a los días que se realiza el curso, modifíquelo en caso de ser un error o si es para cargar una clase de recuperación, prosiga con la carga.
          </p>
          <div className="flex w-full justify-end">
            <button
              className="bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded"
              onClick={() => setDateAlertModal(false)}
            >
              Entendido
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={isAssistance}
        onClose={hideModalOfAssistance}
        title={isCreateRegister? "Registrar Asistencias" : "Actualizar Asistencias"}
        backdrop={true}
        cross={true}
      >
        { isAssistance && (
        <Formik
            initialValues={{ students: listStudentRegisters }}
            validate={(values) =>
                {
                  const errors = {};

                  values.students.forEach((student, index) =>
                  {
                        if (!student.register || student.register.length === 0)
                        {
                            errors[`student.${index}.register`] = 'Campo requerido';
                        }
                  });
                return errors;
                }
            }
            onSubmit={  ( values ) =>
            {
              var studentclassId = idClassForAssistance
              var arrayStudent  = values.students.map(student =>
              ({
                studentId : student.studentId,
                register : student.register
              }))
              var data = { studentclassId ,arrayStudent}
              actionRegister(data)
              hideModalOfAssistance();
            }}
        >

        { ( {values, isSubmitting, isValid, errors} ) =>
        {
            if ( isSubmitting && !isValid )
            {
                setResponseMessage("invalid_data");
            }

            return(
                <Form>
                <FieldArray name="students">
                {( ) =>(
                    <>
                    <div>
                        <div className="md:flex" >
                            <div className="md:w-1/2 p-3 text-center text-lg">
                                <label>Nombre completo</label>
                            </div>
                            <div className="md:w-1/2 p-3 text-center text-lg">
                                <label>Registro</label>
                            </div>
                        </div>

                        {values.students.length > 0 &&
                            values.students.map((student, index) =>(
                            <div className="md:flex" key={student.studentId}>
                                <div className="md:w-1/2 flex items-center justify-center" >
                                    <p>{student.fullName}</p>
                                </div>
                                <div className="md:w-1/2 px-3 text-center">
                                    <Field
                                    as ="select"
                                    name = {`students.${index}.register`}
                                    className= {select}
                                    defaultValue = {`${registerOptionsMatch(student.register)}`}
                                    >
                                        <option value="" >Seleccionar</option>
                                        {registerOptions.map((option) =>(
                                        <option key={option.id} value={option.id}>
                                        {option.value}
                                        </option>
                                        ))}
                                    </Field>
                                    <ErrorArrayMessage
                                    name ={`student.${index}.register`}
                                    error = {errors}
                                    />
                                </div>
                            </div>
                        ))
                        }
                        <div className="items-center gap-2 mt-3 sm:flex">
                            <button
                                type="submit"
                                className="w-full mt-2 p-2.5 flex-1 text-white font-bold bg-orange-500/80 hover:bg-orange-500 rounded-md outline-none ring-offset-2 ring-orange-500 focus:ring-2"
                            >
                                {isCreateRegister ? "Crear" : "Editar"}
                            </button>
                            <button
                                className="w-full mt-2 p-2.5 flex-1 text-white bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none border ring-offset-2 ring-zinc-500 focus:ring-2"
                                onClick={() => hideModalOfAssistance()}
                            >
                                Cerrar
                            </button>
                        </div>
                    </div>
                    </>
                )}
                </FieldArray>
                </Form>
            )
        }}
        </Formik>
        )}
      </Modal>
    </>
    )}
    </>
  );
}

export default StudentClasses;
