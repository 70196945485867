import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "../../shared/components/Modal/Modal";
import jwt_decode from "jwt-decode";
import Pen from "../../shared/assets/icons/Pen";
import TrashCan from "../../shared/assets/icons/TrashCan";
import InformationCircle from "../../shared/assets/icons/InformationCircle";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../shared/components/CRUD/Pagination";
import { useAxiosWithAuth } from '../../hooks/useAxiosWithAuth';
import SortableTableHeaders from "../../shared/components/CRUD/SortableTableHeader";
import TableFilter from "../../shared/components/CRUD/TableFilter";
import { setAll as setAllRoles } from "../../reducers/rolesReducer";
import {
  setList as setListUsers,
  setCurrentPage,
  setTotalPages,
} from "./usersReducer";
import ResponseMessage from "../../shared/components/ResponseMessage/ResponseMessage";
import Spinner from "../../shared/components/Spinner/Spinner";
import useIsLoadingSection from "../../hooks/useIsLoadingSection";

const rolesTranslations = {
  "Admin": "Administrador",
  "Professor": "Profesor",
};

const allTeaches = [
  {"value": 0, "label": "No"},
  {"value": 1, "label": "Si"},
];

const teacherRoles = [
  2, // Professor
];

const Users = () => {
  const token = useSelector((state) => state.layout.token);
  const axios = useAxiosWithAuth();
  const role = useSelector((state) => state.layout.role);
  const userLogged = useSelector((state) => state.layout.userLogged);
  const allRoles = useSelector((state) => state.roles.all);
  const sectionActions = useSelector((state) => state.layout.sectionActions);
  const isLoadingSection = useIsLoadingSection("users");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listUsers = useSelector((state) => state.users.list);
  const currentPage = useSelector((state) => state.users.currentPage);
  const totalPages = useSelector((state) => state.users.totalPages);
  const [updateTable, setUpdateTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUserHasCoursesModal, setShowUserHasCoursesModal] = useState(false);
  const [model, setModel] = useState({
    id: null,
    firstName: "",
    lastName: "",
    email: "",
    roleId: "",
    password: "",
    repeat_password: "",
    teaches: false,
    currentValuePerHour: null,
    newValuePerHour: null,
    dateNewValuePerHour: null,
    referenceEmail: "",
  });
  const [isCreate, setIsCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilterDropdownOptions, setSelectedFilterDropdownOptions] = useState("");
  const [searchTermFilter, setSearchTermFilter] = useState("");
  const [searchColumnFilter, setSearchColumnFilter] = useState("");
  const [roleFilter, setRoleFilter] = useState(null);
  const [teachesFilter, setTeachesFilter] = useState(null);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [message, setMessage] = useState("");
  const [editPassword, setEditPassword] = useState(false);
  const [lastAction, setLastAction] = useState("");

  const boxInput = "shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
  const selectRoleId =  "block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 my-2 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600"

   const handleChangeRole = (roleId) => {
    setRoleFilter(roleId);
  };

  const handleChangeTeaches = (teaches) => {
    setTeachesFilter(teaches);
  };

  const columns = [
    {columnId: "firstName", columnName: "Nombre usuario", showInTable: true,  filterable: true,  dataType: "string"},
    {columnId: "roleId",    columnName: "Rol",            showInTable: true,  filterable: true,  dataType: "dropdown", isMulti: false, className: "Role", onChange: handleChangeRole,    options: allRoles,   selectedOptions: roleFilter,    placeholder: "Rol de usuario", translations: rolesTranslations},
    {columnId: "teaches",   columnName: "Da clases",      showInTable: false, filterable: true,  dataType: "dropdown", isMulti: false,                    onChange: handleChangeTeaches, options: allTeaches, selectedOptions: teachesFilter, placeholder: "Da clases"},
    {columnId: "email",     columnName: "Email",          showInTable: true,  filterable: true,  dataType: "string"}
  ];

  useEffect(() => {
    if (token) {
      getUsersList(
        currentPage,
        searchTermFilter,
        searchColumnFilter,
        roleFilter,
        teachesFilter,
        orderBy,
        orderDirection
      );
      getRoles();
    }
  }, [token]);

  useEffect(() => {
    if (responseMessage !== "") {
      setTimeout(() => {
        setResponseMessage("");
      }, 5000);
    }
  }, [responseMessage]);

  useEffect(() => {
    if (updateTable && token && role === 1) {
      getUsersList(
        currentPage,
        searchTermFilter,
        searchColumnFilter,
        roleFilter,
        teachesFilter,
        orderBy,
        orderDirection
      );
      setUpdateTable(false);
    }
  }, [updateTable]);

  const getUsersList = async (
    currentPage,
    searchTermFilter = '',
    searchColumnFilter = null,
    roleFilter = null,
    teachesFilter = null,
    orderBy = 'id',
    orderDirection = 'DESC'
  ) => {
    dispatch(setListUsers(null));

    let searchColumnFilterURLParam = [];
    if(searchColumnFilter) {
      searchColumnFilterURLParam = searchColumnFilter.map((columnId, index) => {
        return "&searchColumns[" + index + "]=" + encodeURIComponent(columnId);
      });
    }
    let roleFilterURLParam = "";
    if(roleFilter) {
      roleFilterURLParam = "&roleFilter=" + roleFilter.value;
    }
    let teachesFilterURLParam = "";
    if(teachesFilter) {
      teachesFilterURLParam = "&teachesFilter=" + teachesFilter.value;
    }
    const requestParams = new URLSearchParams({
      page: currentPage,
      orderBy: orderBy,
      orderDirection: orderDirection,
      searchTerm: searchTermFilter,
    }).toString() +
      searchColumnFilterURLParam.join("") +
      roleFilterURLParam +
      teachesFilterURLParam;
    axios
      .get("/user/list?"+requestParams)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentPage(res.data.currentPage));
          dispatch(setTotalPages(res.data.totalPages));
          dispatch(setListUsers(res.data.users));
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error( message.error )
      });
  };

  const handleFilterDropdownValues = (onChangeFilterDropdownValues) => {
    let selectedFilterDropdownOptions = [];
    let filteredColumnIds = onChangeFilterDropdownValues.map((columnFiltered) => {
      selectedFilterDropdownOptions.push(columnFiltered);
      return columnFiltered.value;
    });

    setSelectedFilterDropdownOptions(selectedFilterDropdownOptions);
    setSearchColumnFilter(filteredColumnIds);
  };

  const handleChangePage = (page) => {
    dispatch(setCurrentPage(page));
    if (token) {
      getUsersList(
        page,
        searchTermFilter,
        searchColumnFilter,
        roleFilter,
        teachesFilter,
        orderBy,
        orderDirection
      );
    }
  };

  const handleEdit = (event, user) => {
    event.preventDefault();
    setLastAction("edit");
    setModel(user);
    setIsEdit(true);
    setShowModal(true);
  };

  const handleDelete = (event, user) => {
    event.preventDefault();
    setLastAction("delete");
    setModel(user);
    setIsDelete(true);
    setShowModal(true);
  };

  const handleCreate =  (event) => {
    event.preventDefault();
    setLastAction("create");
    setIsCreate(true);
    setShowModal(true);
  };

  const handleView =  (event, user) => {
    event.preventDefault();
    setLastAction("view");
    setModel(user);
    setIsView(true);
    setShowModal(true);
  };

  const handleSortColumn = (columnId) => {
    return ((event) => {
      event.preventDefault();
      setOrderBy(columnId);
      if(orderDirection === "ASC") {
        setOrderDirection("DESC");
      } else {
        setOrderDirection("ASC");
      }
      setUpdateTable(true);
    });
  };

  const handleFilter = () => {
    setSearchTermFilter(searchTerm);
    setOrderBy("id");
    setOrderDirection("DESC");
    dispatch(setCurrentPage(1));
    setUpdateTable(true);
  };

  const resetFilter = () => {
    setSearchTerm("");
    setSearchTermFilter("");
    setSearchColumnFilter("");
    setOrderBy("id");
    setOrderDirection("DESC");
    dispatch(setCurrentPage(1));
    setRoleFilter("");
    setTeachesFilter("");
    setSelectedFilterDropdownOptions([]);
    setUpdateTable(true);
  };

  const handleSearchTextChange = (event) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  const hideModal = () => {
    resetModel();
    setEditPassword(false);
    setShowModal(false);
    if (isDelete) {
      setIsDelete(false);
    } else if (isCreate) {
      setIsCreate(false);
    } else if (isEdit) {
      setIsEdit(false);
    } else if (isView) {
      setIsView(false);
    }
  };

  const hideUserHasCoursesModal = () => {
    resetModel();
    setEditPassword(false);
    setShowUserHasCoursesModal(false);
  };

  const resetModel = () => {
    setModel({
      id: null,
      firstName: "",
      lastName: "",
      email: "",
      roleId: "",
      currentValuePerHour: null,
      newValuePerHour: null,
      dateNewValuePerHour: null
    });
  };

  const getRoles = async () => {
    if (allRoles.length > 0) {
      return allRoles;
    } else {
      return await axios
        .get("/role/getAll")
        .then((res) => {
          if (res.status === 200) {
            const roles = res.data.roles.map((role) => {
              return { "value": role.id, "label": role.name};
            });
            dispatch(setAllRoles(roles));
            return roles;
          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            const cookies = new Cookies();
            cookies.remove("jwt_token");
            navigate("/login");
          }
          console.error( message.error )
        });
    }
  };

  const isExistingEmail = async (email) => {
    return axios
      .post("/user/checkEmail" , {email: email})
      .then((res) => {
        return (res.status === 200 && res.data.status === "existing_email");
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error( message.error )
      });
  };

  const hasPermission = (action) => {
    return sectionActions && sectionActions.includes(action);
  };

  const actionUser = async (action, data = null) => {
    if (!data) {
      data = model;
    }

    axios
      .post("/user/" + action, data)
      .then((res) => {
        if (res.status === 200) {
          if (res.data.user === false) {
            setShowUserHasCoursesModal(true);
          } else {
            setResponseMessage(res.data.status);
            setUpdateTable(true);
          }
        } else {
          setResponseMessage(res.data.message);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error( message.error )
        setResponseMessage("error");
      });

    hideModal();
  };

  useEffect(() => {
    if (responseMessage === "success") {
      setMessage(lastAction === "create"
        ? "¡Usuario creado exitosamente!"
        : lastAction === "edit"
        ? "¡Usuario modificado exitosamente!"
        : lastAction === "delete"
        ? "¡Usuario borrado existosamente!"
        : "")
    } else if (responseMessage === "existing_email") {
      setMessage("El email ya está en uso.");
    } else if (responseMessage === "invalid_data") {
      setMessage("Datos inválidos.");
    } else if (responseMessage === "error") {
      setMessage("Ocurrió un error. ¡Vuelva a intentar mas tarde!")
    }
    if (responseMessage) {
      setShowResponseMessage(true);
    }
  }, [responseMessage]);

  const hideResponseMessage = () => {
    setShowResponseMessage(false);
    setResponseMessage("");
    setMessage("");
  };

  return (
    <>
    {isLoadingSection && (
      <Spinner
        color="#000000"
        size={80}
      />
    )}
    {!isLoadingSection && (
    <>
    <div className="mx-4 font-bold text-left text-sky-600/70 text-3xl mb-8">Usuarios</div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="flex items-center gap-4 py-3 px-3 md:px-10 mb-4">
            <TableFilter
              placeholderText={"Buscar usuario..."}
              searchTermFilter={searchTermFilter}
              onChangeSearchText={handleSearchTextChange}
              onFilter={handleFilter}
              onChangeFilterDropdownValues={handleFilterDropdownValues}
              selectedFilterDropdownOptions={selectedFilterDropdownOptions}
              tableColumns={columns}
              onResetFiltersAndOrder={resetFilter}
            />
            {hasPermission("create") && (
              <div style={{marginLeft: "auto"}}>
                <div className="relative">
                  <button
                    className="bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded"
                    type="button"
                    onClick={(ev) => handleCreate(ev)}
                  >
                    Nuevo usuario
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="p-3 w-full inline-block align-middle">
            <div className="overflow-auto border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map((column, index) => {
                      if(column.showInTable) {
                        return (
                          <SortableTableHeaders
                            id={column.columnId}
                            key={"header_" + index}
                            headerText={column.columnName}
                            onSortColumn={handleSortColumn(column.columnId)}
                            sortBy={orderBy}
                            sortOrder={orderDirection}
                          />
                        );
                      } else {
                        return false;
                      }
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {listUsers &&  listUsers.length > 0 ?
                    listUsers.map((user, idx) => {
                    return (
                      <tr key={"row_" + idx}>
                        <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                          {user.firstName + " " + user.lastName}
                        </td>
                        <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                          {rolesTranslations[user.role.name]}
                        </td>
                        <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                          {user.email}
                        </td>
                        <td className="px-6 py-4 flex justify-center gap-x-4 text-sm whitespace-nowrap">
                          {hasPermission("update") && (
                            <button
                              className="text-zinc-500 hover:text-zinc-600"
                              title="Editar"
                              onClick={(ev) => handleEdit(ev, user)}
                            >
                              <Pen />
                            </button>
                          )}
                          {(userLogged.id !== user.id) && hasPermission("delete") && (
                            <button
                              className="text-zinc-500 hover:text-zinc-600"
                              title="Borrar"
                              onClick={(ev) => handleDelete(ev, user)}
                            >
                              <TrashCan />
                            </button>
                          )}
                          <button
                            className="text-zinc-500 hover:text-zinc-600"
                            href=""
                            title="Ver"
                            onClick={(ev) => handleView(ev, user)}
                          >
                            <InformationCircle />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                  :
                  <>
                      <tr className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                        {/* Mejorar */}
                        <td className="px-6 py-4 text-sm text-right text-gray-800 whitespace-nowrap">
                          "No se encontraron resultados"
                        </td>
                      </tr>
                    </>
                }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Pagination currentPage={currentPage} totalPages={totalPages} onChangePage={handleChangePage} />

      <ResponseMessage
        open={showResponseMessage}
        onClose={hideResponseMessage}
        type={responseMessage === 'success' ? 'success' : 'error'}
        message={message}
      />

      <Modal
        open={showModal}
        onClose={hideModal}
        title={
          isCreate
            ? "Nuevo usuario"
            : isEdit
            ? "Editar usuario"
            : isDelete
            ? "Eliminar usuario " + model.firstName + " " + model.lastName
            : "Ver usuario"
        }
        backdrop={true}
        cross={true}
      >

        {isCreate || isEdit || isView ? (
          <Formik
            initialValues={model}
            validate={(values) => {
              const errors = {}
              const valuePerHourRegex = /^\d+(\.\d{1,2})?$/;

              if (!values.firstName) {
                errors.firstName = "Campo requerido";
              }
              if (!values.lastName) {
                errors.lastName = "Campo requerido";
              }
              if (!values.email) {
                errors.email = "Campo requerido";
              } else if (
                !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+(\.[a-zA-Z]{2,})+$/i.test(values.email)
              ) {
                errors.email = "Correo inválido";
              }
              if (!values.roleId) {
                errors.roleId = "Campo requerido";
              }
              if (isCreate || (isEdit && editPassword)) {
                if (!values.password) {
                  errors.password = "Campo requerido";
                } else if (
                  !/^(?=.*\d)(?=.*\W|.*_)(?=.*[A-Z])\S{8,12}$/g.test(values.password)
                ) {
                  errors.password = "Mínimo de 8 a 12 caracteres, con al menos 1 mayúscula, 1 número y 1 caracter especial";
                }
                if (isEdit) {
                  if (!values.repeat_password) {
                    errors.repeat_password = "Campo requerido";
                  } else if (values.password !== values.repeat_password) {
                    errors.password = "Las contraseñas no coinciden";
                  }
                }
              }
              if (values.teaches) {
                if (isEdit && model.currentValuePerHour) {
                  if (!!values.newValuePerHour) {
                    if (!valuePerHourRegex.test(values.newValuePerHour)) {
                      errors.newValuePerHour = "Solo se aceptan numeros de hasta dos decimales";
                    }
                    if (!values.dateNewValuePerHour) {
                      errors.dateNewValuePerHour = "Campo Requerido";
                    }
                  }
                }
                if (isCreate || (isEdit && !model.currentValuePerHour)) {
                  if (!values.currentValuePerHour) {
                    errors.currentValuePerHour = "Campo requerido";
                  } else if (!valuePerHourRegex.test(values.currentValuePerHour)) {
                    errors.currentValuePerHour = "Solo se aceptan numeros de hasta dos decimales";
                  }
                }
              }
              if (
                values.referenceEmail &&
                !/^[a-zA-Z0-9._%+-]+@gmail(\.[a-zA-Z]{2,})+$/i.test(values.referenceEmail)
              ) {
                errors.referenceEmail = "Correo inválido. Debe ser una casilla de Gmail";
              }

              return errors;
            }}
            onSubmit={async (values) => {
              if (values.email !== model.email && await isExistingEmail(values.email)) {
                setResponseMessage("existing_email");
                return;
              }

              if (isCreate) {
                actionUser("create", values);
              } else if (isEdit) {
                actionUser("edit", values);
              }
            }}
          >
            {({ values, setFieldValue, isSubmitting, isValid, errors, touched }) => {
              if (isSubmitting && !isValid) {
                setResponseMessage("invalid_data");
              }
              return (
              <Form>
                <div className="md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-semibold text-gray-800"
                    >

                      Nombre*
                    </label>
                    <Field
                      className = {boxInput}
                      id="firstName"
                      type="text"
                      name="firstName"
                      disabled={isView}
                    />
                    <ErrorMessage
                      name="firstName"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Apellido*
                    </label>
                    <Field
                      className= {boxInput}
                      id="lastName"
                      type="text"
                      name="lastName"
                      disabled={isView}
                    />
                    <ErrorMessage
                      name="lastName"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Email*
                    </label>
                    <Field
                      className= { boxInput }
                      id="email"
                      type="text"
                      name="email"
                      disabled={isView}
                    />
                    <ErrorMessage
                      name="email"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="role"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Rol*
                    </label>
                    <Field
                      as="select"
                      className={selectRoleId}
                      id="roleId"
                      name="roleId"
                      disabled={isView}
                      onChange={(event) => {
                        const roleId = event.target.value;
                        setFieldValue("roleId", roleId);
                        if (teacherRoles.includes(parseInt(roleId, 10))) {
                          setFieldValue("teaches", true);
                        }
                      }}
                    >
                      <option value="">Seleccionar</option>
                      {allRoles && allRoles.map((role, index) => {
                        return (
                          <option key={"role_" + index} value={role.value}>
                            {(role.label in rolesTranslations) ? rolesTranslations[role.label] : role.label}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="roleId"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="referenceEmail"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Email de referencia
                    </label>
                    <Field
                      className={boxInput}
                      id="referenceEmail"
                      type="text"
                      name="referenceEmail"
                      disabled={isView}
                    />
                    <ErrorMessage
                      name="referenceEmail"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-1/2 px-3">
                    <div className="md:w-full px-3 inline-flex">
                      <label
                        htmlFor="teaches"
                        className="block text-sm font-semibold text-gray-800"
                      >
                        Da clases
                      </label>
                      <Field
                        type="checkbox"
                        name="teaches"
                        className="ml-2"
                        disabled={isView}
                        onChange={(event) => {
                          if (teacherRoles.includes(parseInt(values.roleId, 10))) {
                            setFieldValue("teaches", true);
                          } else {
                            setFieldValue("teaches", event.target.checked);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
                {!isView && (
                  <div className="md:flex">
                    <div className={`${(editPassword || (isCreate && values.teaches)) ? "md:w-1/2" : "md:w-full"} px-3`}>
                      <div className="w-full inline-flex justify-between">
                        <label
                          htmlFor="password"
                          className="block text-sm font-semibold text-gray-800"
                        >
                          {`Contraseña${(isCreate) ? "*" : ""}`}
                        </label>
                        {isEdit && (
                          <button
                            type="button"
                            className="text-zinc-500 hover:text-zinc-600"
                            title="Ingresar nueva contraseña"
                            onClick={(ev) => setEditPassword(!editPassword)}
                          >
                            <Pen />
                          </button>
                        )}
                      </div>
                      <Field
                        className={boxInput}
                        id="password"
                        type="password"
                        name="password"
                        disabled={!isCreate && !editPassword}
                      />
                      <ErrorMessage
                        name="password"
                        className="text-red-600"
                        component="div"
                      />
                    </div>
                    {isEdit && editPassword && (
                      <div className="md:w-1/2 px-3">
                        <label
                          htmlFor="repeat_password"
                          className="block text-sm font-semibold text-gray-800"
                        >
                          Repetir contraseña
                        </label>
                        <Field
                          className={boxInput}
                          id="repeat_password"
                          type="password"
                          name="repeat_password"
                          disabled={!editPassword}
                        />
                        <ErrorMessage
                          name="repeat_password"
                          className="text-red-600"
                          component="div"
                        />
                      </div>
                      )}
                      {(values.teaches && isCreate) &&
                        (
                          <div className="md:w-1/2 px-3">
                            <label
                              htmlFor="currentValuePerHour"
                              className="block text-sm font-semibold text-gray-800"
                            >
                              Valor Hora Actual*
                            </label>
                            <Field
                              className={boxInput}
                              id="currentValuePerHour"
                              type="text"
                              name="currentValuePerHour"
                              disabled={isView}
                            />
                            <ErrorMessage
                              name="currentValuePerHour"
                              className="text-red-600"
                              component="div"
                            />
                          </div>
                        )}
                    </div>
                )}
                {values.teaches && (
                      <div className="md:w-full">
                      { (isView || (isEdit && !model.currentValuePerHour)) && (
                        <div className="md:w-full px-3">
                          <label
                            htmlFor="currentValuePerHour"
                            className="block text-sm font-semibold text-gray-800"
                          >
                            Valor Hora Actual*
                          </label>
                          <Field
                            className={boxInput}
                            id="currentValuePerHour"
                            type="text"
                            name="currentValuePerHour"
                            disabled={isView}
                          />
                          <ErrorMessage
                            name="currentValuePerHour"
                            className="text-red-600"
                            component="div"
                          />
                        </div>
                      )}
                        {(isView || (isEdit && model.currentValuePerHour)) && (
                        <div className="md:flex">
                          <div className="md:w-1/2 px-3">
                          <div className="w-full inline-flex justify-between">
                            <label
                              htmlFor="newValuePerHour"
                              className="block text-sm font-semibold text-gray-800"
                            >
                              Nuevo Valor Hora*
                            </label>
                          </div>
                          <Field
                            className={boxInput}
                            id="newValuePerHour"
                            type="text"
                            name="newValuePerHour"
                            disabled={!isEdit}
                            />
                          <ErrorMessage
                            name="newValuePerHour"
                            className="text-red-600"
                            component="div"
                            />
                          </div>
                          <div className="md:w-1/2 px-3">
                          <label
                          htmlFor="dateNewValuePerHour"
                          className="block text-sm font-semibold text-gray-800"
                          >
                          Desde*
                        </label>
                        <Field
                          className={boxInput}
                          id="dateNewValuePerHour"
                          type="date"
                          name="dateNewValuePerHour"
                          disabled={!isEdit || !values.newValuePerHour}
                        />
                        <ErrorMessage
                          name="dateNewValuePerHour"
                          className="text-red-600"
                          component="div"
                          />
                          </div>

                        </div>
                        )}
                      </div>
                )}
                <div className="items-center gap-2 mt-3 sm:flex">
                  {!isView && (
                    <button
                      type="submit"
                      className="w-full mt-2 p-2.5 flex-1 text-white font-bold bg-orange-500/80 hover:bg-orange-500 rounded-md outline-none ring-offset-2 ring-orange-500 focus:ring-2"
                    >
                      {isCreate ? "Crear" : "Editar"}
                    </button>
                  )}
                  <button
                    className="w-full mt-2 p-2.5 flex-1 text-white bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none border ring-offset-2 ring-zinc-500 focus:ring-2"
                    onClick={() => hideModal()}
                  >
                    Cerrar
                  </button>
                </div>
              </Form>
              );
            }}
          </Formik>
        ) : (
          <>
            <p className="mt-2 text-[18px] leading-relaxed text-gray-500">
              ¿Está seguro de eliminar al usuario?
            </p>
            <div className="items-center gap-2 mt-3 sm:flex">
              <button
                type="submit"
                className="w-full mt-2 p-2.5 flex-1 text-white bg-red-500/80 hover:bg-red-500 rounded-md outline-none ring-offset-2 ring-red-500 focus:ring-2"
                onClick={() => actionUser("delete")}
              >
                Eliminar
              </button>
              <button
                className="w-full mt-2 p-2.5 flex-1 text-white bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none border ring-offset-2 ring-zinc-500 focus:ring-2"
                onClick={() => hideModal()}
              >
                Cancelar
              </button>
            </div>
          </>
        )}
      </Modal>
      <Modal
        open={showUserHasCoursesModal}
        onClose={hideUserHasCoursesModal}
        title={"Atencion!"}
        backdrop={true}
        cross={true}
      >
        <>
          <p className="mt-2 text-[18px] leading-relaxed text-gray-500">
            El usuario tiene cursos asignados. Por favor, desasigne los cursos antes de continuar.
          </p>
          <div className="items-center gap-2 mt-3 sm:flex">
            <button
              className="w-full mt-2 p-2.5 flex-1 text-white bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none border ring-offset-2 ring-zinc-500 focus:ring-2"
              onClick={() => hideUserHasCoursesModal()}
            >
              Entendido!
            </button>
          </div>
        </>
      </Modal>
    </>
    )}
    </>
  );
}

export default Users;
