import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: null,
  label: null,
};

export const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    selectClient: (state, action) => {
      state.value = action.payload.value;
      state.label = action.payload.label;
    },
    resetClient: (state) => {
      state.value = null;
      state.label = null;
    },
  },
});

export const { selectClient, resetClient } = reportSlice.actions;

export default reportSlice.reducer;
