import React from "react";
import ApiCalendar from "react-google-calendar-api";
import { useDispatch, useSelector } from "react-redux";
import { setIsSignedIn } from "../../../reducers/calendarReducer";

const config = {
  clientId: process.env.REACT_APP_CLIENT_ID_GOOGLE,
  apiKey: process.env.REACT_APP_API_KEY_GOOGLE,
  scope: "https://www.googleapis.com/auth/calendar",
  discoveryDocs: [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
  ],
};

const apiCalendar = new ApiCalendar(config);
export const createEventWithVideo = async ({
  eventTitle,
  dateTimeStart,
  timeZone,
  dateTimeEnd,
  recurrence,
  attendees,
}) => {
  const event = {
    summary: eventTitle,
    start: {
      dateTime: dateTimeStart,
      timeZone: timeZone,
    },
    end: {
      dateTime: dateTimeEnd,
      timeZone: timeZone,
    },
    recurrence: recurrence,
    attendees: attendees,
  };

  return await apiCalendar
    .createEventWithVideoConference(event)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      // console.error("Error al completar el evento:", error);
    });
};
export const editEventWithVideo = ({
  eventTitle,
  dateTimeStart,
  timeZone,
  dateTimeEnd,
  recurrence,
  attendees,
  googleId,
}) => {
  const event = {
    summary: eventTitle,
    start: {
      dateTime: dateTimeStart,
      timeZone: timeZone,
    },
    end: {
      dateTime: dateTimeEnd,
      timeZone: timeZone,
    },
    recurrence: recurrence,
    attendees: attendees,
  };

  return apiCalendar
    .updateEvent(event, googleId)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      // console.log("Error al editar el evento:", error);
    });
};

export const deleteEvent = (event) => {
  apiCalendar
    .deleteEvent(event)
    .then(() => {})
    .catch((error) => {
      // console.error("Error al eliminar el evento:", error);
    });
};
export const CalendarSignIn = () => {
  const dispatch = useDispatch();
  const isSignedIn = useSelector((state) => state.calendar.isSignedIn);

  const handleItemClick = (name) => {
    if (name === "sign-in") {
      apiCalendar.handleAuthClick()
      .then(() => {
        dispatch(setIsSignedIn(true));
      })
      .catch(() => {
        // TODO: handle error
      });
    } else if (name === "sign-out") {
      apiCalendar.handleSignoutClick();
      dispatch(setIsSignedIn(false));
    }
  };

  return (
    <>
      {isSignedIn ? (
        <>
        <div className="flex justify-end px-10">
          <button
          className="bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded"
          onClick={() => handleItemClick("sign-out")}
          >
            Desvincular Google Calendar
          </button>
        </div>
        </>
      ) : (
        <>
          <div className="flex justify-end px-10">
            <button
              className="bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded"
              onClick={() => handleItemClick("sign-in")}
            >
              Vincular Google Calendar
            </button>
          </div>
        </>
      )}
    </>
  );
};
