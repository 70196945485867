import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/b1u-300x146.png";
import HamburguerMenu from "../../assets/icons/HamburguerMenu";
import Avatar from "../../assets/icons/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedSection, reset as resetStore } from "./layoutReducer";
import useNavSections from "../../../hooks/useNavSections";
import useLogout from "../../../hooks/useLogout";

function Navbar({ setEditProfile }) {
  const dispatch = useDispatch();
  const userLogged = useSelector((state) => state.layout.userLogged);
  const sections = useNavSections();
  const role = useSelector((state) => state.layout.role);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const logout = useLogout();

  const generateRoute = (section) => {
    switch (section) {
      case "home":
        return "/home";
      case "user":
        return "/users";
      case "client":
        return "/clients";
      case "course":
        return "/courses";
      case "class":
        return "/classes";

      default:
        return "";
    };
  };

  const getSectionName = (section) => {
    switch (section.toLowerCase()) {
      case "home":
        return "Inicio";
      case "client":
        return "Clientes";
      case "course":
        return "Cursos";
      case "class":
        return "Clases";
      case "report":
        return "Reportes";
      case "user":
        return "Usuarios";

      default:
        return section;
    };
  }

  const handleClickEditProfile = () => {
    setOpenProfileMenu(!openProfileMenu);
    setEditProfile(true);
  };

  return (
    <>
      <nav className="bg-sky-600/70 md:bg-white border-b border-b-sky-50">
        <div className="flex justify-between">
          <div className="flex items-center justify-center md:bg-sky-600/70 w-20 h-14">
            <img src={logo} className="w-16" alt="BeOneUp"></img>
          </div>
          <div className="flex items-center justify-end">
            <div className="" id="mobile-menu">
              <ul className="flex items-center justify-end flex-row pr-4">
                <li className="md:hidden">
                  <button
                    type="button"
                    className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => {
                      setOpenMobileMenu(!openMobileMenu);
                    }}
                  >
                    <span className="sr-only">Abrir/cerrar menú</span>
                    <HamburguerMenu />
                  </button>
                </li>
                {setEditProfile && (
                  <li>
                    <div onMouseEnter={() => setOpenProfileMenu(!openProfileMenu)} onMouseLeave={() => setOpenProfileMenu(!openProfileMenu)}>
                      <div className="flex items-center gap-x-4">
                        <div className="hidden text-right md:block">
                          <p className="leading-3">
                            <strong className="block text-sky-600/70">
                              {userLogged.firstName + " " + userLogged.lastName}
                            </strong>
                            <span className="text-sm text-gray-500">
                              {role === 1 ? "Administrador" : "Profesor"}
                            </span>
                          </p>
                        </div>
                        <button
                          type="button"
                          className="hidden md:block rounded-full bg-sky-600/70 text-sm"
                          id="user-menu-button"
                        >
                          <Avatar />
                        </button>
                      </div>
                      {openProfileMenu && (
                        <div
                          className="absolute right-0 z-10 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none mt-0"
                          tabIndex="-1"
                        >
                          <button
                            className="block px-4 py-2 text-sm text-gray-700"
                            tabIndex="-1"
                            onClick={() => {
                              handleClickEditProfile();
                            }}
                          >
                            Ver perfil
                          </button>
                          <button
                            className="block px-4 py-2 text-sm text-gray-700"
                            tabIndex="-1"
                            onClick={() => logout()}
                          >
                            Cerrar sesión
                          </button>
                        </div>
                      )}
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="md:hidden" id="mobile-menu">
          {openMobileMenu && sections && (
            <div className="space-y-1 px-2 pt-2 pb-3">
              {sections.length > 0 &&
                sections.map((section, index) => {
                  return (
                    <Link
                      onClick={() => {
                        setOpenMobileMenu(!openMobileMenu);
                        dispatch(setSelectedSection(section));
                      }}
                      to={generateRoute(section)}
                      className="text-white block px-3 py-2 rounded-md text-base font-medium"
                      key={index}
                    >
                      {getSectionName(section)}
                    </Link>
                  );
                })}
              {sections.length === 0 && (
                <Link
                  onClick={() => {
                    setOpenMobileMenu(!openMobileMenu);
                    dispatch(setSelectedSection("home"));
                  }}
                  to={generateRoute("home")}
                  className="text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  {getSectionName("home")}
                </Link>
              )}
              {setEditProfile && (
                <button
                  className="text-white block px-3 py-2 w-full rounded-md text-base font-medium"
                  onClick={() => logout()}
                >
                  Salir
                </button>
              )}
            </div>
          )}
        </div>
      </nav>
    </>
  );
}

export default Navbar;
