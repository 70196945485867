import React from "react";
import Spinner from "../../shared/components/Spinner/Spinner";
import { CalendarSignIn } from "../../shared/components/calendar/calendarConfig";
import MyCalendar from "../../shared/components/calendar/Iframe";
import { useSelector } from "react-redux";

function Home() {
  const isLoadingSection = false; // TODO implement loading
  const role = useSelector((state) => state.layout.role);

  return (
    <>
      {isLoadingSection && <Spinner color="#000000" size={80} />}
      {!isLoadingSection && (
        <>
          <h1 className="mx-4 font-bold text-left text-sky-600/70 text-3xl mb-8">
            Inicio
          </h1>
          {role === 1 && <CalendarSignIn />}
          <MyCalendar />
        </>
      )}
    </>
  );
}

export default Home;
