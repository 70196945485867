import React from 'react';
import Navbar from '../../shared/components/Layout/Navbar';
import Sidebar from '../../shared/components/Layout/Sidebar';
import { Link, isRouteErrorResponse, useRouteError } from 'react-router-dom';

const Error = () => {

  const error = useRouteError();
  const isRouteError = isRouteErrorResponse(error);
  const status = isRouteError ? error.status : 0;

  const getErrorDescription = () => {
    switch (status) {
      case 404:
        return "La página que estaba buscando no se pudo encontrar.";

      default:
        return "Ocurrió un error inesperado.";
    }
  };

  return (
    <>
      <div className="w-full">
        <Navbar />
      </div>
      <div className="flex relative min-h-full">
        <Sidebar />
        <div id="container" className="w-full">
          <div className="flex flex-col w-full h-full justify-center items-center">
            <div className="flex flex-col p-8">
              <h1 className="font-bold text-left text-sky-600/70 text-xl mb-4">
                ¡Error!
              </h1>
              <p
                className="mb-8"
              >
                {getErrorDescription()}
              </p>
              <div className="flex w-full justify-end">
                <Link
                  to="/home"
                  className="bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded"
                >
                  Volver
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

};

export default Error;
