// Font Aweso
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
// To search new icons: https://fontawesome.com/search?o=r&m=free&s=solid
import {
  faUsers,
  faFolder,
  faBookOpen,
  faChalkboardUser,
  faClock,
  faUser,
  faFile,
  faHouse,
  faSort,
  faSortUp,
  faSortDown,
  faFilter,
  faFilterCircleXmark,
  faFilePdf,
  faFileExcel,
  faCalendarDays,
  faCalendarAlt
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faUsers,
  faFolder,
  faBookOpen,
  faChalkboardUser,
  faClock,
  faUser,
  faFile,
  faHouse,
  faSort,
  faSortUp,
  faSortDown,
  faFilter,
  faFilterCircleXmark,
  faFilePdf,
  faFileExcel,
  faCalendarDays,
  faCalendarAlt
);

const Icon = ({ icon, size, color, className }) => {
  return <FontAwesomeIcon
    icon={"fa-solid fa-"+icon}
    size={size}
    color={color}
    className={className}
  />
}

export default Icon;
