import axios from 'axios';
import { useSelector } from "react-redux";
import config from "../shared/config";

export const useAxiosWithAuth = () => {
  const token = useSelector((state) => state.layout.token);

  const instance = axios.create({
    baseURL: `${config.API_BASE}/api`,
    headers: {
      "Content-Type": "application/json; charset=UTF-8",
      Authorization: "Bearer " + token,
    },
  });

  return instance;
};
