import React, { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Modal from "../../shared/components/Modal/Modal";
import Pen from "../../shared/assets/icons/Pen";
import TrashCan from "../../shared/assets/icons/TrashCan";
import InformationCircle from "../../shared/assets/icons/InformationCircle";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../shared/components/CRUD/Pagination";
import { useAxiosWithAuth } from '../../hooks/useAxiosWithAuth';
import SortableTableHeaders from "../../shared/components/CRUD/SortableTableHeader";
import TableFilter from "../../shared/components/CRUD/TableFilter";
import { setAll as setAllCountries } from "../../reducers/countriesReducer";
import {
  setList as setListClients,
  setCurrentPage,
  setTotalPages,
} from "./clientsReducer";
import ResponseMessage from "../../shared/components/ResponseMessage/ResponseMessage";
import TwoAvatarsNoColor from "../../shared/assets/icons/TwoAvatarsNoColor";
import Students from "../Students/Students";
import Spinner from "../../shared/components/Spinner/Spinner";
import useIsLoadingSection from "../../hooks/useIsLoadingSection";
import Icon from '../../shared/components/Icon/Icon'
import BulkUpload from "../Students/BulkUpload";

const Clients = () => {
  const token = useSelector((state) => state.layout.token);
  const axios = useAxiosWithAuth();
  const role = useSelector((state) => state.layout.role);
  const sectionActions = useSelector((state) => state.layout.sectionActions);
  const isLoadingSection = useIsLoadingSection("clients");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const listClients = useSelector((state) => state.clients.list);
  const currentPage = useSelector((state) => state.clients.currentPage);
  const totalPages = useSelector((state) => state.clients.totalPages);
  const allCountries = useSelector((state) => state.countries.all);
  const [updateTable, setUpdateTable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [model, setModel] = useState({
    id: null,
    businessName: "",
    contactName: "",
    countryId: null,
    taxPrefixId: null,
    taxId: "",
    email: "",
    phone: "",
    address: "",
    registrationDate: null,
    currentValuePerHour: null,
    newValuePerHour: null,
    dateNewValuePerHour: null
  });
  const [isCreate, setIsCreate] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isView, setIsView] = useState(false);
  const [orderBy, setOrderBy] = useState("id");
  const [orderDirection, setOrderDirection] = useState("DESC");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedFilterDropdownOptions, setSelectedFilterDropdownOptions] = useState("");
  const [searchTermFilter, setSearchTermFilter] = useState("");
  const [searchColumnFilter, setSearchColumnFilter] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [message, setMessage] = useState("");
  const [studentsModal, setStudentsModal] = useState(false);
  const [studentClient, setStudentClient] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [lastAction, setLastAction] = useState("");
  const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);

  const boxInput = "shadow appearance-none border rounded w-full my-2 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
  const select =  "block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 my-2 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-600"

  const columns = [
    {columnId: "businessName",     columnName: "Nombre Empresa", showInTable: true,  filterable: true,  dataType: "string"},
    {columnId: "contactName",      columnName: "Contacto",       showInTable: true,  filterable: true,  dataType: "string"},
    {columnId: "email",            columnName: "Email",          showInTable: true,  filterable: true,  dataType: "string"},
    {columnId: "registrationDate", columnName: "Fecha de Alta",  showInTable: true,  filterable: false, dataType: "date"}
  ];

  useEffect(() => {
    if (token) {
      getClientsList(
        currentPage,
        searchTermFilter,
        searchColumnFilter,
        orderBy,
        orderDirection
      );
      getCountries();
    }
  }, [token]);


  useEffect(() => {
    if (responseMessage !== "") {
      setTimeout(() => {
        setResponseMessage("");
      }, 5000);
    }
  }, [responseMessage]);

  useEffect(() => {
    if (updateTable && token && role === 1) {
      getClientsList(
        currentPage,
        searchTermFilter,
        searchColumnFilter,
        orderBy,
        orderDirection
      );
      setUpdateTable(false);
    }
  }, [updateTable]);

  const getClientsList = async (
    currentPage,
    searchTermFilter = '',
    searchColumnFilter = null,
    orderBy = 'id',
    orderDirection = 'ASC'
  ) => {
    dispatch(setListClients(null));

    let searchColumnFilterURLParam = [];
    if(searchColumnFilter) {
      searchColumnFilterURLParam = searchColumnFilter.map((columnId, index) => {
        return "&searchColumns[" + index + "]=" + encodeURIComponent(columnId);
      });
    }
    const requestParams = new URLSearchParams({
      page: currentPage,
      orderBy: orderBy,
      orderDirection: orderDirection,
      searchTerm: searchTermFilter,
    }).toString() + searchColumnFilterURLParam.join("");
    axios
      .get("/client/list?"+requestParams)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setCurrentPage(res.data.currentPage));
          dispatch(setTotalPages(res.data.totalPages));
          dispatch(setListClients(res.data.clients));
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error(error.message)
      });
  };

  const getCountries = async () => {
    if (allCountries.length > 0) {
      return allCountries;
    } else {
      return await axios
        .get("/country/getAll")
        .then((res) => {
          if (res.status === 200) {
            const countries = res.data.countries;
            dispatch(setAllCountries(countries));
            return countries;
          }
        })
        .catch(function (error) {
          if (error.response.status === 401) {
            const cookies = new Cookies();
            cookies.remove("jwt_token");
            navigate("/login");
          }
          console.error(error.message)
        });
    }
  };

  const handleFilterDropdownValues = (onChangeFilterDropdownValues) => {
    let selectedFilterDropdownOptions = [];
    let filteredColumnIds = onChangeFilterDropdownValues.map((columnFiltered) => {
      selectedFilterDropdownOptions.push(columnFiltered);
      return columnFiltered.value;
    });

    setSelectedFilterDropdownOptions(selectedFilterDropdownOptions);
    setSearchColumnFilter(filteredColumnIds);
  };

  const handleChangePage = (page) => {
    dispatch(setCurrentPage(page));
    if (token) {
      getClientsList(
        page,
        searchTermFilter,
        searchColumnFilter,
        orderBy,
        orderDirection
      );
    }
  };

  const handleEdit = (event, client) => {
    event.preventDefault();
    setLastAction("edit");
    setModel(client);
    setSelectedCountry(allCountries.find((c) => c.id === client.countryId));
    setIsEdit(true);
    setShowModal(true);
  };

  const handleDelete = (event, client) => {
    event.preventDefault();
    setLastAction("delete");
    setModel(client);
    setIsDelete(true);
    setShowModal(true);
  };

  const handleCreate = (event) => {
    event.preventDefault();
    setLastAction("create");
    setSelectedCountry(null);
    setIsCreate(true);
    setShowModal(true);
  };

  const handleView = (event, client) => {
    event.preventDefault();
    setLastAction("view");
    setModel(client);
    setSelectedCountry(allCountries.find((c) => c.id === client.countryId));
    setIsView(true);
    setShowModal(true);
  };

  const handleSortColumn = (columnId) => {
    return ((event) => {
      event.preventDefault();
      setOrderBy(columnId);
      if(orderDirection === "ASC") {
        setOrderDirection("DESC");
      } else {
        setOrderDirection("ASC");
      }
      setUpdateTable(true);
    });
  };

  const handleFilter = () => {
    setSearchTermFilter(searchTerm);
    setOrderBy("id");
    setOrderDirection("DESC");
    dispatch(setCurrentPage(1));
    setUpdateTable(true);
  };

  const resetFilter = () => {
    setSearchTerm("");
    setSearchTermFilter("");
    setSearchColumnFilter("");
    setOrderBy("id");
    setOrderDirection("DESC");
    dispatch(setCurrentPage(1));
    setSelectedFilterDropdownOptions([]);
    setUpdateTable(true);
  };

  const handleSearchTextChange = (event) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  const hideModal = () => {
    resetModel();
    setShowModal(false);
    if (isDelete) {
      setIsDelete(false);
    } else if (isCreate) {
      setIsCreate(false);
    } else if (isEdit) {
      setIsEdit(false);
    } else if (isView) {
      setIsView(false);
    }
  };

  const resetModel = () => {
    setModel({
      id: null,
      businessName: "",
      contactName: "",
      countryId: "",
      taxPrefixId: "",
      taxId: "",
      email: "",
      phone: "",
      address: "",
      registrationDate: null,
      currentValuePerHour: null,
      newValuePerHour: null,
      dateNewValuePerHour: null
    });
  };

  const showStudentsModal = (evt, client) => {
    setStudentClient(client);
    setStudentsModal(true);
  };

  const hideStudentsModal = () => {
    setStudentClient(null);
    setStudentsModal(false);
  };

  const isExistingEmail = async (email) => {
    return axios
      .post("/client/checkEmail" , {email: email})
      .then((res) => {
        return (res.status === 200 && res.data.status === "existing_email");
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        console.error(error.message)
      });
  };

  const hasPermission = (action) => {
    return sectionActions && sectionActions.includes(action);
  };

  const actionClient = async (action, data = null) => {
    if (!data) {
      data = model;
    }

    axios
      .post("/client/" + action, data)
      .then((res) => {
        if (res.status === 200) {
          setResponseMessage(res.data.status);
          setUpdateTable(true);
        } else {
          setResponseMessage(res.data.message);
        }
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          const cookies = new Cookies();
          cookies.remove("jwt_token");
          navigate("/login");
        }
        setResponseMessage("error");
        console.error(error.message)
      });

    hideModal();
  };

  const renderTaxPrefixesOptions = () => {
    const showSelect = (selectedCountry === null) ||
      (selectedCountry !== null && selectedCountry.tax_prefixes.length > 1);
    return (
      <>
        {showSelect && (
          <option value="">Seleccionar</option>
        )}
        {selectedCountry !== null && selectedCountry.tax_prefixes.map((taxPrefix, idx) => (
          <option key={"taxPrefix_" + idx} value={taxPrefix.id}>
            {taxPrefix.value}
          </option>
        ))}
      </>
    );
  }

  useEffect(() => {
    if (responseMessage === "success") {
      setMessage(lastAction === "create"
        ? "¡Cliente creado exitosamente!"
        : lastAction === "edit"
        ? "¡Cliente modificado exitosamente!"
        : lastAction === "delete"
        ? "¡Cliente borrado existosamente!"
        : "");
    } else if (responseMessage === "existing_email") {
      setMessage("El email ya está en uso.");
    } else if (responseMessage === "invalid_data") {
      setMessage("Datos inválidos.");
    } else if (responseMessage === "error") {
      setMessage("Ocurrió un error. ¡Vuelva a intentar mas tarde!");
    }
    if (responseMessage) {
      setShowResponseMessage(true);
    }
  }, [responseMessage]);

  const hideResponseMessage = () => {
    setShowResponseMessage(false);
    setResponseMessage("");
    setMessage("");
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString([], {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: 'UTC',
    });
  };

  const handleBulkUploadSuccess = () => {
    setUpdateTable(true);
    hideBulkUploadModal();
  };

  const hideBulkUploadModal = () => {
    setShowBulkUploadModal(false);
    setResponseMessage("");
  };

  const handleBulkUpload = (event) => {
    event.preventDefault();
    setShowBulkUploadModal(true);
  };

  return (
    <>
    {isLoadingSection && (
      <Spinner
        color="#000000"
        size={80}
      />
    )}
    {!isLoadingSection && (
    <>
    <div className="mx-4 font-bold text-left text-sky-600/70 text-3xl mb-8">Clientes</div>
      <div className="flex flex-col">
        <div className="overflow-x-auto">
          <div className="flex items-center gap-4 py-3 px-3 md:px-10 mb-4">
            <TableFilter
              placeholderText={"Buscar cliente..."}
              searchTermFilter={searchTermFilter}
              onChangeSearchText={handleSearchTextChange}
              onFilter={handleFilter}
              onChangeFilterDropdownValues={handleFilterDropdownValues}
              selectedFilterDropdownOptions={selectedFilterDropdownOptions}
              tableColumns={columns}
              onResetFiltersAndOrder={resetFilter}
            />
            {hasPermission("create") && (
              <div style={{marginLeft: "auto"}}>
                <div className="relative">
                  <button
                    className="bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 mx-1 rounded"
                    type="button"
                    onClick={(ev) => handleBulkUpload(ev)}
                  >
                    Cargar Cliente
                  </button>
                  <button
                    className="bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 mx-1 rounded"
                    type="button"
                    onClick={(ev) => handleCreate(ev)}
                  >
                    Nuevo cliente
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="p-3 w-full inline-block align-middle">
            <div className="overflow-auto border rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    {columns.map((column, index) => {
                      if(column.showInTable) {
                        return (
                          <SortableTableHeaders
                            id={column.columnId}
                            key={"header_" + index}
                            headerText={column.columnName}
                            onSortColumn={handleSortColumn(column.columnId)}
                            sortBy={orderBy}
                            sortOrder={orderDirection}
                          />
                        );
                      } else {
                        return false;
                      }
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {
                    listClients && listClients.length > 0 ?
                      listClients.map((client, idx) => {
                        return (
                          <tr key={"row_" + idx}>
                            <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                              {client.businessName}
                            </td>
                            <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                              {client.contactName}
                            </td>
                            <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                              {client.email}
                            </td>
                            <td className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                              {formatDate(client.registrationDate)}
                            </td>
                            <td className="px-6 py-4 flex justify-center gap-x-4 text-sm whitespace-nowrap">
                              {hasPermission("update") && (
                                <>
                                  <button
                                  className="text-zinc-500 hover:text-zinc-600"
                                  title="Alumnos"
                                  onClick={(ev) => showStudentsModal(ev, client)}
                                  >
                                    <Icon icon='user' size='xl'/>
                                  </button>
                                  <button
                                    className="text-zinc-500 hover:text-zinc-600"
                                    title="Editar"
                                    onClick={(ev) => handleEdit(ev, client)}
                                    >
                                    <Pen />
                                  </button>
                                </>
                              )}
                              {hasPermission("delete") && (
                                <button
                                  className="text-zinc-500 hover:text-zinc-600"
                                  title="Borrar"
                                  onClick={(ev) => handleDelete(ev, client)}
                                >
                                  <TrashCan />
                                </button>
                              )}
                              <button
                                className="text-zinc-500 hover:text-zinc-600"
                                href=""
                                title="Ver"
                                onClick={(ev) => handleView(ev, client)}
                              >
                                <InformationCircle />
                              </button>
                            </td>
                          </tr>
                        );
                      })
                    :
                    <>
                      <tr className="px-6 py-4 text-sm text-center text-gray-800 whitespace-nowrap">
                        {/* Mejorar */}
                        <td></td>
                        <td></td>
                        <td className="px-6 py-4 text-sm text-start text-gray-800 whitespace-nowrap">
                          "No se encontraron resultados"
                        </td>
                        <td></td>
                      </tr>
                    </>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <Pagination currentPage={currentPage} totalPages={totalPages} onChangePage={handleChangePage}/>

      <ResponseMessage
        open={showResponseMessage}
        onClose={hideResponseMessage}
        type={responseMessage === 'success' ? 'success' : 'error'}
        message={message}
      />

      <Modal
        open={showModal}
        onClose={hideModal}
        title={
          isCreate
            ? "Nuevo cliente"
            : isEdit
            ? "Editar cliente"
            : isDelete
            ? "Borrar cliente " + model.businessName
            : "Ver cliente"
        }
        backdrop={true}
        cross={true}
      >
        {isCreate || isEdit || isView ? (
          <Formik
            initialValues={model}
            validate={(values) => {
              const errors = {}
              const valuePerHourRegex = /^\d+(\.\d{1,2})?$/;

              if (!values.businessName) {
                errors.businessName = "Campo requerido";
              }
              if (!values.countryId) {
                errors.countryId = "Campo requerido";
              }
              if (!values.taxPrefixId) {
                errors.taxPrefixId = "Campo requerido";
              }
              if (!values.taxId) {
                errors.taxId = "Campo requerido";
              }
              if (!values.email) {
                errors.email = "Campo requerido";
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = "Correo inválido";
              }
              if (!values.address) {
                errors.address = "Campo requerido";
              }
              if (!values.registrationDate) {
                errors.registrationDate = "Campo requerido";
              }
              if (isEdit) {
                if (!!values.newValuePerHour) {
                  if (!valuePerHourRegex.test(values.newValuePerHour)) {
                    errors.newValuePerHour = "Solo se aceptan numeros de hasta dos decimales";
                  }

                  if (!values.dateNewValuePerHour) {
                    errors.dateNewValuePerHour = "Campo Requerido";
                  }
                }
              }
              if (isCreate) {
                if (!values.currentValuePerHour) {
                  errors.currentValuePerHour = "Campo requerido";
                } else if (!valuePerHourRegex.test(values.currentValuePerHour)) {
                  errors.currentValuePerHour = "Solo se aceptan numeros de hasta dos decimales";
                }
              }

              return errors;
            }}
            onSubmit={async(values) => {
              try{
                if (values.email !== model.email && await isExistingEmail(values.email)) {
                  setResponseMessage("existing_email");
                  return;
                }

                if (isCreate) {
                  actionClient("create", values);
                } else if (isEdit) {
                  actionClient("edit", values);
                }
              }
              catch (error){
                console.error(error.message)
              }
            }}

          >
            {({ values, setFieldValue, isSubmitting, isValid }) => {
              if (isSubmitting && !isValid)
              {
                setResponseMessage("invalid_data");
              }
              return (
              <Form>
                <div className="md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="businessName"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Razón Social*
                    </label>
                    <Field
                      className={boxInput}
                      id="businessName"
                      type="text"
                      name="businessName"
                      disabled={isView}
                    />
                    <ErrorMessage
                      name="businessName"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="contactName"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Contacto
                    </label>
                    <Field
                      className={boxInput}
                      id="contactName"
                      type="text"
                      name="contactName"
                      disabled={isView}
                    />
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="country"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      País*
                    </label>
                    <Field
                      as="select"
                      className={select}
                      id="countryId"
                      name="countryId"
                      disabled={isView}
                      onChange={(event) => {
                        const selectedId = event.target.value;
                        if (!selectedId) {
                          setFieldValue("countryId", "");
                          setFieldValue("taxPrefixId", "");
                          setSelectedCountry(null);
                        } else {
                          setFieldValue("countryId", selectedId);

                          const country = allCountries.find((c) => c.id == selectedId);
                          setSelectedCountry(country);

                          if (country.tax_prefixes.length === 1) {
                            setFieldValue("taxPrefixId", country.tax_prefixes[0].id);
                          }
                        }
                      }}
                    >
                      <option value="">Seleccionar</option>
                      {allCountries.map((country, idx) => {
                        return (
                          <option key={"country_" + idx} value={country.id}>
                            {country.name}
                          </option>
                        );
                      })}
                    </Field>
                    <ErrorMessage
                      name="countryId"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="taxId"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Tax ID*
                    </label>
                    <div className="grid grid-cols-2 gap-2">
                      <div className="col-span-1">
                        <Field
                          as="select"
                          className={select}
                          id="taxPrefixId"
                          name="taxPrefixId"
                          disabled={isView}
                        >
                          {renderTaxPrefixesOptions()}
                        </Field>
                        <ErrorMessage
                          name="taxPrefixId"
                          className="text-red-600"
                          component="div"
                        />
                      </div>
                      <div className="col-span-1">
                        <Field
                          className={boxInput}
                          id="taxId"
                          type="text"
                          name="taxId"
                          disabled={isView}
                        />
                        <ErrorMessage
                          name="taxId"
                          className="text-red-600"
                          component="div"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Email*
                    </label>
                    <Field
                      className={boxInput}
                      id="email"
                      type="text"
                      name="email"
                      disabled={isView}
                    />
                    <ErrorMessage
                      name="email"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-1/2 px-3">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Teléfono
                    </label>
                    <Field
                      className={boxInput}
                      id="phone"
                      type="text"
                      name="phone"
                      disabled={isView}
                    />
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-full px-3">
                    <label
                      htmlFor="address"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Dirección fiscal*
                    </label>
                    <Field
                      className={boxInput}
                      id="address"
                      type="text"
                      name="address"
                      disabled={isView}
                    />
                    <ErrorMessage
                      name="address"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/3 px-3">
                    <label
                      htmlFor="registrationDate"
                      className="block text-sm font-semibold text-gray-800"
                    >
                      Fecha de registro*
                    </label>
                    <Field
                      className={boxInput}
                      id="registrationDate"
                      type="date"
                      name="registrationDate"
                      disabled={isView}
                    />
                    <ErrorMessage
                      name="registrationDate"
                      className="text-red-600"
                      component="div"
                    />
                  </div>
                  <div className="md:w-2/3 px-3">
                    <div className="md:flex">
                      <div className="md:w-full">
                      { !isEdit && (
                        <div className="md:w-full px-3">
                          <label
                            htmlFor="currentValuePerHour"
                            className="block text-sm font-semibold text-gray-800"
                          >
                            Valor Hora Actual*
                          </label>
                          <Field
                            className={boxInput}
                            id="currentValuePerHour"
                            type="text"
                            name="currentValuePerHour"
                            disabled={isView }
                          />
                          <ErrorMessage
                            name="currentValuePerHour"
                            className="text-red-600"
                            component="div"
                          />
                        </div>
                      )}
                        {!isCreate && (

                        <div className="md:flex">
                          <div className="md:w-1/2 px-3">
                          <label
                            htmlFor="newValuePerHour"
                            className="block text-sm font-semibold text-gray-800"
                            >
                            Nuevo Valor Hora*
                          </label>
                          <Field
                            className={boxInput}
                            id="newValuePerHour"
                            type="text"
                            name="newValuePerHour"
                            disabled={isView}
                            />
                          <ErrorMessage
                            name="newValuePerHour"
                            className="text-red-600"
                            component="div"
                            />
                          </div>
                          <div className="md:w-1/2 px-3">
                          <label
                          htmlFor="dateNewValuePerHour"
                          className="block text-sm font-semibold text-gray-800"
                          >
                          Desde*
                        </label>
                        <Field
                          className={boxInput}
                          id="dateNewValuePerHour"
                          type="date"
                          name="dateNewValuePerHour"
                          disabled={isView || !values.newValuePerHour}
                          />
                        <ErrorMessage
                          name="dateNewValuePerHour"
                          className="text-red-600"
                          component="div"
                          />
                          </div>
                        </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="items-center gap-2 mt-3 sm:flex">
                  {!isView && (
                    <button
                      type="submit"
                      className="w-full mt-2 p-2.5 flex-1 text-white font-bold bg-orange-500/80 hover:bg-orange-500 rounded-md outline-none ring-offset-2 ring-orange-500 focus:ring-2"
                    >
                      {isCreate ? "Crear" : "Editar"}
                    </button>
                  )}
                  <button
                    className="w-full mt-2 p-2.5 flex-1 text-white bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none border ring-offset-2 ring-zinc-500 focus:ring-2"
                    onClick={() => hideModal()}
                  >
                    Cancelar
                  </button>
                </div>
              </Form>
              );
            }}
          </Formik>
        ) : (
          <>
            <p className="mt-2 text-[18px] leading-relaxed text-gray-500">
              ¿Está seguro de borrar al cliente?
            </p>
            <div className="items-center gap-2 mt-3 sm:flex">
              <button
                type="submit"
                className="w-full mt-2 p-2.5 flex-1 text-white bg-red-500/80 hover:bg-red-500 rounded-md outline-none ring-offset-2 ring-red-500 focus:ring-2"
                onClick={() => actionClient("delete")}
              >
                Borrar
              </button>
              <button
                className="w-full mt-2 p-2.5 flex-1 text-white bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none border ring-offset-2 ring-zinc-500 focus:ring-2"
                onClick={() => hideModal()}
              >
                Cancelar
              </button>
            </div>
          </>
        )}
      </Modal>
      <Modal
        open={studentsModal}
        onClose={hideStudentsModal}
        title="Alumnos"
        backdrop={true}
        cross={true}
        size = "3"
      >
        <Students client={studentClient} />
      </Modal>
      <Modal
        open={showBulkUploadModal}
        onClose={hideBulkUploadModal}
        title="Carga masiva"
        backdrop={false}
        cross={true}
      >
        <BulkUpload
          clientId={1}
          onHide={hideBulkUploadModal}
          onSuccess={handleBulkUploadSuccess}
        />
      </Modal>
    </>
    )}
    </>
  );
}

export default Clients;
