import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  all: [],
};

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {
    setAll: (state, action) => {
      state.all = action.payload;
    },
  },
});

export const {
  setAll,
} = countriesSlice.actions;

export default countriesSlice.reducer;
