import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  userLogged: {},
  sections: [],
  token: "",
  role: "",
  sectionActions: null,
  selectedSection: "",
  isLoading: true,
  openModals: [],
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    reset: () => initialState,
    setUserLogged: (state, action) => {
      state.userLogged = action.payload;
    },
    setSections: (state, action) => {
      state.sections = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setSectionActions: (state, action) => {
      state.sectionActions = action.payload;
    },
    setSelectedSection: (state, action) => {
      if (state.selectedSection !== action.payload) {
        state.selectedSection = action.payload;
        state.sectionActions = null;
      }
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setModalOpen: (state, action) => {
      if (action.payload.open) {
        if (!state.openModals.includes(action.payload.id)) {
          state.openModals.push(action.payload.id);
        }
      } else {
        state.openModals = state.openModals.filter((modalId) => modalId !== action.payload.id);
      }
    },
  },
});

export const {
  reset,
  setUserLogged,
  setSections,
  setToken,
  setRole,
  setSectionActions,
  setSelectedSection,
  setIsLoading,
  setModalOpen,
} = layoutSlice.actions;

export default layoutSlice.reducer;
