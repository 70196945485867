import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';


const DateRangePicker = ({ handleDateRangeChange }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    handleDateRangeChange(date, null);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    handleDateRangeChange(startDate, date);
  };  

  return (
    <div className='flex flex-row gap-1'>
      <DatePicker
        className="border rounded px-2 py-1"
        selected={startDate}
        onChange={(date) => handleStartDateChange(date)}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        isClearable
        placeholderText="From Date"
        dateFormat="dd/MM/yyyy"
      />
      <DatePicker
        className="border rounded px-2 py-1"
        selected={endDate}
        onChange={(date) => handleEndDateChange(date)}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        isClearable
        placeholderText="To Date" 
        dateFormat="dd/MM/yyyy" 
      />
    </div>
  );
};

export default DateRangePicker;