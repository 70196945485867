import { useDispatch } from "react-redux";
import { reset as resetStore, setToken } from "../shared/components/Layout/layoutReducer";
import { useAxiosWithAuth } from "./useAxiosWithAuth";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const useLogin = () => {
  const axios = useAxiosWithAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const login = (data) => {
    dispatch(resetStore());

    axios
      .post("/auth/signin", data)
      .then((res) => {
        if (res.status === 200) {
          const cookies = new Cookies();
          cookies.set("jwt_token", res.data.token, { path: "/" });
          dispatch(setToken(res.data.token));
          navigate("/home");
        }
      })
      .catch(function (error) {
        setError(true);
        setTimeout(() => {
          setError(false);
        }, 5000);
      });
  };

  return {login, error};
};

export default useLogin;
