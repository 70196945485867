import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

const excludedSections = [
  "student",
];

const useNavSections = () => {
  const [sections, setSections] = useState([]);
  const allSections = useSelector((state) => state.layout.sections);

  useEffect(() => {
    let newSections = allSections;
    newSections = newSections.filter((sec) => !excludedSections.includes(sec));
    setSections(newSections);
  }, [allSections]);

  return sections;
};

export default useNavSections;
