import React from "react";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, Font, Image } from '@react-pdf/renderer';
import Icon from "../../shared/components/Icon/Icon";
import { faPhone, faEnvelope, faGlobe } from '@fortawesome/free-solid-svg-icons';
import ExporterIcon from './ExporterIcon';
import B1UpLogo from '../../shared/assets/b1u-300x146.png';
import { SubCover } from "../../shared/assets/pdf-icons/subCoverImporter";

Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf'
});

const styles = StyleSheet.create({
  cover: {
    backgroundColor: '#1BAEE4'
  },
  coverImage: {
    width: "200px",
    height: "120px",
    position: 'absolute',
    top: '302px',
    left: '202.5px'
  },
  subCoverWrapper: {
    flexDirection: 'row',
    position: 'absolute',
    top: '432px',
    left: '140px'
  },
  subCoverImage: {
    width: "20px",
    height: "15px",
    paddingHorizontal: 5
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Oswald',
    textDecoration: 'underline'
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Oswald',
    textDecoration: 'underline'
  },
  innerSubtitle: {
    fontSize: 14,
    fontFamily: 'Oswald',
    textDecoration: 'underline'
  },
  text: {
    flexDirection: 'row',
    margin: 6,
    fontSize: 14,
    textAlign: 'justify',
    fontFamily: 'Times-Roman',
    paddingHorizontal: 30
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  footer: {
    flexDirection: 'row',
    backgroundColor: '#1BAEE4',
    position: 'absolute',
    minHeight: '125pt',
    bottom: 0,
    left: 0,
    right: 0
  },
  svg: {
    color: 'white', width: '15px', height: '15px'
  },
  footerImage: {
    width: "100px",
    height: "49px",
    marginVertical: 50,
    marginHorizontal: 100
  },
  infoWrapper: {
    position: 'relative',
    marginVertical: 30,
    marginHorizontal: 100
  },
  infoBlock: {
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
    marginVertical: 10
  },
  footerText: {
    color: 'white',
    fontSize: 8
  },
  bodyBlock: {
    paddingVertical: 50,
  },
  viewWrapper: {
    flexDirection: 'row'
  },
  leftView: {
    marginLeft: 100
  },
  rightView: {
    marginRight: 50
  }
});

const Footer = () => {
  return (
    <View style={styles.footer}>
      <View style={styles.infoWrapper}>
        <View style={styles.infoBlock}>
          <ExporterIcon svg={faGlobe} styles={styles.svg}></ExporterIcon>
          <Text style={styles.footerText}> {process.env.REACT_APP_PDF_WEB}</Text>
        </View>
        <View style={styles.infoBlock}>
          <ExporterIcon svg={faEnvelope} styles={styles.svg}></ExporterIcon>
          <Text style={styles.footerText}> {process.env.REACT_APP_PDF_MAIL}</Text>
        </View>
        <View style={styles.infoBlock}>
          <ExporterIcon svg={faPhone} styles={styles.svg}></ExporterIcon>
          <Text style={styles.footerText}> {process.env.REACT_APP_PDF_PHONE}</Text>
        </View>
      </View>
      <View>
        <Image style={styles.footerImage} src={B1UpLogo} />
      </View>
    </View>
  )
}

const CoverSubtitle = () => {
  return (
    <>
      <View style={styles.subCoverWrapper}>
        {
          SubCover && SubCover.map((img, i) => {
            return (<Image style={styles.subCoverImage} src={SubCover[i]} />)
          })
        }
      </View>
    </>
  )
}

// Create Document Component
const MyDocument = ({ data }) => {
  return (
    <Document>
      <Page size="A4" style={styles.cover}>
        <View >
          <Image style={styles.coverImage} src={B1UpLogo} />
        </View>
        {/* <CoverSubtitle/> */}
        <Footer />
      </Page>
      <Page size="A4" style={styles.body}>
        <View>
          <Text style={styles.title}>ALTA DE CURSO DE INGLÉS</Text>
        </View>
        <View style={styles.viewWrapper}>
          <View style={styles.leftView}></View>
          <View>
            <View style={styles.bodyBlock}>
              <View >
                <Text>• <Text style={styles.subtitle}>Empresa<Text style={styles.innerSubtitle}>: {data.client.businessName}</Text></Text></Text>
              </View>
              <View>
                <Text>• <Text style={styles.subtitle}>Alumnos<Text style={styles.innerSubtitle}>:</Text></Text></Text>
                {data.students.map((student, i) => {
                  return (
                    <>
                      <View style={styles.text} key={i}>
                        <Text >• </Text>
                        <Text >
                          {student.firstName + " " + student.lastName}
                        </Text>
                      </View>
                    </>
                  )
                })}
              </View>
              <View>
                <Text>• <Text style={styles.subtitle}>Modalidad<Text style={styles.innerSubtitle}>: {data.modality.name}</Text></Text></Text>
              </View>
              <View>
                <Text>• <Text style={styles.subtitle}>Fecha de comienzo<Text style={styles.innerSubtitle}>: {
                  new Date(data.startDate).toLocaleDateString('es-AR', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' })
                }</Text></Text></Text>
              </View>
              <View>
                <Text>• <Text style={styles.subtitle}>Horarios<Text style={styles.innerSubtitle}>: </Text></Text></Text>
                {data.days.map((day, i) => {
                  return (
                    <>
                      <View style={styles.text} key={i}>
                        <Text >• </Text>
                        <Text >
                          {day.weekDay + ' de ' + day.startTime + ' Duración: ' + day.lessonDuration + ' min'}
                        </Text>
                      </View>
                    </>
                  )
                })}
              </View>
              <View>
                <Text>• <Text style={styles.subtitle}>Nivel<Text style={styles.innerSubtitle}>: {data.level.name}</Text></Text></Text>
              </View>
              <View>
                <Text>• <Text style={styles.subtitle}>Profesores<Text style={styles.innerSubtitle}>: </Text></Text></Text>
                {data.teachers.map((teacher, i) => {
                  return (
                    <>
                      <View style={styles.text} key={i}>
                        <Text >• </Text>
                        <Text >
                          {teacher.firstName + ' ' + teacher.lastName}
                        </Text>
                      </View>
                    </>
                  )
                })}
              </View>
            </View>
          </View>
          <View style={styles.rightView}></View>
        </View>
        <Footer />
      </Page>
    </Document>
  )
};

const Exporter = ({ data }) => {
  return (
    <PDFDownloadLink key={data.id} document={<MyDocument data={data} />} title="Descargar" fileName={`Alta-curso-${data.client.businessName}.pdf`} className="text-zinc-500 hover:text-zinc-600">
      {({ blob, url, loading, error }) => {
        return (
          <>
            {
              <button title={loading ? "Cargando" : "Descargar"} disabled={loading}>
                <Icon icon="file-pdf" size="xl"></Icon>
              </button>
            }
          </>
        )
      }}
    </PDFDownloadLink>
  )
};

export default Exporter;
