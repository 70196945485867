import { useDispatch } from "react-redux";
import { useAxiosWithAuth } from "./useAxiosWithAuth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import Cookies from "universal-cookie";
import { reset as resetStore } from "../shared/components/Layout/layoutReducer";

const useLogout = () => {
  const axios = useAxiosWithAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logout = () => {
    axios
      .post("/auth/signout")
      .then((res) => {
        if (res.status === 200) {
          const cookies = new Cookies();
          cookies.remove("jwt_token", { path: "/" });
          cookies.remove("user", { path: "/" });
          navigate("/login");
          dispatch(resetStore());
        }
      })
      .catch(function (error) {
        // TODO: handle error
      });
  };

  return logout;
};

export default useLogout;
