import React, { useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import B1UpLogo from '../../shared/assets/b1u-300x146.png';
import faPhone from '../../shared/assets/phone-solid.png';
import faEnvelope from '../../shared/assets/globe-solid.png';
import faGlobe from '../../shared/assets/envelope-solid.png';
import Icon from "../../shared/components/Icon/Icon";

const ReportsApiExportToPDF = ({ variables, collectedValue, valuePerHour, totalHours, tab , lapseTime}) => {

    const scheduleArray = (schedule) => {
        const resultArray = schedule?.map(getStartTimeAndEndTime);
        return resultArray?.join(', ');
    };

    const formatDate = (utcDate) => {
      return new Date(utcDate).toLocaleDateString('es-AR', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        timeZone: 'UTC',
      });
    };

    const formatDates = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const convertDateFormats = (lapseTime) => {
        const startDate = new Date(lapseTime.startDate);
        const endDate = new Date(lapseTime.endDate);
        const formattedStartDate = formatDates(startDate);
        const formattedEndDate = formatDates(endDate);
        return `Periodo: ${formattedStartDate} al ${formattedEndDate}`
    }

    const getStartTimeAndEndTime = (lesson) => {
        const splitTime = lesson.startTime.split(':');
        const startDate = new Date(0, 0, 0, parseInt(splitTime[0]), parseInt(splitTime[1])).getTime();
        const endDate = new Date(startDate).getTime() + lesson.lessonDuration * 60000;

        const startTime = new Date(startDate).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
        });
        const endTime = new Date(endDate).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
        });

        return `${lesson.weekDay} de ${startTime} a ${endTime} hs`;
    };

    const tableStyles = {
        fillColor: [77, 169, 216],
        textColor: 255,
    };

    const calculateTableHeight = (tableData) => {
        const rowHeight = 10;
        const headerHeight = 10;

        const numRows = tableData.length;

        return numRows * rowHeight + headerHeight;
    };

    const handleExportClick = () => {
        const doc = new jsPDF();
        const maxWidth = 170;

        let title = `${tab === 'client' ? variables[0]?.client?.businessName : Object.values(variables[0]?.teachers)[0]['lastName'] + ' ' + Object.values(variables[0]?.teachers)[0]['firstName']}`

        const columnStylesTable =  { 3: { overflow: 'visible' } }
        const tableHeaders =  (tab === 'client') ? ['Alumno', 'Horas Tomadas', 'Asistencias'] : ['Alumno' , 'Horas Dictadas' , 'Horas Tomadas' , 'Asistencias']

        let subTitle = `Valor / Hora: ${valuePerHour}   -   Horas a Facturar: ${totalHours}   -   Valor a facturar: ${collectedValue}`
        let lapseTimeTitle = convertDateFormats (lapseTime)

        doc.setFillColor(77, 169, 216);
        doc.rect(0, 0, doc.internal.pageSize.getWidth(), 40, 'F');


        doc.setFontSize(8);
        doc.setTextColor(255, 255, 255);

        doc.text(process.env.REACT_APP_PDF_WEB, 24, 10)
        doc.text(process.env.REACT_APP_PDF_MAIL, 24, 20)
        doc.text(process.env.REACT_APP_PDF_PHONE, 24, 30)

        doc.addImage(B1UpLogo, 'PNG', doc.internal.pageSize.getWidth() - 40, 10, 30, 15);
        doc.addImage(faEnvelope, 'PNG', 15, 7, 5, 5);
        doc.addImage(faGlobe, 'PNG',15, 17, 5, 5);
        doc.addImage(faPhone, 'PNG',15, 26, 5, 5);

        doc.setFontSize(16);
        doc.setTextColor(0, 0, 0);

        doc.text(title, 105, 50, null, null, 'center');

        doc.setFontSize(12);
        doc.text(subTitle, 30, 60);

        doc.text( lapseTimeTitle, 30, 70);

        let startYPosition = 85

        for (const variable of variables) {

            if (Object.keys(variable.students).length === 0) continue

            let secondSubtitle = (tab !== 'client') ? `Cliente: ${variable.client.businessName}      Curso: ${variable.name}      Nivel: ${variable.level?.name}.`
                                                    : `Curso: ${variable.name}      Nivel: ${variable.level?.name}      Modalidad: ${variable.modality?.name}.`;

            let thirdSubtitle = (tab !== 'client') ? `Modalidad: ${variable.modality?.name}      Horas totales: ${variable.totalDictated} hs.`
                                                    : `Horas totales: ${variable.totalDictated} hs.`;

            let fourthSubtitle = `Horario: ${scheduleArray(variable.schedule)}`;


            let tableData = [];
            let highSubtitles = 20

            let students = Object.values(variable.students);
            let takenPerStudent = variable.takenPerStudent
            let totalDictated = variable.totalDictated
            let attendancePerStudent = variable.attendancePerStudent

            students?.map((student) => {
                let rowData = []
                if (tab === 'client') {
                    rowData = [
                        `${student?.lastName} ${student?.firstName}`,
                        `${takenPerStudent && takenPerStudent[student.id]} hs`,
                        `${attendancePerStudent && attendancePerStudent[student.id]}%` ,
                    ]
                }
                else {
                    rowData = [
                        `${student?.lastName} ${student?.firstName}`,
                        `${totalDictated} hs`,
                        `${takenPerStudent && takenPerStudent[student.id]} hs`,
                        `${attendancePerStudent && attendancePerStudent[student.id]}%`
                    ];
                }
                tableData.push(rowData);
            })

            let spaceLeftTable = doc.internal.pageSize.getHeight() - startYPosition;

            if (tab === 'client') {
                highSubtitles += 10
            }

            let tableHeight = calculateTableHeight(tableData) + highSubtitles;
            if (spaceLeftTable < tableHeight) {
                doc.addPage();
                startYPosition = 15;
            }

            doc.text(secondSubtitle, 20, startYPosition);
            startYPosition += 8

            doc.text(thirdSubtitle, 20, startYPosition);
            startYPosition += 8

            let textFragments = doc.splitTextToSize(fourthSubtitle, maxWidth);

            textFragments.forEach((fragment, index) => {
                doc.text(20, startYPosition + index * 8, fragment);
            });
            startYPosition += textFragments.length * 8;

            if (tab === 'client') {
                let teachers = Object.values(variable.teachers);
                let teachersDataTable = teachers?.map(teacher => `${teacher.lastName} ${teacher.firstName}`).join(', ')
                let teachersSubtitle = `Profesor/es a cargo: ${teachersDataTable}.`
                let textFragmentsTeacher = doc.splitTextToSize(teachersSubtitle, maxWidth);

                textFragmentsTeacher.forEach((fragment, index) => {
                    doc.text(20, startYPosition + index * 8, fragment);
                });
                startYPosition += textFragmentsTeacher.length * 8;
            }

            doc.autoTable({
                head: [tableHeaders],
                body: tableData,
                startY: startYPosition,
                margin: { horizontal: 15 },
                headStyles: tableStyles,
                columnStyles: columnStylesTable,
                theme: 'grid',
                styles: { cellPadding: 2, fontSize: 10, valign: 'middle' }
            });
            startYPosition = doc.autoTable.previous.finalY + 15;
        }

        doc.save(`report_${title}.pdf`)
    }

    return (
        <div>
            <button
            class="flex flex-row gap-2 bg-orange-500/80 hover:bg-orange-500 text-white font-bold py-2 px-4 rounded"
            type="button"
            onClick={handleExportClick}
            disabled={!variables || variables.length === 0}
            >
                Exportar
                <Icon icon="file-pdf" size="xl" />
            </button>
        </div>
    );
};

export default ReportsApiExportToPDF;


