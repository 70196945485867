import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  all: [],
};

export const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setAll: (state, action) => {
      state.all = action.payload;
    },
  },
});

export const {
  setAll,
} = roleSlice.actions;

export default roleSlice.reducer;
