import { combineReducers, configureStore } from "@reduxjs/toolkit";
import layoutReducer from "./components/Layout/layoutReducer";
import rolesReducer from "../reducers/rolesReducer";
import countriesReducer from "../reducers/countriesReducer";
import levelsReducer from "../reducers/levelsReducer";
import modalitiesReducer from "../reducers/modalitiesReducer";
import usersReducer from "../pages/Users/usersReducer";
import clientsReducer from "../pages/Clients/clientsReducer";
import studentsReducer from "../pages/Students/studentsReducer";
import coursesReducer from "../pages/Courses/coursesReducer";
import studentClassesReducer from "../pages/StudentClasses/studentClassesReducer";
import reportsReducer from "../pages/Reports/reportsReducer";
import calendarReducer from "../reducers/calendarReducer";

const combinedReducer = combineReducers({
  layout: layoutReducer,
  roles: rolesReducer,
  countries: countriesReducer,
  levels: levelsReducer,
  modalities: modalitiesReducer,
  users: usersReducer,
  clients: clientsReducer,
  students: studentsReducer,
  courses: coursesReducer,
  studentClasses: studentClassesReducer,
  reports: reportsReducer,
  calendar: calendarReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "layout/reset") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export default configureStore({
  reducer: rootReducer,
});
