import React, { useEffect, useState } from "react";
import { useAxiosWithAuth } from "../../hooks/useAxiosWithAuth";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import ResponseMessage from "../../shared/components/ResponseMessage/ResponseMessage";
import Spinner from "../../shared/components/Spinner/Spinner";

const acceptedFileTypes = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
];

const BulkUpload = ({
  clientId,
  onHide,
  onSuccess,
}) => {

  const axios = useAxiosWithAuth();
  const navigate = useNavigate();
  const [responseMessage, setResponseMessage] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [message, setMessage] = useState("");
  const [errorResponse, setErrorResponse] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!errorResponse) {
      setErrorMessage("");
      return;
    }

    let newMessage = "";
    if (errorResponse.message === "missing_client") {
      newMessage = "Es necesario especificar el cliente al que pertenecerán los alumnos.";
    } else if (errorResponse.message === "file_size") {
      newMessage = "El archivo no debe superar los 50 MB de tamaño.";
    } else if (errorResponse.message === "file_type") {
      newMessage = "El archivo debe ser de formato \".xlsx\".";
    } else if (errorResponse.message === "field") {
      newMessage = "Error en la estructura de la planilla: ";

      const errorsCount = errorResponse.data.length;
      errorResponse.data.forEach((error, index) => {
        newMessage += `En la columna ${error.column}, fila ${error.row}. `;

        switch (error.error) {
          case "required":
            newMessage += "El campo es requerido.";
            break;

          case "format":
            newMessage += "El formato del campo no es válido.";
            break;

          default:
            newMessage += "Error desconocido. Por favor, revise los valores de esta fila.";
            break;
        }

        if (index < errorsCount - 1) {
          newMessage += " | ";
        }
      });
    } else if (errorResponse.message === "missing_name") {
      newMessage = `Error en la columna NOMBRE, fila ${errorResponse.data.row}. El nombre ingresado es "${errorResponse.data.value}" pero debe tener el formato "Nombre Apellido".`;
    } else if (errorResponse.message === "missing_country") {
      newMessage = `Error en la columna PAIS, fila ${errorResponse.data.row}. No se pudo encontrar un país con el nombre de "${errorResponse.data.value}".`;
    } else if (errorResponse.message === "missing_document_type") {
      newMessage = `Error en la columna TIPO DE DOCUMENTO, fila ${errorResponse.data.row}. No se pudo encontrar un tipo de documento con el nombre de "${errorResponse.data.value}" que sea válido para el país.`;
    } else {
      newMessage = "Ocurrió un error. ¡Vuelva a intentar mas tarde!";
    }

    setFile(null);
    setErrorMessage(newMessage);
  }, [errorResponse]);

  useEffect(() => {
    if (!responseMessage) {
      return;
    }

    let newMessage = "";
    if (responseMessage === "success") {
      newMessage = "¡Alumnos creados exitosamente!";
    } else {
      newMessage = "Ocurrió un error. ¡Vuelva a intentar mas tarde!";
    }

    setFile(null);
    setMessage(newMessage);
  }, [responseMessage, responseData]);

  const hideResponseMessage = () => {
    setResponseMessage("");
    setResponseData(null);
    setMessage("");
  };

  const handleClose = (evt) => {
    evt.preventDefault();

    setFile(null);
    setResponseMessage("");
    setErrorResponse(null);
    onHide(evt);
  };

  const handleSuccess = () => {
    setFile(null);
    setTimeout(onSuccess, 2000);
  };

  const handleInputChange = (evt) => {
    setErrorResponse(null);
    if (evt.target.files) {
      setFile(evt.target.files[0]);
    } else {
      setFile(null);
    }
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (!file) {
      return;
    }

    const data = new FormData();
    data.append("file", file);
    const options = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    setIsLoading(true);
    axios
      .post("/student/bulkUpload/" + clientId, data, options)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          setResponseMessage(res.data.status);
          setResponseData(null);
          handleSuccess();
        } else {
          setResponseMessage(res.data.message);
          setResponseData(res.data.data);
        }
      })
      .catch(function (error) {
        if (error.response.status === 500) {
          setErrorResponse(error.response.data);
        } else {
          if (error.response.status === 401) {
            const cookies = new Cookies();
            cookies.remove("jwt_token");
            navigate("/login");
          }

          setResponseMessage("error");
          setResponseData(null);
        }
      })
      .finally(function () {
        setIsLoading(false);
      });
  };

  return (
    <>
      <ResponseMessage
        open={message !== ""}
        onClose={hideResponseMessage}
        type={responseMessage === 'success' ? 'success' : 'error'}
        message={message}
      />

      {isLoading && (
        <Spinner
          color="#000000"
          size={80}
        />
      )}
      {!isLoading && (
        <form onSubmit={handleSubmit}>
          <input
            className="p-8"
            type="file"
            accept={acceptedFileTypes.join(",")}
            onChange={handleInputChange}
          />
          <p className="text-red-600">{errorMessage}</p>
          <div className="items-center gap-2 mt-3 sm:flex">
            {!!file && (
              <button
                type="submit"
                className="w-full mt-2 p-2.5 flex-1 text-white font-bold bg-orange-500/80 hover:bg-orange-500 rounded-md outline-none ring-offset-2 ring-orange-500 focus:ring-2"
              >
                Cargar
              </button>
            )}
            <button
              type="button"
              className="w-full mt-2 p-2.5 flex-1 text-white bg-zinc-500/80 hover:bg-zinc-500 rounded-md outline-none border ring-offset-2 ring-zinc-500 focus:ring-2"
              onClick={handleClose}
            >
              Cerrar
            </button>
          </div>
        </form>
      )}
    </>
  );

};

export default BulkUpload;
