function CheckRegister() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-8 h-8"
    >
      <path fill="currentColor" d="M16 14q-1.25 0-2.125-.875T13 11q0-1.25.875-2.125T16 8q1.25 0 2.125.875T19 11q0 1.25-.875 2.125T16 14Zm-5 6q-.425 0-.713-.288T10 19v-.9q0-.525.25-1t.7-.75q1.125-.675 2.388-1.012T16 15q1.4 0 2.663.338t2.387 1.012q.45.275.7.75t.25 1v.9q0 .425-.288.713T21 20H11Zm-1-6H4q-.425 0-.713-.288T3 13q0-.425.288-.713T4 12h6q.425 0 .713.288T11 13q0 .425-.288.713T10 14Zm4-8H4q-.425 0-.713-.288T3 5q0-.425.288-.713T4 4h10q.425 0 .713.288T15 5q0 .425-.288.713T14 6Zm-2.9 4H4q-.425 0-.713-.288T3 9q0-.425.288-.713T4 8h8q-.35.425-.563.925T11.1 10Z" />
    </svg>
  );
}

export default CheckRegister;
