import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: null,
  currentPage: 1,
  totalPages: 1,
  clientCourses: {},
};

const filterList = (list, key, value) => {
  return list.filter((student) => {
    return !student.hasOwnProperty(key) || student[key] !== value;
  });
};

export const studentSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    setList: (state, action) => {
      state.list = action.payload;
    },
    setAll: (state, action) => {
      state.all = action.payload;
    },
    appendToList: (state, action) => {
      state.list.push(action.payload);
    },
    removeFromListByIndex: (state, action) => {
      state.list.splice(action.payload, 1);
    },
    removeFromListById: (state, action) => {
      state.list = filterList(state.list, "id", action.payload);
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    setTotalPages: (state, action) => {
      state.totalPages = action.payload;
    },
    setClientCourses: (state, action) => {
      state.clientCourses[action.payload.clientId] = action.payload.courses;
    },
  },
});

export const {
  setList,
  setAll,
  appendToList,
  removeFromListByIndex,
  removeFromListById,
  setCurrentPage,
  setTotalPages,
  setClientCourses,
} = studentSlice.actions;

export default studentSlice.reducer;
