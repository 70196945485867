import React, { useEffect, useState } from "react";
import "./Modal.css";
import { useDispatch } from "react-redux";
import { setModalOpen } from "../../components/Layout/layoutReducer";

const Modal = ({
  children,
  open,
  onClose,
  title = null,
  header = null,
  footer = null,
  backdrop = true,
  cross = true,
  size = 2
}) => {

  const dispatch = useDispatch();
  const [modalId, setModalId] = useState("1");

  useEffect(() => {
    setModalId(Math.random().toString().slice(2));
  }, []);

  useEffect(() => {
    dispatch(setModalOpen({
      id: modalId,
      open: open,
    }));
  }, [open]);

  const handleBackdropClick = (evt) => {
    if (backdrop) {
      onClose(evt);
    }
  };

  const handleCrossClick = (evt) => {
    if (cross) {
      onClose(evt);
    }
  };

  return (
    <>
      {open && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div
            className={`fixed inset-0 w-full h-full bg-black opacity-40 ${!backdrop ? "transparent" : ""}`}
            onClick={handleBackdropClick}
          ></div>
          <div className="flex items-center min-h-screen px-4 py-8">
            <div className={`relative w-auto max-w-${size}xl p-4 mx-auto bg-white rounded-md shadow-lg`}>
              {header && (header())}
              {(title || cross) && (
                <div className="flex w-full">
                  {title && (
                    <h4 className="text-lg text-left w-1/2 font-medium text-sky-500/80">
                      {title}
                    </h4>
                  )}
                  {cross && (
                    <div className="w-1/2 text-right">
                      <button className="text-xl font-medium text-sky-500/80" type="button" onClick={handleCrossClick}>
                        X
                      </button>
                    </div>
                  )}
                </div>
              )}
              <div className="text-center sm:text-left">{children}</div>
              {footer && (footer())}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Modal;
